import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductionPlan } from "./types";

export const DELETE_PRODUCTION_PLAN_URL = (id: ID) =>
  `/api/admin/production-plans/${id}`;

export enum DeleteProductionPlanError {}

export const deletedProductionPlanErrorMessages = {};

export type DeleteProductionPlanResponse = Response<ProductionPlan>;

export type DeleteProductionPlanArgs = {
  id: ID;
};

export const deleteProductionPlan = ({ id }: DeleteProductionPlanArgs) => {
  return httpClient.delete<DeleteProductionPlanResponse>(
    DELETE_PRODUCTION_PLAN_URL(id),
  );
};

export const useDeleteProductionPlanMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteProductionPlanResponse>,
    AxiosError<Response<null>>,
    DeleteProductionPlanArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_PRODUCTION_PLAN_URL],
    mutationFn: (deletedProductionPlanArgs: DeleteProductionPlanArgs) =>
      deleteProductionPlan(deletedProductionPlanArgs),
    ...opts,
  });
};
