import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Customer } from "./types";

export const DELETE_CUSTOMER_URL = (id: ID) => `/api/admin/customers/${id}`;

export enum DeleteCustomerError {}

export const deletedCustomerErrorMessages = {};

export type DeleteCustomerResponse = Response<Customer>;

export type DeleteCustomerArgs = {
  id: ID;
};

export const deleteCustomer = ({ id }: DeleteCustomerArgs) => {
  return httpClient.delete<DeleteCustomerResponse>(DELETE_CUSTOMER_URL(id));
};

export const useDeleteCustomerMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteCustomerResponse>,
    AxiosError<Response<null>>,
    DeleteCustomerArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_CUSTOMER_URL],
    mutationFn: (deletedCustomerArgs: DeleteCustomerArgs) =>
      deleteCustomer(deletedCustomerArgs),
    ...opts,
  });
};
