import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { APIError, APISuccess, Response } from "../types";
import { httpClient } from "app/datasources";

export const CHANGE_PASSWORD_URL = "/api/admin/auth/change-password";

export type ChangePasswordResponse = Response<unknown>;

export type ChangePasswordArgs = {
  oldPassword: string;
  newPassword: string;
};

export enum ChangePasswordError {
  PasswordNotMatch = "Password Not Match",
}

export const changePasswordErrorMessages = {
  [ChangePasswordError.PasswordNotMatch]: "Mật khẩu khống chính xác",
};

export const changePassword = (changePasswordArgs: ChangePasswordArgs) => {
  return httpClient.put<ChangePasswordResponse>(
    CHANGE_PASSWORD_URL,
    changePasswordArgs,
  );
};

export const useChangePasswordMutation = (
  opts?: UseMutationOptions<
    APISuccess<ChangePasswordResponse>,
    APIError,
    ChangePasswordArgs
  >,
) =>
  useMutation({
    mutationFn: changePassword,
    mutationKey: [CHANGE_PASSWORD_URL],
    ...opts,
  });
