import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { CurrentUser } from "./types";

export const RETRIEVE_CURRENT_PANEL_PROFILE_URL = "/api/panel/auth/profile";

export enum CurrentPanelProfileError {
  AccountNotFound = "ACCOUNT_NOT_FOUND",
}

export const currentPanelProfileErrorMessages = {
  [CurrentPanelProfileError.AccountNotFound]: "Tài khoản không tồn tại",
};

export type RetrieveCurrentPanelProfileResponse = Response<CurrentUser>;

export const retrieveCurrentPanelProfile = () => {
  return httpClient.get<RetrieveCurrentPanelProfileResponse>(
    RETRIEVE_CURRENT_PANEL_PROFILE_URL,
  );
};

export const useRetrieveCurrentPanelProfile = (
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveCurrentPanelProfileResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveCurrentPanelProfileResponse>, AxiosError<any>>(
    {
      queryKey: [RETRIEVE_CURRENT_PANEL_PROFILE_URL],
      queryFn: retrieveCurrentPanelProfile,
      ...opts,
    },
  );
