import { OrderItem } from "../order";
import { IBaseModel } from "../types";

export enum ProductionPlanStatus {
  WaitForApprove = "WaitForApprove",
  Todo = "Todo",
}

export enum ProductionPlanItemStatus {
  WaitForApprove = "WaitForApprove",
  Todo = "Todo",
}

export interface ProductionPlanItem extends IBaseModel {
  planId: ID;
  plan: ProductionPlan;
  orderItem: OrderItem;
  description: string | null;
  quantity: number;
  productionOrderQty: number;
  status: ProductionPlanItemStatus;
}

export interface ProductionPlan extends IBaseModel {
  name: string;
  description: string | null;
  startDate: DateTimeString;
  endDate: DateTimeString;
  planItems: ProductionPlanItem[];
  status: ProductionPlanStatus;
}
