import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//routes
import Layout from "app/components/molecules/Layout/Layout";
import NonAuthMainLayout from "app/components/molecules/NonAuthLayout/NonAuthLayout";
import NonAuthPanelLayout from "app/components/molecules/NonAuthPanelLayout";
import Error404 from "app/components/pages/error/Error404";
import {
  appPanelProtectedRoutes,
  appPanelPublicRoutes,
  appProtectedRoutes,
  appPublicRoutes,
} from "./allRoutes";
import AuthPosProtected from "./AuthPanelProtected";
import AuthProtected from "./AuthProtected";
import PanelEmployeeProtected from "./PanelEmployeeProtected";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {appPublicRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={<NonAuthMainLayout>{route.component}</NonAuthMainLayout>}
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>

        <Route>
          {appPanelPublicRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={
                <NonAuthPanelLayout>{route.component}</NonAuthPanelLayout>
              }
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>

        <Route>
          {appProtectedRoutes.map((route: any, idx: any) => (
            <Route
              index={route.index}
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              }
              key={idx}
            >
              {route.children?.map((child: any, idx: any) => (
                <Route
                  index={child.index}
                  path={child.path}
                  element={child.component}
                  key={idx}
                />
              ))}
            </Route>
          ))}
        </Route>
        <Route>
          <Route path="/panel" element={<AuthPosProtected />}>
            {appPanelProtectedRoutes.map((route: any, idx: any) => (
              <Route
                index={route.index}
                path={route.path}
                element={
                  route.isPublic ? (
                    route.component
                  ) : (
                    <PanelEmployeeProtected>
                      {route.component}
                    </PanelEmployeeProtected>
                  )
                }
                key={idx}
              >
                {route.children?.map((child: any, idx: any) => (
                  <Route
                    index={child.index}
                    path={child.path}
                    element={child.component}
                    key={idx}
                  />
                ))}
              </Route>
            ))}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="404" />} />
        <Route path="404" element={<Error404 />} />
      </Routes>
    </React.Fragment>
  );
};

export default Index;
