import { useAppTranslation } from "app/hooks";
import { RetrieveProductionOrdersArgs } from "app/services/productionOrder/retrieveProductionOrders";
import { OptionSelect } from "app/services/types";
import React from "react";
import Select, { Props } from "react-select";
import { useRetrieveProductionOrderById } from "../../hooks";

export type RetrieveKeys = keyof RetrieveProductionOrdersArgs;

export type ProductionOrderItemOptionSelect = OptionSelect<ID>;

export interface ProductionOrderItemSelectProps
  extends Omit<Props<ProductionOrderItemOptionSelect>, "value" | "onChange"> {
  id?: ID;
  value: ProductionOrderItemOptionSelect | null;
  onChange: (value: ProductionOrderItemOptionSelect | null) => void;
  isInvalid?: boolean;
}

export const ProductionOrderItemSelect: React.FC<
  ProductionOrderItemSelectProps
> = ({ value, id, onChange, isInvalid, ...props }) => {
  const { placeHolderText } = useAppTranslation();
  const { productionOrder, isLoadingProductionOrder } =
    useRetrieveProductionOrderById(
      {
        id: id as string,
      },
      { enabled: !!id },
    );

  const productionOrderItems = productionOrder?.productionOrderItems || [];

  return (
    <>
      <Select<ProductionOrderItemOptionSelect>
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => "Không có dữ liệu"}
        value={value}
        isMulti={false}
        isLoading={isLoadingProductionOrder}
        onChange={onChange}
        options={
          productionOrderItems.map((item) => ({
            value: item.id,
            label: `${item.planItem.orderItem.product.nameVn}`,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            bproductionOrderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.bproductionOrderColor,
            ":hover": {
              bproductionOrderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.bproductionOrderColor,
            },
          }),
        }}
      />
    </>
  );
};
