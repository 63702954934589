import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Panel } from "./types";

export const UPDATE_PANEL_URL = (id: ID) => `/api/admin/panels/${id}`;

export enum UpdatePanelError {}

export const updatePanelErrorMessages = {};

export type UpdatePanelResponse = Response<Panel>;

export type UpdatePanelArgs = {
  id: ID;
  name: string;
  description: string | null;
  workshopId: ID;
};

export const updatePanel = ({ id, ...args }: UpdatePanelArgs) => {
  return httpClient.put<UpdatePanelResponse>(UPDATE_PANEL_URL(id), args);
};

export const useUpdatePanelMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdatePanelResponse>,
      AxiosError<any>,
      UpdatePanelArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdatePanelResponse>,
    AxiosError<any>,
    UpdatePanelArgs
  >({
    mutationFn: updatePanel,
    mutationKey: [UPDATE_PANEL_URL],
    ...opts,
  });
