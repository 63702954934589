import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Product } from "./types";

export const RETRIEVE_PRODUCTS_URL = "/api/admin/products";

export interface RetrieveProductsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveProductsResponse = Response<PagingData<Product[]>>;

export const retrieveProducts = (args: RetrieveProductsArgs) => {
  return httpClient.get<RetrieveProductsResponse>(RETRIEVE_PRODUCTS_URL, {
    params: args,
  });
};

export const useRetrieveProductsQuery = (
  retrieveProductsArgs: RetrieveProductsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveProductsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCTS_URL, retrieveProductsArgs],
    queryFn: () => retrieveProducts(retrieveProductsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveProductsInfiniteQuery = (
  retrieveProductsArgs: RetrieveProductsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveProductsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveProductsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveProductsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_PRODUCTS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveProductsArgs;
      return retrieveProducts({
        ...retrieveProductsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveProductsArgs;
      let nextPageParam: RetrieveProductsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveProductsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
