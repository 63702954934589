import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deletedTicketErrorMessages,
  RETRIEVE_TICKETS_URL,
  useDeleteTicketMutation,
} from "app/services/ticket";

interface UseDeleteTicketProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteTicket = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseDeleteTicketProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteTicketMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_TICKETS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deletedTicketErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
