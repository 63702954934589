import { useAppTranslation, useRouteMatch } from "app/hooks";
import classnames from "classnames";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

export interface DetailProductTabsProps {}

export enum DetailProductTabEnum {
  General = "/product/:id/general",
  OrderItems = "/product/:id/order-items",
  Bom = "/product/:id/bom",
}

export const DetailProductTabs: FC<DetailProductTabsProps> = () => {
  const { productText } = useAppTranslation();
  const { id } = useParams();
  const routeMatch = useRouteMatch([
    "/product/:id/general",
    "/product/:id/order-items",
    "/product/:id/bom",
  ]);

  const currentTab = routeMatch?.pattern?.path ?? DetailProductTabEnum.General;

  return (
    <div className="d-flex px-3">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <Link
            to={`/product/${id}/general`}
            className={classnames(
              {
                active: currentTab === DetailProductTabEnum.General,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {productText.detail.tab.general}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/product/${id}/bom`}
            className={classnames(
              {
                active: currentTab === DetailProductTabEnum.Bom,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="mdi mdi-file-tree-outline d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {productText.detail.tab.bom}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/product/${id}/order-items`}
            className={classnames(
              {
                active: currentTab === DetailProductTabEnum.OrderItems,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-shopping-cart-2-line d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {productText.detail.tab.orders}
            </span>
          </Link>
        </NavItem>
      </Nav>
    </div>
  );
};
