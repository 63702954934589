import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "app/components/organisms/AuthProvider";
import { LoadingScreen } from "app/components/organisms/LoadingScreen";
import { AccessTypeUser } from "app/services/user";

const AuthPanelProtected = () => {
  const navigate = useNavigate();
  const { authenticated, fetchingCurrentUser, currentUser } = useAuth();

  useEffect(() => {
    if (!authenticated) {
      navigate("/panel/login");
    } else {
      if (currentUser?.accessType === AccessTypeUser.Admin) {
        navigate("/");
      } else {
        localStorage.setItem("accessType", "panel");
      }
    }
  }, [authenticated]);

  if (fetchingCurrentUser || !currentUser) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
};

export default AuthPanelProtected;
