import { Employee } from "../employee";
import { ProductionPlan, ProductionPlanItem } from "../productionPlan";
import { IBaseModel } from "../types";

export enum ProductionOrderStatus {
  InProgress = "InProgress",
  Todo = "Todo",
}

export enum ProductionOrderItemStatus {
  InProgress = "InProgress",
  Todo = "Todo",
}

export interface ProductionOrderItem extends IBaseModel {
  planItem: ProductionPlanItem;
  quantity: number;
  note: string | null;
  productionOrderId: ID;
  productionOrder: ProductionOrder;
  status: ProductionOrderItemStatus;
}

export interface ProductionOrder extends IBaseModel {
  name: string;
  startDate: DateTimeString;
  endDate: DateTimeString;
  note: string | null;
  productionOrderItems: ProductionOrderItem[];
  productionPlan: ProductionPlan;
  status: ProductionOrderStatus;
}
