import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { readAsArrayBuffer } from "app/helpers/utils";
import useToastify from "app/hooks/useToastify";
import { FileKey, Source } from "app/models";
import { IBaseModel, Response } from "../types";

const UPLOAD_FILES_URL = "/api/admin/attachments";

interface AttachmentResponse extends IBaseModel {
  fileName: string;
  filePath: string;
  fileType: FileKey;
  moduleType: Source;
  attachmentUrl: string;
}

export type GetPresignedURLsResponse = Response<AttachmentResponse[]>;

export type UploadFilesResponse = ID[];

export type UploadFilesArgs = {
  files: File[];
  moduleType: Source;
  fileType: FileKey;
  isFormData?: boolean;
};

export const uploadFiles = async ({
  fileType,
  files,
  moduleType,
  isFormData = false,
}: UploadFilesArgs): Promise<UploadFilesResponse> => {
  const fileNames = files.map((file) => file.name);
  const getPresignedURLsResponse =
    await httpClient.post<GetPresignedURLsResponse>(UPLOAD_FILES_URL, {
      fileNames,
      fileType,
      moduleType,
    });

  const presigneds = getPresignedURLsResponse.data.data;
  const fileIds = presigneds.map((attachment) => attachment.id);

  const uploadToS3Promisses = presigneds.map(async (presigned, index) => {
    if (isFormData) {
      const formData = new FormData();
      formData.append("file", files[index]);
      await httpClient.post(presigned.attachmentUrl, formData);
      return fileIds;
    } else {
      const arrayBuffer = await readAsArrayBuffer(files[index]);
      return httpClient.post(presigned.attachmentUrl, arrayBuffer, {
        headers: { "Content-Type": files[index].type },
      });
    }
  });

  await Promise.all(uploadToS3Promisses);

  return fileIds;
};

export const useUploadFiles = (
  opts?: UseMutationOptions<UploadFilesResponse, any, UploadFilesArgs>,
) => {
  const { error } = useToastify();
  return useMutation({
    mutationFn: uploadFiles,
    onError: () => {
      error();
    },
    ...opts,
  });
};
