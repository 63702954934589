import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { JobTitle } from "./types";
import { Response } from "../types";

export const RETRIEVE_JOB_TITLE_BY_ID_URL = (id: ID) =>
  `/api/admin/job-titles/${id}`;

export type RetrieveJobTitleByIdResponse = Response<JobTitle>;

export type RetrieveJobTitleByIdArgs = {
  id: ID;
};

export const retrieveJobTitleById = ({ id }: RetrieveJobTitleByIdArgs) => {
  return httpClient.get<RetrieveJobTitleByIdResponse>(
    RETRIEVE_JOB_TITLE_BY_ID_URL(id),
  );
};

export const useRetrieveJobTitleByIdQuery = (
  retrieveJobTitlesArgs: RetrieveJobTitleByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveJobTitleByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveJobTitleByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_JOB_TITLE_BY_ID_URL(retrieveJobTitlesArgs.id)],
    queryFn: () => retrieveJobTitleById(retrieveJobTitlesArgs),
    ...opts,
  });
