import {
  ProductionPlanItemStatus,
  ProductionPlanStatus,
} from "app/services/productionPlan";
import {
  productionPlanItemStatusMappers,
  productionPlanStatusMappers,
} from "./constants";

export const mapProductionPlanStatus = (key: ProductionPlanStatus) => {
  return productionPlanStatusMappers[key] || key;
};

export const mapProductionPlanItemStatus = (key: ProductionPlanItemStatus) => {
  return productionPlanItemStatusMappers[key] || key;
};
