import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Panel } from "./types";

export const CREATE_PANEL_URL = "/api/admin/panels";

export enum CreatePanelError {}

export const createPanelErrorMessages = {};

export interface CreatePanelArgs {
  name: string;
  description: string | null;
  workshopId: ID;
}

export type CreatePanelResponse = Response<Panel>;

export const createPanel = (args: CreatePanelArgs) => {
  return httpClient.post<CreatePanelResponse>(CREATE_PANEL_URL, args);
};

export const useCreatePanelMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreatePanelResponse>,
      AxiosError<any>,
      CreatePanelArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreatePanelResponse>,
    AxiosError<any>,
    CreatePanelArgs
  >({
    mutationFn: createPanel,
    mutationKey: [CREATE_PANEL_URL],
    ...opts,
  });
