import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Workshop } from "app/services/workshop";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailWorkshopGeneralProps {}

const DetailWorkshopGeneral: FC<DetailWorkshopGeneralProps> = () => {
  const { workshopText } = useAppTranslation();
  const { data: workshop } = useDetailPageContext<Workshop>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {workshopText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={workshopText.field.name}
                    value={workshop?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={workshopText.field.address}
                    value={workshop?.address}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={workshopText.field.description}
                    value={workshop?.description}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailWorkshopGeneral;
