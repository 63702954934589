import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Panel } from "./types";
import { Response } from "../types";

export const RETRIEVE_PANEL_BY_ID_URL = (id: ID) => `/api/admin/panels/${id}`;

export type RetrievePanelByIdResponse = Response<Panel>;

export type RetrievePanelByIdArgs = {
  id: ID;
};

export const retrievePanelById = ({ id }: RetrievePanelByIdArgs) => {
  return httpClient.get<RetrievePanelByIdResponse>(
    RETRIEVE_PANEL_BY_ID_URL(id),
  );
};

export const useRetrievePanelByIdQuery = (
  retrievePanelsArgs: RetrievePanelByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrievePanelByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePanelByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PANEL_BY_ID_URL(retrievePanelsArgs.id)],
    queryFn: () => retrievePanelById(retrievePanelsArgs),
    ...opts,
  });
