import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deactiveUserErrorMessages,
  RETRIEVE_USER_BY_ID_URL,
  RETRIEVE_USERS_URL,
  useDeactiveUserMutation,
} from "app/services/user";

interface UseDeactiveUserProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID;
}

export const useDeactiveUser = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: UseDeactiveUserProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeactiveUserMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_USERS_URL] });
      if (id)
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_USER_BY_ID_URL(id)],
        });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deactiveUserErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
