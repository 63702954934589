import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Step } from "./types";

export const RETRIEVE_STEPS_URL = "/api/admin/steps";

export interface RetrieveStepsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveStepsResponse = Response<PagingData<Step[]>>;

export const retrieveSteps = (args: RetrieveStepsArgs) => {
  return httpClient.get<RetrieveStepsResponse>(RETRIEVE_STEPS_URL, {
    params: args,
  });
};

export const useRetrieveStepsQuery = (
  retrieveStepsArgs: RetrieveStepsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveStepsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveStepsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_STEPS_URL, retrieveStepsArgs],
    queryFn: () => retrieveSteps(retrieveStepsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveStepsInfiniteQuery = (
  retrieveStepsArgs: RetrieveStepsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveStepsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveStepsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveStepsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_STEPS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveStepsArgs;
      return retrieveSteps({
        ...retrieveStepsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveStepsArgs;
      let nextPageParam: RetrieveStepsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveStepsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
