import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Workshop } from "./types";

export const RETRIEVE_WORKSHOPS_URL = "/api/admin/workshops";

export interface RetrieveWorkshopsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveWorkshopsResponse = Response<PagingData<Workshop[]>>;

export const retrieveWorkshops = (args: RetrieveWorkshopsArgs) => {
  return httpClient.get<RetrieveWorkshopsResponse>(RETRIEVE_WORKSHOPS_URL, {
    params: args,
  });
};

export const useRetrieveWorkshopsQuery = (
  retrieveWorkshopsArgs: RetrieveWorkshopsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveWorkshopsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveWorkshopsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_WORKSHOPS_URL, retrieveWorkshopsArgs],
    queryFn: () => retrieveWorkshops(retrieveWorkshopsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveWorkshopsInfiniteQuery = (
  retrieveWorkshopsArgs: RetrieveWorkshopsArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveWorkshopsResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveWorkshopsResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveWorkshopsResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_WORKSHOPS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveWorkshopsArgs;
      return retrieveWorkshops({
        ...retrieveWorkshopsArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveWorkshopsArgs;
      let nextPageParam: RetrieveWorkshopsArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveWorkshopsArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
