import { Navigate } from "react-router-dom";

// App
import Dashboard from "app/components/pages/dashboard/Dashboards";

// Auth
import LoginMain from "app/components/pages/auth/Login";
import LoginPanel from "app/components/pages/auth/LoginPanel";
import LogoutMain from "app/components/pages/auth/Logout";
import ResetPassword from "app/components/pages/auth/ResetPassword";

// Setting
import ProfileSetting from "app/components/pages/setting/ProfileSetting";

// Role
import ListRole from "app/components/pages/role/ListRole";

// Job Title
import ListJobTitle from "app/components/pages/jobTitle/ListJobTitle";

// Department
import ListDepartment from "app/components/pages/department/ListDepartment";

// Employee
import CreateEmployee from "app/components/pages/employee/CreateEmployee";
import DetailEmployee from "app/components/pages/employee/DetailEmployee";
import DetailEmployeeGeneral from "app/components/pages/employee/DetailEmployeeGeneral";
import ListEmployee from "app/components/pages/employee/ListEmployee";
import UpdateEmployee from "app/components/pages/employee/UpdateEmployee";

// User
import ListUser from "app/components/pages/user/ListUser";

// Master data
import ListMasterData from "app/components/pages/masterData/ListMasterData";

// Vendor
import CreateVendor from "app/components/pages/vendor/CreateVendor";
import DetailVendor from "app/components/pages/vendor/DetailVendor";
import DetailVendorGeneral from "app/components/pages/vendor/DetailVendorGeneral";
import ListVendor from "app/components/pages/vendor/ListVendor";
import UpdateVendor from "app/components/pages/vendor/UpdateVendor";

// Customer
import CreateCustomer from "app/components/pages/customer/CreateCustomer";
import DetailCustomer from "app/components/pages/customer/DetailCustomer";
import DetailCustomerGeneral from "app/components/pages/customer/DetailCustomerGeneral";
import ListCustomer from "app/components/pages/customer/ListCustomer";
import UpdateCustomer from "app/components/pages/customer/UpdateCustomer";

// Order
import CreateOrder from "app/components/pages/order/CreateOrder";
import DetailOrder from "app/components/pages/order/DetailOrder";
import DetailOrderGeneral from "app/components/pages/order/DetailOrderGeneral";
import DetailOrderOrderItems from "app/components/pages/order/DetailOrderOrderItems";
import DuplicateOrder from "app/components/pages/order/DuplicateOrder";
import ListOrder from "app/components/pages/order/ListOrder";
import UpdateOrder from "app/components/pages/order/UpdateOrder";

// Product
import CreateProduct from "app/components/pages/product/CreateProduct";
import DetailProduct from "app/components/pages/product/DetailProduct";
import DetailProductBom from "app/components/pages/product/DetailProductBom/DetailProductBom";
import DetailProductGeneral from "app/components/pages/product/DetailProductGeneral/DetailProductGeneral";
import DetailProductOrderItems from "app/components/pages/product/DetailProductOrderItems";
import ListProduct from "app/components/pages/product/ListProduct";
import UpdateProduct from "app/components/pages/product/UpdateProduct";

// Production Plan
import CreateProductionPlan from "app/components/pages/productionPlan/CreateProductionPlan";
import DetailProductionPlan from "app/components/pages/productionPlan/DetailProductionPlan";
import DetailProductionPlanGeneral from "app/components/pages/productionPlan/DetailProductionPlanGeneral";
import DetailProductionPlanItems from "app/components/pages/productionPlan/DetailProductionPlanItems";
import ListProductionPlan from "app/components/pages/productionPlan/ListProductionPlan";
import UpdateProductionPlan from "app/components/pages/productionPlan/UpdateProductionPlan";

// Production Order
import CreateProductionOrder from "app/components/pages/productionOrder/CreateProductionOrder";
import DetailProductionOrder from "app/components/pages/productionOrder/DetailProductionOrder";
import DetailProductionOrderGeneral from "app/components/pages/productionOrder/DetailProductionOrderGeneral";
import DetailProductionOrderItems from "app/components/pages/productionOrder/DetailProductionOrderItems";
import ListProductionOrder from "app/components/pages/productionOrder/ListProductionOrder";
import UpdateProductionOrder from "app/components/pages/productionOrder/UpdateProductionOrder";

// Workshop
import CreateWorkshop from "app/components/pages/workshop/CreateWorkshop";
import DetailWorkshop from "app/components/pages/workshop/DetailWorkshop";
import DetailWorkshopGeneral from "app/components/pages/workshop/DetailWorkshopGeneral";
import DetailWorkshopPanel from "app/components/pages/workshop/DetailWorkshopPanel";
import ListWorkshop from "app/components/pages/workshop/ListWorkshop";
import UpdateWorkshop from "app/components/pages/workshop/UpdateWorkshop";

// Ticket
import DetailTicket from "app/components/pages/ticket/DetailTicket";
import DetailTicketGeneral from "app/components/pages/ticket/DetailTicketGeneral";
import DetailTicketLogs from "app/components/pages/ticket/DetailTicketLogs";
import ListTicket from "app/components/pages/ticket/ListTicket";

// Step
import CreateStep from "app/components/pages/step/CreateStep";
import DetailStep from "app/components/pages/step/DetailStep";
import DetailStepGeneral from "app/components/pages/step/DetailStepGeneral";
import ListStep from "app/components/pages/step/ListStep";
import UpdateStep from "app/components/pages/step/UpdateStep";

// Panel
import Scanner from "app/components/pages/panel/CameraScanner";
import DetailTicketPanel from "app/components/pages/panel/DetailTicketPanel";
import ListTicketPanel from "app/components/pages/panel/ListTicketPanel";
import NFCScan from "app/components/pages/panel/NFCScan";

const appProtectedRoutes = [
  {
    index: true,
    component: <Navigate replace to="/employees" />,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/profile",
    component: <ProfileSetting />,
  },
  {
    path: "/roles",
    component: <ListRole />,
  },
  {
    path: "/job-titles",
    component: <ListJobTitle />,
  },
  {
    path: "/departments",
    component: <ListDepartment />,
  },
  {
    path: "/employee/new",
    component: <CreateEmployee />,
  },
  {
    path: "/employee/:id/update",
    component: <UpdateEmployee />,
  },
  {
    path: "/employees",
    component: <ListEmployee />,
  },
  {
    path: "/employee/:id",
    component: <DetailEmployee />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailEmployeeGeneral />,
      },
    ],
  },
  {
    path: "/users",
    component: <ListUser />,
  },
  {
    path: "/master-datas",
    component: <ListMasterData />,
  },
  {
    path: "/vendor/new",
    component: <CreateVendor />,
  },
  {
    path: "/vendor/:id/update",
    component: <UpdateVendor />,
  },
  {
    path: "/vendors",
    component: <ListVendor />,
  },
  {
    path: "/vendor/:id",
    component: <DetailVendor />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailVendorGeneral />,
      },
    ],
  },
  {
    path: "/customer/new",
    component: <CreateCustomer />,
  },
  {
    path: "/customer/:id/update",
    component: <UpdateCustomer />,
  },
  {
    path: "/customers",
    component: <ListCustomer />,
  },
  {
    path: "/customer/:id",
    component: <DetailCustomer />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailCustomerGeneral />,
      },
    ],
  },
  {
    path: "/order/new",
    component: <CreateOrder />,
  },
  {
    path: "/order/:id/update",
    component: <UpdateOrder />,
  },
  {
    path: "/order/:id/duplicate",
    component: <DuplicateOrder />,
  },
  {
    path: "/orders",
    component: <ListOrder />,
  },
  {
    path: "/order/:id",
    component: <DetailOrder />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailOrderGeneral />,
      },
      {
        path: "order-items",
        component: <DetailOrderOrderItems />,
      },
    ],
  },
  {
    path: "/product/new",
    component: <CreateProduct />,
  },
  {
    path: "/product/:id/update",
    component: <UpdateProduct />,
  },
  {
    path: "/products",
    component: <ListProduct />,
  },
  {
    path: "/product/:id",
    component: <DetailProduct />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailProductGeneral />,
      },
      {
        path: "order-items",
        component: <DetailProductOrderItems />,
      },
      {
        path: "bom",
        component: <DetailProductBom />,
      },
    ],
  },
  {
    path: "/production-plan/new",
    component: <CreateProductionPlan />,
  },
  {
    path: "/production-plan/:id/update",
    component: <UpdateProductionPlan />,
  },
  {
    path: "/production-plans",
    component: <ListProductionPlan />,
  },
  {
    path: "/production-plan/:id",
    component: <DetailProductionPlan />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailProductionPlanGeneral />,
      },
      {
        path: "items",
        component: <DetailProductionPlanItems />,
      },
    ],
  },
  {
    path: "/production-order/new",
    component: <CreateProductionOrder />,
  },
  {
    path: "/production-order/:id/update",
    component: <UpdateProductionOrder />,
  },
  {
    path: "/production-orders",
    component: <ListProductionOrder />,
  },
  {
    path: "/production-order/:id",
    component: <DetailProductionOrder />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailProductionOrderGeneral />,
      },
      {
        path: "items",
        component: <DetailProductionOrderItems />,
      },
    ],
  },
  {
    path: "/workshop/new",
    component: <CreateWorkshop />,
  },
  {
    path: "/workshop/:id/update",
    component: <UpdateWorkshop />,
  },
  {
    path: "/workshops",
    component: <ListWorkshop />,
  },
  {
    path: "/workshop/:id",
    component: <DetailWorkshop />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailWorkshopGeneral />,
      },
      {
        path: "panel",
        component: <DetailWorkshopPanel />,
      },
    ],
  },
  {
    path: "/tickets",
    component: <ListTicket />,
  },
  {
    path: "/ticket/:id",
    component: <DetailTicket />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailTicketGeneral />,
      },
      {
        path: "logs",
        component: <DetailTicketLogs />,
      },
    ],
  },
  {
    path: "/step/new",
    component: <CreateStep />,
  },
  {
    path: "/step/:id/update",
    component: <UpdateStep />,
  },
  {
    path: "/steps",
    component: <ListStep />,
  },
  {
    path: "/step/:id",
    component: <DetailStep />,
    children: [
      {
        index: true,
        component: <Navigate to="general" replace />,
      },
      {
        path: "general",
        component: <DetailStepGeneral />,
      },
    ],
  },
];

const appPublicRoutes = [
  {
    path: "/login",
    component: <LoginMain />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/logout",
    component: <LogoutMain />,
  },
];

const appPanelPublicRoutes = [
  {
    path: "/panel/login",
    component: <LoginPanel />,
  },
];

const appPanelProtectedRoutes = [
  {
    index: true,
    component: <Navigate to="scan" replace />,
  },
  {
    path: "camera-scan",
    component: <Scanner />,
    isPublic: true,
  },
  {
    path: "scan",
    component: <NFCScan />,
    isPublic: true,
  },
  {
    path: "tickets",
    component: <ListTicketPanel />,
  },
  {
    path: "ticket/:id",
    component: <DetailTicketPanel />,
  },
];

export {
  appPanelProtectedRoutes,
  appPanelPublicRoutes,
  appProtectedRoutes,
  appPublicRoutes,
};
