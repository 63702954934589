import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveStepById } from "app/modules/step/hooks";
import { Step } from "app/services/step";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailStepHeader from "./DetailStepHeader";
import { DetailStepTabs } from "./DetailStepTabs";

const DetailStep = () => {
  const { id } = useParams();
  const { stepText } = useAppTranslation();

  const { step, isLoadingStep } = useRetrieveStepById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <DetailPage<Step> data={step as Step} loading={isLoadingStep}>
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={stepText.detail.title}
          pageTitle={stepText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailStepHeader />
      </DetailPage.Header>
      <DetailPage.Main
        tabs={<DetailStepTabs />}
        // attachmentBar={
        //   <AttachmentBar
        //     imageProps={{
        //       data: [],
        //     }}
        //     documentProps={{
        //       data: [],
        //     }}
        //   />
        // }
      >
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailStep;
