import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { ProductionOrder } from "app/services/productionOrder";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailProductionOrderGeneralProps {}

const DetailProductionOrderGeneral: FC<
  DetailProductionOrderGeneralProps
> = () => {
  const { productionOrderText } = useAppTranslation();
  const { data: productionOrder } = useDetailPageContext<ProductionOrder>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {productionOrderText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={productionOrderText.field.name}
                    value={productionOrder?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionOrderText.field.startDate}
                    value={
                      productionOrder?.startDate
                        ? formatDate(productionOrder.startDate)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionOrderText.field.endDate}
                    value={
                      productionOrder?.startDate
                        ? formatDate(productionOrder.endDate)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionOrderText.field.note}
                    value={productionOrder?.note}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailProductionOrderGeneral;
