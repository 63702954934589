import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Ticket } from "./types";

export const RETRIEVE_PANEL_TICKET_BY_ID_URL = (id: ID) =>
  `/api/panel/tickets/${id}`;

export type RetrievePanelTicketByIdResponse = Response<Ticket>;

export type RetrievePanelTicketByIdArgs = {
  id: ID;
};

export const retrievePanelTicketById = ({
  id,
}: RetrievePanelTicketByIdArgs) => {
  return httpClientPanel.get<RetrievePanelTicketByIdResponse>(
    RETRIEVE_PANEL_TICKET_BY_ID_URL(id),
  );
};

export const useRetrievePanelTicketByIdQuery = (
  retrievePanelTicketsArgs: RetrievePanelTicketByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrievePanelTicketByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePanelTicketByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PANEL_TICKET_BY_ID_URL(retrievePanelTicketsArgs.id)],
    queryFn: () => retrievePanelTicketById(retrievePanelTicketsArgs),
    ...opts,
  });
