import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { TicketLog } from "./types";
import { Response } from "../types";

export const RETRIEVE_TICKET_LOG_BY_ID_URL = (id: ID) =>
  `/api/admin/ticket-logs/${id}`;

export type RetrieveTicketLogByIdResponse = Response<TicketLog>;

export type RetrieveTicketLogByIdArgs = {
  id: ID;
};

export const retrieveTicketLogById = ({ id }: RetrieveTicketLogByIdArgs) => {
  return httpClient.get<RetrieveTicketLogByIdResponse>(
    RETRIEVE_TICKET_LOG_BY_ID_URL(id),
  );
};

export const useRetrieveTicketLogByIdQuery = (
  retrieveTicketLogsArgs: RetrieveTicketLogByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveTicketLogByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveTicketLogByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_TICKET_LOG_BY_ID_URL(retrieveTicketLogsArgs.id)],
    queryFn: () => retrieveTicketLogById(retrieveTicketLogsArgs),
    ...opts,
  });
