import React, { useEffect, useRef, useState } from "react";

const CameraScanner: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);

  useEffect(() => {
    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: "user", // Use the front-facing camera
          },
        });
        console.log(stream);

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setIsCameraEnabled(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    enableCamera();

    return () => {
      const stream = videoRef.current?.srcObject as MediaStream;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleCapture = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const context = canvas.getContext("2d");

    if (context) {
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL("image/png");
      console.log(imageData);
      // onScan(imageData);
    } else {
      // onError("Unable to capture image.");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Camera Scanner</h2>
      {isCameraEnabled ? (
        <>
          <video
            ref={videoRef}
            style={{ maxWidth: "100%", margin: "10px auto" }}
          >
            <track kind="captions" />
          </video>
          <br />
          <button onClick={handleCapture} style={{ marginTop: "10px" }}>
            Capture Image
          </button>
        </>
      ) : (
        <p>Initializing camera...</p>
      )}
    </div>
  );
};

export default CameraScanner;

// Usage Example:
// import React from 'react';
// import ReactDOM from 'react-dom';
// import CameraScanner from './CameraScanner';

// const App: React.FC = () => {
//   const handleScan = (data: string) => {
//     console.log('Scanned Data:', data);
//   };

//   const handleError = (error: string) => {
//     console.error('Error:', error);
//   };

//   return <CameraScanner onScan={handleScan} onError={handleError} />;
// };

// ReactDOM.render(<App />, document.getElementById('root'));
