import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Product } from "app/services/product";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailProductGeneralProps {}

const DetailProductGeneral: FC<DetailProductGeneralProps> = () => {
  const { productText } = useAppTranslation();
  const { data: product } = useDetailPageContext<Product>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {productText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.code}
                    value={product?.code}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.nameVn}
                    value={product?.nameVn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.nameEn}
                    value={product?.nameEn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.type}
                    value={
                      product?.productType ? (
                        <Chip
                          size="sm"
                          color={product?.productType?.colorCode}
                          label={product?.productType?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.group}
                    value={
                      product?.productGroup ? (
                        <Chip
                          size="sm"
                          color={product?.productGroup?.colorCode}
                          label={product?.productGroup?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.unit}
                    value={
                      product?.productUnit ? (
                        <Chip
                          size="sm"
                          color={product?.productUnit?.colorCode}
                          label={product?.productUnit?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.countryOfOrigin}
                    value={product?.countryOfOrigin}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.isActive}
                    value={product?.isActive ? "Có" : "Không"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.description}
                    value={product?.description}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.lengthCm}
                    value={product?.lengthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.widthCm}
                    value={product?.widthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.heightCm}
                    value={product?.heightCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.seatSizeCm}
                    value={product?.seatSizeCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.weightKg}
                    value={product?.weightKg}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.color}
                    value={product?.color}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.packagingLengthCm}
                    value={product?.packageLengthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.packagingWidthCm}
                    value={product?.packageWidthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.packagingHeightCm}
                    value={product?.packageHeightCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.boxQuantity}
                    value={product?.packageBoxCount}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailProductGeneral;
