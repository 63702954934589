import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { EmployeeSelect } from "app/modules/employee/components";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface TicketLogFormProps {
  defaultValues?: TicketLogFormData;
  onSubmit: (data: TicketLogFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
  edit?: boolean;
}

export interface TicketLogFormData {
  quantity: number | null;
  note: string;
  employee: OptionSelect | null;
  totalQuantity?: number | null;
  remainingQuantity?: number | null;
  defaultQuantity?: number | null;
}

export const TicketLogForm: FC<TicketLogFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
  edit,
}) => {
  const { ticketText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm<TicketLogFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        quantity: yupSchema
          .numberMoreThanRequired(
            0,
            "ticket.error.quantityMoreThanZero",
            "ticket.error.quantityRequired",
          )
          .max(Yup.ref("remainingQuantity"), "ticket.error.quantityInvalid"),
        employee: yupSchema.objectRequired("ticket.error.employeeRequired"),
        note: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="master-data-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="employee" className="form-label">
              {ticketText.field.employee}
            </FormLabel>
            <Controller
              name="employee"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <EmployeeSelect
                    isDisabled
                    name="employee"
                    value={field.value}
                    inputId="employee"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <Alert color="info" fade={false}>
            <div>Tổng số lượng: {getValues("totalQuantity")}</div>
            <div>
              Số lượng có thể check in: {getValues("remainingQuantity")}
            </div>
          </Alert>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="quantity" className="form-label">
              {ticketText.field.quantity}
            </FormLabel>
            <Controller
              name="quantity"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                      // const totalQuantity = getValues("totalQuantity") || 0;
                      // const nValue = Number(value.target.value) || 0;
                      // // Đã nhập trước đó
                      // const currentQuantity = watch("defaultQuantity") || 0;
                      // // Có thể check in
                      // const remainingQuantity = totalQuantity - currentQuantity;
                      // const newRemaningValue =
                      //   remainingQuantity - nValue + currentQuantity;
                      // const isInvalid = newRemaningValue < 0 || nValue <= 0;
                      // if (isInvalid) {
                      //   setValue("remainingQuantity", remainingQuantity);
                      // } else {
                      //   setValue("remainingQuantity", newRemaningValue);
                      // }
                    }}
                    type="number"
                    className="form-control"
                    id="quantity"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="note" className="form-label">
              {ticketText.field.note}
            </FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    disabled={edit}
                    {...field}
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Nhập"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
