import {
  RetrieveProductionPlanByIdArgs,
  useRetrieveProductionPlanByIdQuery,
} from "app/services/productionPlan";

export const useRetrieveProductionPlanById = (
  args: RetrieveProductionPlanByIdArgs,
  opts?: Parameters<typeof useRetrieveProductionPlanByIdQuery>[1],
) => {
  const result = useRetrieveProductionPlanByIdQuery(args, opts as any);
  return {
    productionPlan: result.data?.data.data,
    isLoadingProductionPlan: result.isLoading,
    ...result,
  };
};
