import { useAppTranslation } from "app/hooks";
import { OptionValue } from "app/models";
import { OptionSelect } from "app/services/types";
import React from "react";
import Select, { Props } from "react-select";
import { useRetrieveProductById } from "../../../product/hooks";

export type ProductStepOptionSelect = OptionSelect<ID>;

export interface ProductStepSelectProps
  extends Omit<Props<ProductStepOptionSelect>, "value" | "onChange"> {
  id?: ID;
  value: OptionValue;
  onChange: (value: OptionValue) => void;
  isInvalid?: boolean;
}

export const ProductStepSelect: React.FC<ProductStepSelectProps> = ({
  value,
  id,
  onChange,
  isInvalid,
  ...props
}) => {
  const { placeHolderText, noDataTableText } = useAppTranslation();
  const { product, isLoadingProduct } = useRetrieveProductById(
    {
      id: id as string,
    },
    { enabled: !!id },
  );

  const componentsItems = product?.productSteps || [];

  return (
    <>
      <Select
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => noDataTableText}
        value={value}
        isLoading={isLoadingProduct}
        onChange={onChange}
        options={
          componentsItems.map((item) => ({
            value: item.id,
            label: item.step.name,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
            ":hover": {
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
            },
          }),
        }}
      />
    </>
  );
};
