import { useAppTranslation, useRouteMatch } from "app/hooks";
import classnames from "classnames";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

export interface DetailWorkshopTabsProps {}

export enum DetailWorkshopTabEnum {
  General = "/workshop/:id/general",
  Pos = "/workshop/:id/panel",
}

export const DetailWorkshopTabs: FC<DetailWorkshopTabsProps> = () => {
  const { workshopText } = useAppTranslation();
  const { id } = useParams();
  const routeMatch = useRouteMatch([
    "/workshop/:id/general",
    "/workshop/:id/panel",
  ]);

  const currentTab = routeMatch?.pattern?.path ?? DetailWorkshopTabEnum.General;

  return (
    <div className="d-flex px-3">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <Link
            to={`/workshop/${id}/general`}
            className={classnames(
              {
                active: currentTab === DetailWorkshopTabEnum.General,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {workshopText.detail.tab.general}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/workshop/${id}/panel`}
            className={classnames(
              {
                active: currentTab === DetailWorkshopTabEnum.Pos,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {workshopText.detail.tab.panel}
            </span>
          </Link>
        </NavItem>
      </Nav>
    </div>
  );
};
