import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "./types";

export const START_TICKET_URL = (id: ID) => `/api/admin/tickets/${id}/start`;

export enum StartTicketError {}

export const startTicketErrorMessages = {};

export interface StartTicketArgs {
  id: ID;
  employeeId: ID;
}

export type StartTicketResponse = Response<Ticket>;

export const startTicket = ({ id, ...args }: StartTicketArgs) => {
  return httpClientPanel.post<StartTicketResponse>(START_TICKET_URL(id), args);
};

export const useStartTicketMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<StartTicketResponse>,
      AxiosError<any>,
      StartTicketArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<StartTicketResponse>,
    AxiosError<any>,
    StartTicketArgs
  >({
    mutationFn: startTicket,
    mutationKey: [START_TICKET_URL],
    ...opts,
  });
