import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import { RETRIEVE_TICKET_BY_ID_URL } from "app/services/ticket";
import {
  deletedTicketLogErrorMessages,
  useDeleteTicketLogMutation,
} from "app/services/ticketLog";

interface UseDeleteTicketLogProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID;
}

export const useDeleteTicketLog = ({
  id,
  onError: handleError,
  onSuccess: handleSuccess,
}: UseDeleteTicketLogProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteTicketLogMutation({
    onSuccess: () => {
      toast.success();
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_TICKET_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deletedTicketLogErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
