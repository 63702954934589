import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createProductionOrderErrorMessages,
  RETRIEVE_PRODUCTION_ORDERS_URL,
  useCreateProductionOrderMutation,
} from "app/services/productionOrder";

interface UseCreateProductionOrderProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateProductionOrder = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateProductionOrderProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateProductionOrderMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_PRODUCTION_ORDERS_URL],
      });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createProductionOrderErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
