import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "../types";
import { Ticket, TicketStatus } from "./types";

export const RETRIEVE_PANEL_TICKETS_URL = "/api/panel/tickets";

export interface RetrievePanelTicketsArgs {
  page: number;
  limit: number;
  searchString?: string;
  status?: TicketStatus[];
  productStepIds?: ID[];
  assigneeIds?: ID[];
  workshopIds?: ID[];
}

export type RetrievePanelTicketsResponse = Response<PagingData<Ticket[]>>;

export const retrievePanelTickets = (args: RetrievePanelTicketsArgs) => {
  return httpClientPanel.get<RetrievePanelTicketsResponse>(
    RETRIEVE_PANEL_TICKETS_URL,
    {
      params: args,
    },
  );
};

export const useRetrievePanelTicketsQuery = (
  retrievePanelTicketsArgs: RetrievePanelTicketsArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrievePanelTicketsResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePanelTicketsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PANEL_TICKETS_URL, retrievePanelTicketsArgs],
    queryFn: () => retrievePanelTickets(retrievePanelTicketsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
