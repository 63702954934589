import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { ProductionOrder } from "./types";

export const CREATE_PRODUCTION_ORDER_URL = "/api/admin/production-orders";

export enum CreateProductionOrderError {}

export const createProductionOrderErrorMessages = {};

export interface CreateProductionOrderArgs {
  name: string;
  planId: ID;
  startDate: DateTimeString;
  endDate: DateTimeString;
  note: string | null;
  productionOrderItems: {
    planItemId: ID;
    quantity: number;
    note: string | null;
  }[];
}

export type CreateProductionOrderResponse = Response<ProductionOrder>;

export const createProductionOrder = (args: CreateProductionOrderArgs) => {
  return httpClient.post<CreateProductionOrderResponse>(
    CREATE_PRODUCTION_ORDER_URL,
    args,
  );
};

export const useCreateProductionOrderMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateProductionOrderResponse>,
      AxiosError<any>,
      CreateProductionOrderArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateProductionOrderResponse>,
    AxiosError<any>,
    CreateProductionOrderArgs
  >({
    mutationFn: createProductionOrder,
    mutationKey: [CREATE_PRODUCTION_ORDER_URL],
    ...opts,
  });
