import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  ProductionPlanForm,
  ProductionPlanFormData,
} from "app/modules/productionPlan/components";
import { useCreateProductionPlan } from "app/modules/productionPlan/hooks";
import {
  mapCreateProductionPlan,
  mapDefaultProductionPlan,
} from "app/modules/productionPlan/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateProductionPlanProps {}

const CreateProductionPlan: FC<CreateProductionPlanProps> = () => {
  const { productionPlanText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateProductionPlan({
    onSuccess: () => {
      navigate("/production-plans");
    },
  });

  const handleSubmit = async (data: ProductionPlanFormData) => {
    mutateAsync(mapCreateProductionPlan(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={productionPlanText.add}
          pageTitle={productionPlanText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <ProductionPlanForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultProductionPlan()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateProductionPlan;
