import {
  RetrieveWorkshopByIdArgs,
  useRetrieveWorkshopByIdQuery,
} from "app/services/workshop";

export const useRetrieveWorkshopById = (
  args: RetrieveWorkshopByIdArgs,
  opts?: Parameters<typeof useRetrieveWorkshopByIdQuery>[1],
) => {
  const result = useRetrieveWorkshopByIdQuery(args, opts as any);
  return {
    workshop: result.data?.data.data,
    isLoadingWorkshop: result.isLoading,
    ...result,
  };
};
