import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveWorkshopById } from "app/modules/workshop/hooks";
import { Workshop } from "app/services/workshop";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailWorkshopHeader from "./DetailWorkshopHeader";
import { DetailWorkshopTabs } from "./DetailWorkshopTabs";

const DetailWorkshop = () => {
  const { id } = useParams();
  const { workshopText } = useAppTranslation();

  const { workshop, isLoadingWorkshop } = useRetrieveWorkshopById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <DetailPage<Workshop>
      data={workshop as Workshop}
      loading={isLoadingWorkshop}
    >
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={workshopText.detail.title}
          pageTitle={workshopText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailWorkshopHeader />
      </DetailPage.Header>
      <DetailPage.Main
        tabs={<DetailWorkshopTabs />}
        // attachmentBar={
        //   <AttachmentBar
        //     imageProps={{
        //       data: [],
        //     }}
        //     documentProps={{
        //       data: [],
        //     }}
        //   />
        // }
      >
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailWorkshop;
