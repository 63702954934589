import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { TicketLog } from "./types";

export const UPDATE_TICKET_LOG_URL = (id: ID) => `/api/admin/ticket-logs/${id}`;

export enum UpdateTicketLogError {}

export const updateTicketLogErrorMessages = {};

export type UpdateTicketLogResponse = Response<TicketLog>;

export type UpdateTicketLogArgs = {
  id: ID;
  quantity: number;
};

export const updateTicketLog = ({ id, ...args }: UpdateTicketLogArgs) => {
  return httpClient.put<UpdateTicketLogResponse>(
    UPDATE_TICKET_LOG_URL(id),
    args,
  );
};

export const useUpdateTicketLogMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateTicketLogResponse>,
      AxiosError<any>,
      UpdateTicketLogArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateTicketLogResponse>,
    AxiosError<any>,
    UpdateTicketLogArgs
  >({
    mutationFn: updateTicketLog,
    mutationKey: [UPDATE_TICKET_LOG_URL],
    ...opts,
  });
