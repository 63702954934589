import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  RETRIEVE_TICKET_BY_ID_URL,
  startTicketErrorMessages,
  useStartTicketMutation,
} from "app/services/ticket";

interface StartTicketProps {
  id?: ID;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useStartTicket = ({
  id,
  onError: handleError,
  onSuccess: handleSuccess,
}: StartTicketProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useStartTicketMutation({
    onSuccess: () => {
      toast.success();
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_TICKET_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          startTicketErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
