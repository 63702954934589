import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Workshop } from "./types";

export const CREATE_WORKSHOP_URL = "/api/admin/workshops";

export enum CreateWorkshopError {}

export const createWorkshopErrorMessages = {};

export interface CreateWorkshopArgs {
  name: string;
  description: string | null;
  address: string | null;
}

export type CreateWorkshopResponse = Response<Workshop>;

export const createWorkshop = (args: CreateWorkshopArgs) => {
  return httpClient.post<CreateWorkshopResponse>(CREATE_WORKSHOP_URL, args);
};

export const useCreateWorkshopMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateWorkshopResponse>,
      AxiosError<any>,
      CreateWorkshopArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateWorkshopResponse>,
    AxiosError<any>,
    CreateWorkshopArgs
  >({
    mutationFn: createWorkshop,
    mutationKey: [CREATE_WORKSHOP_URL],
    ...opts,
  });
