import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveVendorById } from "app/modules/vendor/hooks";
import { Vendor } from "app/services/vendor";
import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import DetailVendorHeader from "./DetailVendorHeader";
import { DetailVendorTabs } from "./DetailVendorTabs";

const DetailVendor = () => {
  const { id } = useParams();
  const { vendorText } = useAppTranslation();

  const { vendor, isLoadingVendor } = useRetrieveVendorById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <React.Fragment>
      <DetailPage<Vendor> data={vendor as Vendor} loading={isLoadingVendor}>
        <DetailPage.BreadCrumb>
          <BreadCrumb
            title={vendorText.detail.title}
            pageTitle={vendorText.title}
            isBack
          />
        </DetailPage.BreadCrumb>

        <DetailPage.Header>
          <DetailVendorHeader />
        </DetailPage.Header>
        <DetailPage.Main
          tabs={<DetailVendorTabs />}
          // attachmentBar={
          //   <AttachmentBar
          //     imageProps={{ data: [] }}
          //     documentProps={{ data: [] }}
          //   />
          // }
        >
          <Outlet />
        </DetailPage.Main>
      </DetailPage>
    </React.Fragment>
  );
};

export default DetailVendor;
