import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Role } from "./types";

export const CREATE_ROLE_URL = "/api/admin/roles";

export enum CreateRoleError {
  Existed = "Role already existed",
}

export const createRoleErrorMessages = {
  [CreateRoleError.Existed]: "Vai trò đã tồn tại",
};

export interface CreateRoleArgs {
  name: string;
  description?: string;
  permissionIds: ID[];
}

export type CreateRoleResponse = Response<Role>;

export const createRole = (args: CreateRoleArgs) => {
  return httpClient.post<CreateRoleResponse>(CREATE_ROLE_URL, args);
};

export const useCreateRoleMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateRoleResponse>,
      AxiosError<any>,
      CreateRoleArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateRoleResponse>,
    AxiosError<any>,
    CreateRoleArgs
  >({
    mutationFn: createRole,
    mutationKey: [CREATE_ROLE_URL],
    ...opts,
  });
