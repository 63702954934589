import {
  RetrieveProductBomByIdArgs,
  useRetrieveProductBomByIdQuery,
} from "app/services/product";

export const useRetrieveProductBomById = (
  args: RetrieveProductBomByIdArgs,
  opts?: Parameters<typeof useRetrieveProductBomByIdQuery>[1],
) => {
  const result = useRetrieveProductBomByIdQuery(args, opts as any);
  return {
    productBom: result.data?.data.data,
    isLoadingProductBom: result.isLoading,
    ...result,
  };
};
