import { Chip } from "app/components/atoms/Chip";
import { Button } from "app/components/molecules/Button";
import { useAppTranslation } from "app/hooks";
import { useRetrievePanelTickets } from "app/modules/ticket/hooks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { usePanelEmployee } from "Routes/PanelEmployeeProtected";

export interface ListTicketPanelProps {}

const ListTicketPanel: FC<ListTicketPanelProps> = () => {
  const navigate = useNavigate();
  const { buttonText, ticketText, authText } = useAppTranslation();

  const { employee } = usePanelEmployee();

  const { tickets: _tickets } = useRetrievePanelTickets({ page: 1, limit: 10 });

  const tickets = [
    {
      id: "1",
      name: "Chân ghế",
      quantity: 60,
      step: "Sơn",
      image:
        "https://www.furniturestop.co.uk/cdn/shop/products/Kingston-Corner-Sofa-Sofas-8_2048x.jpg?v=1644278108",
    },
    {
      id: "2",
      name: "Chân ghế",
      quantity: 60,
      step: "Sơn",
      image:
        "https://www.furniturestop.co.uk/cdn/shop/products/Kingston-Corner-Sofa-Sofas-8_2048x.jpg?v=1644278108",
    },
    {
      id: "3",
      name: "Chân ghế",
      quantity: 60,
      step: "Sơn",
      image:
        "https://www.furniturestop.co.uk/cdn/shop/products/Kingston-Corner-Sofa-Sofas-8_2048x.jpg?v=1644278108",
    },
    {
      id: "4",
      name: "Chân ghế",
      quantity: 60,
      step: "Sơn",
      image:
        "https://www.furniturestop.co.uk/cdn/shop/products/Kingston-Corner-Sofa-Sofas-8_2048x.jpg?v=1644278108",
    },
  ];

  const avatar = employee?.avatar;
  return (
    <div className="p-3 w-full">
      <div className="p-3 border border-2 border-black rounded">
        <div className="d-flex flex-column flex-sm-row gap-3 align-items-center">
          <div className="avatar-lg d-flex justify-content-center align-items-center">
            {avatar ? (
              <img
                src={avatar?.attachmentUrl}
                alt={employee.fullName}
                className="img-thumbnail rounded-4 w-100 h-100"
              />
            ) : (
              <i className="ri-account-circle-fill text-body"></i>
            )}
          </div>
          <div>
            <h3 className="mb-1">{employee?.fullName}</h3>
            <div className="d-flex  gap-2">
              Kỹ năng:
              {employee?.employeeSkills?.map((skill) => (
                <Chip size="sm" key={skill.id} label={skill.skill.name} />
              ))}
            </div>
          </div>
          <div className="ms-auto">
            <Button color="primary">{authText.logout}</Button>
          </div>
        </div>
      </div>
      <div className="my-3">
        <h3 className="mb-3">{ticketText.detail.ticketList}</h3>
        <Row className="row-cols-xxl-5 row-cols-md-3 row-cols-sm-2 row-cols-1">
          {tickets.map((ticket) => (
            <Col key={ticket.id}>
              <Card className="card-body">
                <div className="d-flex mb-4 align-items-center">
                  <div className="avatar-md d-flex justify-content-center align-items-center">
                    <img
                      src="https://www.furniturestop.co.uk/cdn/shop/products/Kingston-Corner-Sofa-Sofas-8_2048x.jpg?v=1644278108"
                      alt=""
                      className="img-thumbnail rounded-3 w-100 h-100"
                    />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h5 className="card-title mb-2">
                      {ticketText.field.detail}: {ticket.name}
                    </h5>
                    <h6 className="text-muted mb-2">
                      {ticketText.field.productStep}: {ticket.step}
                    </h6>
                    <h6 className="text-muted mb-0">
                      {ticketText.field.quantity}: {ticket.quantity}
                    </h6>
                  </div>
                </div>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => navigate(`/panel/ticket/${ticket.id}`)}
                >
                  {buttonText.detail}
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ListTicketPanel;
