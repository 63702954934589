import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Ticket } from "./types";

export const DELETE_TICKET_URL = (id: ID) => `/api/admin/tickets/${id}`;

export enum DeleteTicketError {}

export const deletedTicketErrorMessages = {};

export type DeleteTicketResponse = Response<Ticket>;

export type DeleteTicketArgs = {
  id: ID;
};

export const deleteTicket = ({ id }: DeleteTicketArgs) => {
  return httpClient.delete<DeleteTicketResponse>(DELETE_TICKET_URL(id));
};

export const useDeleteTicketMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteTicketResponse>,
    AxiosError<Response<null>>,
    DeleteTicketArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_TICKET_URL],
    mutationFn: (deletedTicketArgs: DeleteTicketArgs) =>
      deleteTicket(deletedTicketArgs),
    ...opts,
  });
};
