import { ColumnDef } from "@tanstack/react-table";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  TicketAction,
  TicketActionDropdown,
} from "app/modules/ticket/components";
import {
  TicketProductionOrderItemFormData,
  TicketProductionOrderItemFormModal,
} from "app/modules/ticket/components/TicketProductionOrderItemFormModal";
import {
  useDeleteTicket,
  useRetrieveTickets,
  useUpdateTicket,
} from "app/modules/ticket/hooks";
import { mapUpdateTicket } from "app/modules/ticket/mapper";
import { Ticket, TicketStatus } from "app/services/ticket";
import { OptionSelect } from "app/services/types";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListTicketFilter } from "./ListTicketFilter";
import { ListTicketFilterChips } from "./ListTicketFilterChips";

interface ListTicketProps {}

const ListTicket: FC<ListTicketProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { ticketText, confirmText } = useAppTranslation();
  const [isModalOpen, toggleModal] = useState(false);
  const rowSelected = useRef<Ticket | null>(null);

  const handleToggleModal = (data?: Ticket) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isModalOpen) {
      rowSelected.current = null;
    }
    toggleModal((prevState) => !prevState);
  };

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{
    status: OptionSelect<TicketStatus>[];
    productSteps: OptionSelect[];
    assignees: OptionSelect[];
    workshops: OptionSelect[];
  }>({
    initialPage: 1,
    initialLimit: 20,
    initialSearchString: "",
    initialCustomParams: {
      status: [],
      productSteps: [],
      assignees: [],
      workshops: [],
    },
  });

  const { tickets, isLoadingTickets } = useRetrieveTickets({
    limit,
    page,
    searchString: searchString || undefined,
    status: customParams.status.map((item) => item.value),
    productStepIds: customParams.productSteps.map((item) => item.value),
    assigneeIds: customParams.assignees.map((item) => item.value),
    workshopIds: customParams.workshops.map((item) => item.value),
  });

  const handleClearFilter = () => {
    setCustomParams({
      status: [],
      productSteps: [],
      assignees: [],
      workshops: [],
    });
  };

  const { mutateAsync: deleteTicket } = useDeleteTicket();

  const handleActionClick = (action: TicketAction, data?: Ticket) => {
    switch (action) {
      case "edit":
        if (data) {
          handleToggleModal(data);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/ticket/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(ticketText.title),
            description: confirmText.deleteContent(ticketText.title),
            onConfirm: () => deleteTicket({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Ticket>[] = useMemo(
    () => [
      {
        header: ticketText.field.component,
        accessorKey: "product",
        cell: ({ row }) => row.original?.productStep.product.nameVn || "-",
      },
      {
        header: ticketText.field.productStep,
        accessorKey: "productStep",
        cell: ({ row }) => row.original?.productStep.step.name || "-",
      },
      {
        header: ticketText.field.workshop,
        accessorKey: "workshop",
        cell: ({ row }) => row.original?.workshop.name || "-",
      },
      {
        header: ticketText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original?.quantity || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <TicketActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [ticketText],
  );

  const { mutateAsync: updateTicket, isPending: isCreating } = useUpdateTicket({
    onSuccess: () => {
      handleToggleModal();
    },
  });

  const handleUpdateTicket = (data: TicketProductionOrderItemFormData) => {
    updateTicket(
      mapUpdateTicket({ id: rowSelected.current?.id as ID, ...data }),
    );
  };

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={ticketText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <ListTicketFilter
                searchString={searchString}
                handleSearchChange={setSearchString}
                filterParams={{
                  status: customParams.status,
                  productSteps: customParams.productSteps,
                  assignees: customParams.assignees,
                  workshops: customParams.workshops,
                }}
                setCustomParams={setCustomParams}
              />
            </Col>
            <Col xs={12}>
              <ListTicketFilterChips
                filterParams={{
                  status: customParams.status,
                  productSteps: customParams.productSteps,
                  assignees: customParams.assignees,
                  workshops: customParams.workshops,
                }}
                setCustomParams={setCustomParams}
                handleClearFilter={handleClearFilter}
              />
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Ticket>
            columns={columns}
            data={tickets?.result || []}
            loading={isLoadingTickets}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={tickets?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
      <TicketProductionOrderItemFormModal
        open={isModalOpen}
        onToggle={handleToggleModal}
        onSubmit={handleUpdateTicket}
        submitting={isCreating}
        data={rowSelected.current || null}
        edit
      />
    </React.Fragment>
  );
};

export default ListTicket;
