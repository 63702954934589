import { Chip } from "app/components/atoms/Chip";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { TicketAction } from "app/modules/ticket/components";
import { mapTicketStatus } from "app/modules/ticket/helpers";
import { Ticket, TicketStatus } from "app/services/ticket";
import { FC } from "react";
import { Button, Col, Row } from "reactstrap";

export interface DetailTicketHeaderProps {
  onActionClick?: (action: TicketAction) => void;
}

const DetailTicketHeader: FC<DetailTicketHeaderProps> = ({ onActionClick }) => {
  const { buttonText, ticketText } = useAppTranslation();

  const { data: ticket } = useDetailPageContext<Ticket>();

  const avatar = false;

  const handleActionClick = (action: TicketAction) => () => {
    onActionClick?.(action);
  };

  const isInprogress = ticket?.status === TicketStatus.InProgress;
  const isCompleted = ticket?.status === TicketStatus.Completed;
  const isTodo = ticket?.status === TicketStatus.Todo;

  return (
    <Row className="g-4">
      <div className="col-auto">
        <div className="avatar-lg d-flex justify-content-center align-items-center">
          {avatar ? (
            <img
              src={avatar}
              alt="user-img"
              className="img-thumbnail rounded-4 w-100 h-100"
            />
          ) : (
            <i className="ri-task-line text-body"></i>
          )}
        </div>
      </div>

      <Col>
        <div className="p-2">
          <h3>{ticket?.product.nameVn}</h3>
          <div className="mb-1">
            Trạng thái: &nbsp;
            {ticket?.status ? (
              <Chip
                size="sm"
                color="primary"
                label={mapTicketStatus(ticket.status)}
              />
            ) : (
              "-"
            )}
          </div>
          <div className="text-muted">
            {ticketText.field.productStep}: {ticket?.productStep.step.name}
            &nbsp;-&nbsp;
            {ticketText.field.quantity}: {ticket?.quantity}
          </div>
        </div>
      </Col>
      {!isCompleted ? (
        <Col className="col-lg-auto order-last order-lg-0">
          <div className="d-flex gap-2 justify-content-end">
            <Button color="primary" onClick={handleActionClick("edit")}>
              {buttonText.edit}
            </Button>
            {isTodo ? (
              <Button color="primary" onClick={handleActionClick("start")}>
                {ticketText.startTitle}
              </Button>
            ) : null}
            {isInprogress ? (
              <Button color="primary" onClick={handleActionClick("checkIn")}>
                {ticketText.checkInTitle}
              </Button>
            ) : null}
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

export default DetailTicketHeader;
