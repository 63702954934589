import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { ProductionPlan, ProductionPlanStatus } from "./types";

export const CREATE_PRODUCTION_PLAN_URL = "/api/admin/production-plans";

export enum CreateProductionPlanError {}

export const createProductionPlanErrorMessages = {};

export interface CreateProductionPlanArgs {
  name: string;
  description: string;
  startDate: DateTimeString;
  endDate: DateTimeString;
  items: {
    orderItemId: ID;
    quantity: number;
  }[];
  // status: ProductionPlanStatus;
}

export type CreateProductionPlanResponse = Response<ProductionPlan>;

export const createProductionPlan = (args: CreateProductionPlanArgs) => {
  return httpClient.post<CreateProductionPlanResponse>(
    CREATE_PRODUCTION_PLAN_URL,
    args,
  );
};

export const useCreateProductionPlanMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateProductionPlanResponse>,
      AxiosError<any>,
      CreateProductionPlanArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateProductionPlanResponse>,
    AxiosError<any>,
    CreateProductionPlanArgs
  >({
    mutationFn: createProductionPlan,
    mutationKey: [CREATE_PRODUCTION_PLAN_URL],
    ...opts,
  });
