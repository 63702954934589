import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductionOrder } from "./types";

export const UPDATE_PRODUCTION_ORDER_URL = (id: ID) =>
  `/api/admin/production-orders/${id}`;

export enum UpdateProductionOrderError {}

export const updateProductionOrderErrorMessages = {};

export type UpdateProductionOrderResponse = Response<ProductionOrder>;

export type UpdateProductionOrderArgs = {
  id: ID;
  name: string;
  planId: ID;
  startDate: DateTimeString;
  endDate: DateTimeString;
  note: string | null;
  productionOrderItems: {
    id?: ID;
    planItemId: ID;
    quantity: number;
    note: string | null;
  }[];
};

export const updateProductionOrder = ({
  id,
  ...args
}: UpdateProductionOrderArgs) => {
  return httpClient.put<UpdateProductionOrderResponse>(
    UPDATE_PRODUCTION_ORDER_URL(id),
    args,
  );
};

export const useUpdateProductionOrderMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductionOrderResponse>,
      AxiosError<any>,
      UpdateProductionOrderArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateProductionOrderResponse>,
    AxiosError<any>,
    UpdateProductionOrderArgs
  >({
    mutationFn: updateProductionOrder,
    mutationKey: [UPDATE_PRODUCTION_ORDER_URL],
    ...opts,
  });
