import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import { useRetrieveProductBomById } from "app/modules/product/hooks/useRetrieveProductBomById";
import { BomTreeItem } from "app/services/product";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export interface DetailProductBomProps {}

const DetailProductBom: FC<DetailProductBomProps> = () => {
  const { id } = useParams();
  const { productText } = useAppTranslation();

  const { productBom, isLoadingProductBom } = useRetrieveProductBomById(
    { id: id as string },
    { enabled: !!id },
  );

  const columns: ColumnDef<BomTreeItem>[] = useMemo(
    () => [
      {
        header: productText.field.code,
        accessorKey: "productCode",
        cell: ({ row }) => row.original.component?.code || "-",
      },
      {
        header: productText.field.name,
        accessorKey: "productName",
        cell: ({ row }) => row.original.component?.nameVn || "-",
      },
      {
        header: productText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: productText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
    ],
    [productText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <DataTable<BomTreeItem>
        columns={columns}
        data={productBom?.tree ? [productBom.tree] : []}
        total={1}
        loading={isLoadingProductBom}
        isHiddenPagination
        isTreeTable
        getSubRows={(row) => row.children}
      />
    </div>
  );
};

export default DetailProductBom;
