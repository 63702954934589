import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { JobTitle } from "./types";

export const DELETE_JOB_TITLE_URL = (id: ID) => `/api/admin/job-titles/${id}`;

export enum DeleteJobTitleError {}

export const deletedJobTitleErrorMessages = {};

export type DeleteJobTitleResponse = Response<JobTitle>;

export type DeleteJobTitleArgs = {
  id: ID;
};

export const deleteJobTitle = ({ id }: DeleteJobTitleArgs) => {
  return httpClient.delete<DeleteJobTitleResponse>(DELETE_JOB_TITLE_URL(id));
};

export const useDeleteJobTitleMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteJobTitleResponse>,
    AxiosError<Response<null>>,
    DeleteJobTitleArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_JOB_TITLE_URL],
    mutationFn: (deletedJobTitleArgs: DeleteJobTitleArgs) =>
      deleteJobTitle(deletedJobTitleArgs),
    ...opts,
  });
};
