import {
  ProductionPlanItemStatus,
  ProductionPlanStatus,
} from "app/services/productionPlan";

export const productionPlanStatusMappers: Record<ProductionPlanStatus, string> =
  {
    [ProductionPlanStatus.WaitForApprove]: "Chờ phê duyệt",
    [ProductionPlanStatus.Todo]: "Chờ thực hiện",
  };

export const productionPlanItemStatusMappers: Record<
  ProductionPlanItemStatus,
  string
> = {
  [ProductionPlanItemStatus.WaitForApprove]: "Chờ phê duyệt",
  [ProductionPlanItemStatus.Todo]: "Chờ thực hiện",
};

export const productionPlanStatusOptions = Object.keys(
  ProductionPlanStatus,
).map((key) => ({
  value: key as ProductionPlanStatus,
  label: productionPlanStatusMappers[key as ProductionPlanStatus],
}));
