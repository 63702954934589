import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductionOrder } from "./types";

export const DELETE_PRODUCTION_ORDER_URL = (id: ID) =>
  `/api/admin/production-orders/${id}`;

export enum DeleteProductionOrderError {}

export const deletedProductionOrderErrorMessages = {};

export type DeleteProductionOrderResponse = Response<ProductionOrder>;

export type DeleteProductionOrderArgs = {
  id: ID;
};

export const deleteProductionOrder = ({ id }: DeleteProductionOrderArgs) => {
  return httpClient.delete<DeleteProductionOrderResponse>(
    DELETE_PRODUCTION_ORDER_URL(id),
  );
};

export const useDeleteProductionOrderMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteProductionOrderResponse>,
    AxiosError<Response<null>>,
    DeleteProductionOrderArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_PRODUCTION_ORDER_URL],
    mutationFn: (deletedProductionOrderArgs: DeleteProductionOrderArgs) =>
      deleteProductionOrder(deletedProductionOrderArgs),
    ...opts,
  });
};
