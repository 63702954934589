import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  CustomerAction,
  CustomerActionDropdown,
} from "app/modules/customer/components";
import {
  useDeleteCustomer,
  useRetrieveCustomers,
} from "app/modules/customer/hooks";
import { Customer } from "app/services/customer";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListCustomerProps {}

const ListCustomer: FC<ListCustomerProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { customerText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { customers, isLoadingCustomers } = useRetrieveCustomers({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteCustomer } = useDeleteCustomer();

  const handleActionClick = (action: CustomerAction, data?: Customer) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/customer/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/customer/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(customerText.title),
            description: confirmText.deleteContent(customerText.title),
            onConfirm: () => deleteCustomer({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Customer>[] = useMemo(
    () => [
      {
        header: customerText.field.customerName,
        accessorKey: "customerName",
        size: 200,
      },
      {
        header: customerText.field.customerCode,
        accessorKey: "customerCode",
        cell: ({ row }) => row.original.customerCode || "-",
      },
      {
        header: customerText.field.address,
        accessorKey: "address",
        cell: ({ row }) => row.original.address || "-",
      },
      {
        header: customerText.field.phoneNumber,
        accessorKey: "phoneNumber",
        cell: ({ row }) => row.original.phoneNumber || "-",
      },
      {
        header: customerText.field.email,
        accessorKey: "email",
        cell: ({ row }) => row.original.email || "-",
      },
      {
        header: customerText.field.website,
        accessorKey: "website",
        cell: ({ row }) =>
          row.original.website ? (
            <a href={row.original.website} target="_blank" rel="noreferrer">
              {row.original.website}
            </a>
          ) : (
            "-"
          ),
      },
      {
        header: customerText.field.customerType,
        accessorKey: "customerType",
        cell: ({ row }) =>
          row.original.customerType ? (
            <Chip
              size="sm"
              color={row.original.customerType?.colorCode}
              label={row.original.customerType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: customerText.field.representative,
        accessorKey: "representative",
        cell: ({ row }) => row.original.representative || "-",
      },
      {
        header: customerText.field.representativeRole,
        accessorKey: "representativeRole",
        size: 200,
        cell: ({ row }) => row.original.representativeRole || "-",
      },
      {
        header: customerText.field.paymentTerms,
        accessorKey: "paymentTerms",
        cell: ({ row }) => row.original.paymentTerms || "-",
      },
      {
        header: customerText.field.depositPercentage,
        accessorKey: "depositPercentage",
        cell: ({ row }) =>
          row.original.depositPercentage
            ? intl.formatNumber(row.original.depositPercentage, {
                style: "unit",
                unit: "percent",
              })
            : "-",
      },
      {
        header: customerText.field.paymentDueDays,
        accessorKey: "paymentDueDays",
        cell: ({ row }) =>
          row.original.paymentDueDays
            ? `${row.original.paymentDueDays} ngày`
            : "-",
      },
      {
        header: customerText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          row.original.startDate
            ? new Date(row.original.startDate).toLocaleDateString()
            : "-",
      },
      {
        header: customerText.field.assignee,
        accessorKey: "assignee",
        cell: ({ row }) => {
          const assignee = row.original.assignee;
          return assignee ? assignee.fullName : "-";
        },
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <CustomerActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [customerText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={customerText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-customer-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/customer/new")}
              >
                {customerText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Customer>
            columns={columns}
            data={customers?.result || []}
            loading={isLoadingCustomers}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={customers?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListCustomer;
