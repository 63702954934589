import { FilterByDate } from "app/models";
import { OptionSelect } from "app/services/types";
import { getDateFilterLabel } from "./getDateFilterLabel";

type ReturnType<T> = OptionSelect & {
  field: keyof T;
};

export const getDateFilterValue = <T>({
  type,
  endDate,
  startDate,
}: {
  type?: FilterByDate;
  startDate?: Date;
  endDate?: Date;
}): ReturnType<T> => {
  let result = {} as ReturnType<T>;
  if (type) {
    if (startDate && endDate) {
      result = {
        value: `${type}: ${startDate.toISOString()} ${endDate.toISOString()}`,
        label: getDateFilterLabel({
          type,
          startDate,
          endDate,
        }),
        field: "rangeDate" as keyof T,
      };
    }
    if (startDate && !endDate) {
      result = {
        value: startDate.toISOString(),
        label: getDateFilterLabel({
          type,
          startDate,
        }),
        field: "startDate" as keyof T,
      };
    }
    if (endDate && !startDate) {
      result = {
        value: endDate.toISOString(),
        label: getDateFilterLabel({
          type,
          endDate,
        }),
        field: "endDate" as keyof T,
      };
    }
    if (!startDate && !endDate) {
      console.error("Error: No Start Date or End Date!");
    }
  } else {
    if (startDate && endDate) {
      result = {
        value: `${startDate.toISOString()} ${endDate.toISOString()}`,
        label: getDateFilterLabel({
          startDate,
          endDate,
        }),
        field: "rangeDate" as keyof T,
      };
    }
    if (startDate && !endDate) {
      result = {
        value: startDate.toISOString(),
        label: getDateFilterLabel({
          startDate,
        }),
        field: "startDate" as keyof T,
      };
    }
    if (endDate && !startDate) {
      result = {
        value: endDate.toISOString(),
        label: getDateFilterLabel({
          endDate,
        }),
        field: "endDate" as keyof T,
      };
    }
  }
  return result;
};
