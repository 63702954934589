import { Source } from "app/models";
import { User } from "app/services/user";
import { MultiValue, SingleValue } from "react-select";
import { sourceMappers, USER_INFO } from "./constants";

export const getExtFile = (name: string) => name.split(".").pop() || "";

export const getFileNameWithoutExt = (name: string) =>
  name.slice(0, name.indexOf(getExtFile(name))).slice(0, -1);

export const getTime = () => new Date().getTime();

export const getUnitKey = () => (Math.random() + 1).toString(36).substring(4);

export const formatFileName = (
  name: string,
  fieldName: string,
  id: number | undefined,
) => {
  const ext = getExtFile(name);
  const hexPath = `${fieldName}/${id}-${getTime()}-${getUnitKey()}.${ext}`;
  return hexPath;
};

export const readAsArrayBuffer = (
  file: File,
): Promise<FileReader["result"]> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      return resolve(fileReader.result);
    };

    fileReader.onerror = () => {
      return reject(new Error("Read file as array buffer error!"));
    };

    fileReader.readAsArrayBuffer(file);
  });
};

export const getUserId = (): number | undefined => {
  try {
    const user: User = localStorage.getItem(USER_INFO)
      ? JSON.parse(localStorage.getItem(USER_INFO) || "")
      : null;

    return Number(user.id);
  } catch (error) {
    return undefined;
  }
};

export const mapSource = (sourceKey: Source) => {
  return sourceMappers[sourceKey] || sourceKey;
};

export const formatDate = (date: string | Date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString("vi-VN");
  }
  return new Date(date).toLocaleDateString("vi-VN");
};

export const formatDateTime = (date: string | Date) => {
  if (date instanceof Date) {
    return date
      .toLocaleString("vi-VN", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: false,
      })
      .replace(" ", " - ");
  }
  return new Date(date)
    .toLocaleString("vi-VN", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour12: false,
    })
    .replace(" ", " - ");
};

export const getMaxDate = (date1: Date | null, date2: Date | null) => {
  if (!date1 && !date2) {
    return null;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  return date1 > date2 ? date1 : date2;
};

export const isSingle = <T>(
  value: SingleValue<T> | MultiValue<T>,
): value is T => {
  return !Array.isArray(value);
};
