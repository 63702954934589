import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Panel } from "./types";

export const DELETE_PANEL_URL = (id: ID) => `/api/admin/panels/${id}`;

export enum DeletePanelError {}

export const deletedPanelErrorMessages = {};

export type DeletePanelResponse = Response<Panel>;

export type DeletePanelArgs = {
  id: ID;
};

export const deletePanel = ({ id }: DeletePanelArgs) => {
  return httpClient.delete<DeletePanelResponse>(DELETE_PANEL_URL(id));
};

export const useDeletePanelMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeletePanelResponse>,
    AxiosError<Response<null>>,
    DeletePanelArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_PANEL_URL],
    mutationFn: (deletedPanelArgs: DeletePanelArgs) =>
      deletePanel(deletedPanelArgs),
    ...opts,
  });
};
