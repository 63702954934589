import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Department } from "./types";

export const UPDATE_DEPARTMENT_URL = (id: ID) => `/api/admin/departments/${id}`;

export enum UpdateDepartmentError {}

export const updateDepartmentErrorMessages = {};

export type UpdateDepartmentResponse = Response<Department>;

export type UpdateDepartmentArgs = {
  id: ID;
};

export const updateDepartment = ({ id, ...args }: UpdateDepartmentArgs) => {
  return httpClient.put<UpdateDepartmentResponse>(
    UPDATE_DEPARTMENT_URL(id),
    args,
  );
};

export const useUpdateDepartmentMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateDepartmentResponse>,
      AxiosError<any>,
      UpdateDepartmentArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateDepartmentResponse>,
    AxiosError<any>,
    UpdateDepartmentArgs
  >({
    mutationFn: updateDepartment,
    mutationKey: [UPDATE_DEPARTMENT_URL],
    ...opts,
  });
