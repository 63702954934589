import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  ProductionOrderForm,
  ProductionOrderFormData,
} from "app/modules/productionOrder/components";
import {
  useRetrieveProductionOrderById,
  useUpdateProductionOrder,
} from "app/modules/productionOrder/hooks";
import {
  mapDefaultProductionOrder,
  mapUpdateProductionOrder,
} from "app/modules/productionOrder/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateProductionOrderProps {}

const UpdateProductionOrder: FC<UpdateProductionOrderProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { productionOrderText } = useAppTranslation();

  const { productionOrder, isLoading } = useRetrieveProductionOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateProductionOrder({
    id,
    onSuccess: () => {
      navigate("/production-orders");
    },
  });

  const handleSubmit = async (data: ProductionOrderFormData) => {
    mutateAsync(mapUpdateProductionOrder({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={productionOrderText.edit}
          pageTitle={productionOrderText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <ProductionOrderForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultProductionOrder(productionOrder)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateProductionOrder;
