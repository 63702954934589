import { RetrieveStepsArgs, useRetrieveStepsQuery } from "app/services/step";

export const useRetrieveSteps = (args: RetrieveStepsArgs) => {
  const result = useRetrieveStepsQuery(args);
  return {
    steps: result.data?.data.data,
    isLoadingSteps: result.isLoading,
    ...result,
  };
};
