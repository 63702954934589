import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { MasterData, MasterDataType } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_MASTER_DATAS_URL = "/api/admin/master-data";

export interface RetrieveMasterDatasArgs {
  page: number;
  limit: number;
  searchString?: string;
  type?: MasterDataType;
}

export type RetrieveMasterDatasResponse = Response<PagingData<MasterData[]>>;

export const retrieveMasterDatas = (args: RetrieveMasterDatasArgs) => {
  return httpClient.get<RetrieveMasterDatasResponse>(
    RETRIEVE_MASTER_DATAS_URL,
    { params: args },
  );
};

export const useRetrieveMasterDatasQuery = (
  retrieveMasterDatasArgs: RetrieveMasterDatasArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveMasterDatasResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveMasterDatasResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_MASTER_DATAS_URL, retrieveMasterDatasArgs],
    queryFn: () => retrieveMasterDatas(retrieveMasterDatasArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveMasterDatasInfiniteQuery = (
  retrieveMasterDatasArgs: RetrieveMasterDatasArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveMasterDatasResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveMasterDatasResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveMasterDatasResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_MASTER_DATAS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveMasterDatasArgs;
      return retrieveMasterDatas({
        ...retrieveMasterDatasArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveMasterDatasArgs;
      let nextPageParam: RetrieveMasterDatasArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveMasterDatasArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
