import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import useAppSearch from "app/hooks/useAppSearch";
import { useAppTranslation } from "app/hooks/useAppTranslation";
import {
  EmployeeAction,
  EmployeeActionDropdown,
} from "app/modules/employee/components";
import { mapGender } from "app/modules/employee/helpers";
import {
  useDeleteEmployee,
  useRetrieveEmployees,
} from "app/modules/employee/hooks";
import { UserFormData, UserFormModal } from "app/modules/user/components";
import { useCreateUser } from "app/modules/user/hooks";
import { mapCreateUser } from "app/modules/user/mapper";
import { Employee } from "app/services/employee";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListEmployeeProps {}

const ListEmployee: FC<ListEmployeeProps> = () => {
  const { confirm } = useConfirm();
  const [isUserModalOpen, toggleUserModal] = useState(false);
  const rowSelected = useRef<Employee | null>(null);
  const navigate = useNavigate();
  const { employeeText, confirmText } = useAppTranslation();

  const handleToggleUserModal = (data?: Employee) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isUserModalOpen) {
      rowSelected.current = null;
    }
    toggleUserModal((prevState) => !prevState);
  };

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: DEFAULT_PAGE,
      initialLimit: DEFAULT_LIMIT_PAGE,
      initialSearchString: "",
    });

  const { employees, isLoadingEmployees } = useRetrieveEmployees({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteEmployee } = useDeleteEmployee();

  const handleActionClick = (action: EmployeeAction, data?: Employee) => {
    switch (action) {
      case "create-user":
        handleToggleUserModal(data);
        break;
      case "edit":
        if (data) {
          navigate(`/employee/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/employee/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(employeeText.title),
            description: confirmText.deleteContent(employeeText.title),
            onConfirm: () => deleteEmployee({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Employee>[] = useMemo(
    () => [
      {
        header: employeeText.field.fullName,
        accessorKey: "fullName",
        size: 200,
        cell: ({ row }) => (
          <AvatarRow
            name={row.original.fullName}
            avatarUrl={row.original.avatar?.attachmentUrl}
          />
        ),
      },
      {
        header: employeeText.field.employeeCode,
        accessorKey: "employeeCode",
        cell: ({ row }) => row.original.employeeCode || "-",
      },
      {
        header: employeeText.field.username,
        accessorKey: "username",
        cell: ({ row }) => row.original?.user?.username || "-",
      },
      {
        header: employeeText.field.email,
        accessorKey: "email",
        cell: ({ row }) => row.original.email || "-",
      },
      {
        header: employeeText.field.phone,
        accessorKey: "phone",
        cell: ({ row }) => row.original.phone || "-",
      },
      {
        header: employeeText.field.jobTitle,
        accessorKey: "jobTitle",
        cell: ({ row }) =>
          row.original.jobTitle?.name ? (
            <Chip
              size="sm"
              color={row.original.jobTitle?.colorCode}
              label={row.original.jobTitle?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: employeeText.field.department,
        accessorKey: "department",
        cell: ({ row }) =>
          row.original.department?.name ? (
            <Chip
              size="sm"
              color={row.original.department?.colorCode}
              label={row.original.department?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: employeeText.field.gender,
        accessorKey: "gender",
        cell: ({ row }) =>
          row.original.gender ? mapGender(row.original.gender) : "-",
      },
      {
        header: employeeText.field.dateOfBirth,
        accessorKey: "birthdate",
        cell: ({ row }) =>
          row.original.birthdate
            ? new Date(row.original.birthdate).toLocaleDateString("vi-VN")
            : "-",
      },
      {
        header: employeeText.field.skill,
        accessorKey: "skill",
        cell: ({ row }) => (
          <div className="d-flex gap-1 flex-wrap">
            {row.original.employeeSkills?.map((skill) => (
              <Chip
                size="sm"
                key={skill.id}
                color="primary"
                label={skill.skill.name}
              />
            )) || "-"}
          </div>
        ),
      },
      {
        header: employeeText.field.taxCode,
        accessorKey: "taxCode",
        cell: ({ row }) => row.original.taxCode || "-",
      },
      {
        header: employeeText.field.nationalId,
        accessorKey: "nationalId",
        cell: ({ row }) => row.original.nationalId || "-",
      },
      {
        header: employeeText.field.dateOfIssue,
        accessorKey: "dateOfIssue",
        cell: ({ row }) =>
          row.original.dateOfIssue
            ? new Date(row.original.dateOfIssue).toLocaleDateString("vi-VN")
            : "-",
      },
      {
        header: employeeText.field.placeOfIssue,
        accessorKey: "placeOfIssue",
        cell: ({ row }) => row.original.placeOfIssue || "-",
      },
      {
        header: employeeText.field.permanentAddress,
        accessorKey: "permanentAddress",
        cell: ({ row }) => row.original.permanentAddress || "-",
      },
      {
        header: employeeText.field.dateOfJoining,
        accessorKey: "dateOfJoining",
        cell: ({ row }) =>
          row.original.dateOfJoining
            ? new Date(row.original.dateOfJoining).toLocaleDateString("vi-VN")
            : "-",
      },
      {
        header: employeeText.field.dateOfLeaving,
        accessorKey: "dateOfLeaving",
        cell: ({ row }) =>
          row.original.dateOfLeaving
            ? new Date(row.original.dateOfLeaving).toLocaleDateString("vi-VN")
            : "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <EmployeeActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [employeeText],
  );

  const { mutateAsync: createUser, isPending: isCreating } = useCreateUser({
    onSuccess: () => {
      handleToggleUserModal();
    },
  });

  const handleCreateUser = (data: UserFormData) => {
    if (rowSelected.current) {
      createUser(
        mapCreateUser({ employeeId: rowSelected.current.id, ...data }),
      );
    }
  };

  const handleSubmitUser = (data: UserFormData) => {
    handleCreateUser(data);
  };

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={employeeText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <DebouncedInput
                  name="list-employee-search"
                  value={searchString || ""}
                  onChange={(value) => {
                    setSearchString(String(value));
                  }}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/employee/new")}
              >
                {employeeText.add}
              </Button>
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<Employee>
            columns={columns}
            data={employees?.result || []}
            loading={isLoadingEmployees}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={employees?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>

      <UserFormModal
        open={isUserModalOpen}
        onToggle={handleToggleUserModal}
        onSubmit={handleSubmitUser}
        submitting={isCreating}
      />
    </React.Fragment>
  );
};

export default ListEmployee;
