import { AvatarRow } from "app/components/molecules/AvatarRow";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Ticket } from "app/services/ticket";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailTicketGeneralProps {}

const DetailTicketGeneral: FC<DetailTicketGeneralProps> = () => {
  const { ticketText, productText } = useAppTranslation();
  const { data: ticket } = useDetailPageContext<Ticket>();
  return (
    <div className="p-3 pb-0">
      <div className="flex-row">
        <div className="flex-column-2">
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {ticketText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.component}
                    value={ticket?.product.nameVn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.productStep}
                    value={ticket?.productStep?.step?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.productionPlan}
                    value={ticket?.productionOrderItem.planItem.plan.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.productionOrder}
                    value={ticket?.productionOrderItem.productionOrder.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.workshop}
                    value={ticket?.workshop.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.quantity}
                    value={ticket?.quantity}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={ticketText.field.note}
                    value={ticket?.note}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="flex-column-2">
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {ticketText.detail.productInfomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.avatar}
                    value={
                      ticket?.productStep.product.productImage ? (
                        <AvatarRow
                          avatarUrl={
                            ticket.productStep.product.productImage
                              ?.attachmentUrl
                          }
                          isClickable
                        />
                      ) : null
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.name}
                    value={ticket?.productStep.product.nameVn}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.lengthCm}
                    value={ticket?.productStep.product?.lengthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.widthCm}
                    value={ticket?.productStep.product?.widthCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.heightCm}
                    value={ticket?.productStep.product?.heightCm}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.weightKg}
                    value={ticket?.productStep.product?.weightKg}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productText.field.color}
                    value={ticket?.productStep.product?.color}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DetailTicketGeneral;
