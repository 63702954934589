import {
  createContext,
  FC,
  PropsWithChildren,
  Suspense,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { LoadingScreen } from "app/components/organisms/LoadingScreen";
import { useRetrievePanelEmployeeById } from "app/modules/employee/hooks";
import { Employee } from "app/services/employee";

const PanelEmployeeContext = createContext<{ employee: Employee | null }>({
  employee: null,
});

const PanelEmployeeProtected: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const employeeLS = localStorage.getItem("ks-employee-info");

  useEffect(() => {
    if (!employeeLS) {
      navigate("/panel/scan");
    }
  }, [employeeLS]);

  const { employee, isLoadingPanelEmployee } = useRetrievePanelEmployeeById(
    {
      id: (JSON.parse(employeeLS as string) as Employee)?.id,
    },
    { enabled: employeeLS !== null },
  );

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "ks-employee-info") {
        navigate("/panel/scan");
      }
    });
  }, []);

  if (isLoadingPanelEmployee || !employee) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <PanelEmployeeContext.Provider value={{ employee }}>
        {children}
      </PanelEmployeeContext.Provider>
    </Suspense>
  );
};

const usePanelEmployee = () => useContext(PanelEmployeeContext);

export default PanelEmployeeProtected;

export { usePanelEmployee };
