import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { AvatarUpload } from "app/components/organisms/AvatarUpload";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { FilePreview } from "app/models";
import { EmployeeSelect } from "app/modules/employee/components";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

export interface CustomerFormProps {
  defaultValues?: CustomerFormData;
  onSubmit: (data: CustomerFormData) => void;
  submitting?: boolean;
}

export interface CustomerFormData {
  customerCode: string;
  customerName: string;
  customerType: OptionSelect<ID> | null;
  address: string;
  phoneNumber: string;
  email: string;
  website: string;
  representative: string;
  representativeRole: string;
  paymentTerms: string;
  depositPercentage: number | null;
  paymentDueDays: number | null;
  notes: string;
  startDate: Date | null;
  customerStatus: OptionSelect<ID> | null;
  assignee: OptionSelect<ID> | null;
  avatar: FilePreview[];
}

export const CustomerForm: FC<CustomerFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { customerText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<CustomerFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        customerName: yupSchema.stringRequired(
          "customer.error.customerNameRequired",
        ),
        customerCode: yupSchema.stringNotRequired(),
        customerType: yupSchema.objectNotRequired(),
        address: yupSchema.stringNotRequired(),
        phoneNumber: yupSchema.stringNotRequired(),
        email: yupSchema.emailNotRequired("customer.error.emailInvalid"),
        website: yupSchema.websiteNotRequired("customer.error.websiteInvalid"),
        representative: yupSchema.stringNotRequired(),
        representativeRole: yupSchema.stringNotRequired(),
        paymentTerms: yupSchema.stringNotRequired(),
        depositPercentage: yupSchema.numberMoreThanOrEqualNotRequired(
          0,
          "customer.error.depositPercentageInvalid",
        ),
        paymentDueDays: yupSchema.numberMoreThanOrEqualNotRequired(
          0,
          "customer.error.paymentDueDaysInvalid",
        ),
        notes: yupSchema.stringNotRequired(),
        startDate: yupSchema.dateNotRequired(),
        customerStatus: yupSchema.objectNotRequired(),
        assignee: yupSchema.objectNotRequired(),
        avatar: yupSchema.fileNotRequired(),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue =
    (name: keyof CustomerFormData) => (data: MasterData) => {
      setValue(
        name,
        {
          value: data.id,
          label: data.name,
        },
        { shouldDirty: true },
      );
    };

  return (
    <Form onSubmit={submit} id="customer-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="avatar" className="form-label">
              {customerText.field.avatar}
            </FormLabel>
            <Controller
              name="avatar"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <AvatarUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="customerName" className="form-label">
              {customerText.field.customerName}
            </FormLabel>
            <Controller
              name="customerName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="customerName"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="customerCode" className="form-label">
              {customerText.field.customerCode}
            </Label>
            <Controller
              name="customerCode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="customerCode"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="customerType" className="form-label">
              {customerText.field.customerType}
            </Label>
            <Controller
              name="customerType"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="customerType"
                    name="customerType"
                    inputId="customerType"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.CustomerType }}
                    onCreateSuccess={handleSetValue("customerType")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="address" className="form-label">
              {customerText.field.address}
            </Label>
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="address"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="phoneNumber" className="form-label">
              {customerText.field.phoneNumber}
            </Label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="email" className="form-label">
              {customerText.field.email}
            </Label>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="email"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="website" className="form-label">
              {customerText.field.website}
            </Label>
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="url"
                    className="form-control"
                    id="website"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="representative" className="form-label">
              {customerText.field.representative}
            </Label>
            <Controller
              name="representative"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="representative"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="representativeRole" className="form-label">
              {customerText.field.representativeRole}
            </Label>
            <Controller
              name="representativeRole"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="representativeRole"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="paymentTerms" className="form-label">
              {customerText.field.paymentTerms}
            </Label>
            <Controller
              name="paymentTerms"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="paymentTerms"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="depositPercentage" className="form-label">
              {customerText.field.depositPercentage}
            </Label>
            <Controller
              name="depositPercentage"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value || ""}
                    onChange={(value) => {
                      field.onChange(Number(value.target.value));
                    }}
                    type="number"
                    className="form-control"
                    id="depositPercentage"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="paymentDueDays" className="form-label">
              {customerText.field.paymentDueDays}
            </Label>
            <Controller
              name="paymentDueDays"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value || ""}
                    onChange={(value) => {
                      field.onChange(Number(value.target.value));
                    }}
                    type="number"
                    className="form-control"
                    id="paymentDueDays"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="startDate" className="form-label">
              {customerText.field.startDate}
            </Label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="startDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="customerStatus" className="form-label">
              {customerText.field.customerStatus}
            </Label>
            <Controller
              name="customerStatus"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="customerStatus"
                    name="customerStatus"
                    inputId="customerStatus"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.CustomerStatus }}
                    onCreateSuccess={handleSetValue("customerStatus")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="assignee" className="form-label">
              {customerText.field.assignee}
            </Label>
            <Controller
              name="assignee"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <EmployeeSelect
                    name={field.name}
                    value={field.value}
                    inputId="assignee"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormFeedback type="invalid" className="d-block">
                    {fieldState.error?.message}
                  </FormFeedback>
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="notes" className="form-label">
              {customerText.field.notes}
            </Label>
            <Controller
              name="notes"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="notes"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
