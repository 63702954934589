import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Employee } from "./types";

export const DELETE_EMPLOYEE_URL = (id: ID) => `/api/admin/employees/${id}`;

export enum DeleteEmployeeError {}

export const deletedEmployeeErrorMessages = {};

export type DeleteEmployeeResponse = Response<Employee>;

export type DeleteEmployeeArgs = {
  id: ID;
};

export const deleteEmployee = ({ id }: DeleteEmployeeArgs) => {
  return httpClient.delete<DeleteEmployeeResponse>(DELETE_EMPLOYEE_URL(id));
};

export const useDeleteEmployeeMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteEmployeeResponse>,
    AxiosError<Response<null>>,
    DeleteEmployeeArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_EMPLOYEE_URL],
    mutationFn: (deletedEmployeeArgs: DeleteEmployeeArgs) =>
      deleteEmployee(deletedEmployeeArgs),
    ...opts,
  });
};
