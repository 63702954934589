import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { StepForm, StepFormData } from "app/modules/step/components";
import { useCreateStep } from "app/modules/step/hooks";
import { mapCreateStep, mapDefaultStep } from "app/modules/step/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateStepProps {}

const CreateStep: FC<CreateStepProps> = () => {
  const { stepText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateStep({
    onSuccess: () => {
      navigate("/steps");
    },
  });

  const handleSubmit = async (data: StepFormData) => {
    mutateAsync(mapCreateStep(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb title={stepText.add} pageTitle={stepText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <StepForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultStep()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateStep;
