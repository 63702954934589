import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Ticket, TicketStatus } from "./types";

export const RETRIEVE_TICKETS_URL = "/api/admin/tickets";

export interface RetrieveTicketsArgs {
  page: number;
  limit: number;
  searchString?: string;
  status?: TicketStatus[];
  productStepIds?: ID[];
  assigneeIds?: ID[];
  workshopIds?: ID[];
}

export type RetrieveTicketsResponse = Response<PagingData<Ticket[]>>;

export const retrieveTickets = (args: RetrieveTicketsArgs) => {
  return httpClient.get<RetrieveTicketsResponse>(RETRIEVE_TICKETS_URL, {
    params: args,
  });
};

export const useRetrieveTicketsQuery = (
  retrieveTicketsArgs: RetrieveTicketsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveTicketsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveTicketsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_TICKETS_URL, retrieveTicketsArgs],
    queryFn: () => retrieveTickets(retrieveTicketsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
