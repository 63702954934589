import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveEmployeeById } from "app/modules/employee/hooks";
import { Employee } from "app/services/employee";
import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { DetailEmployeeHeader } from "./DetailEmployeeHeader";
import { DetailEmployeeTabs } from "./DetailEmployeeTabs";

const DetailEmployee = () => {
  const { id } = useParams();
  const { employeeText } = useAppTranslation();

  const { employee, isLoadingEmployee } = useRetrieveEmployeeById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <React.Fragment>
      <DetailPage<Employee>
        data={employee as Employee}
        loading={isLoadingEmployee}
      >
        <DetailPage.BreadCrumb>
          <BreadCrumb
            title={employeeText.detail.title}
            pageTitle={employeeText.title}
            isBack
          />
        </DetailPage.BreadCrumb>
        <DetailPage.Header>
          <DetailEmployeeHeader />
        </DetailPage.Header>
        <DetailPage.Main
          tabs={<DetailEmployeeTabs />}
          // attachmentBar={
          //   <AttachmentBar
          //     imageProps={{ data: [] }}
          //     documentProps={{ data: [] }}
          //   />
          // }
        >
          <Outlet />
        </DetailPage.Main>
      </DetailPage>
    </React.Fragment>
  );
};

export default DetailEmployee;
