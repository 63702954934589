import { WithID } from "app/models";
import { CommonProperties } from "app/services/types";
import {
  CreateWorkshopArgs,
  UpdateWorkshopArgs,
  Workshop,
} from "app/services/workshop";
import { WorkshopFormData } from "./components";

export const mapper = ({
  name,
  address,
  description,
}: WorkshopFormData): CommonProperties<
  CreateWorkshopArgs,
  UpdateWorkshopArgs
> => {
  return {
    name,
    address: address || null,
    description: description || null,
  };
};

export const mapCreateWorkshop = (
  data: WorkshopFormData,
): CreateWorkshopArgs => {
  return mapper(data);
};

export const mapUpdateWorkshop = ({
  id,
  ...data
}: WithID<WorkshopFormData>): UpdateWorkshopArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultWorkshop = ({
  address,
  description,
  name,
}: Partial<Workshop> = {}): WorkshopFormData => {
  return {
    name: name || "",
    address: address || "",
    description: description || "",
  };
};
