import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AUTH_REFRESH_TOKEN } from "app/helpers";
import axios from "axios";
import { APIError, APISuccess } from "../types";

export const REFRESH_TOKEN_URL = "/api/admin/auth/refresh";

export type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export enum RefreshTokenError {
  InvalidToken = "Invalid Token",
  TokenExpired = "Token Expired",
}

export const refreshTokenErrorMessages = {
  [RefreshTokenError.InvalidToken]: "Token không hợp lệ",
  [RefreshTokenError.TokenExpired]: "Token đã hết hạn",
};

export const refreshToken = () => {
  const token = localStorage.getItem(AUTH_REFRESH_TOKEN);
  return axios.post<RefreshTokenResponse>(
    REFRESH_TOKEN_URL,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
      baseURL: import.meta.env.VITE_API_URL,
    },
  );
};

export const useRefreshTokenMutation = (
  opts?: UseMutationOptions<
    APISuccess<RefreshTokenResponse>,
    APIError<RefreshTokenError>
  >,
) =>
  useMutation({
    mutationFn: refreshToken,
    mutationKey: [REFRESH_TOKEN_URL],
    ...opts,
  });
