import classNames from "classnames";
import { FC, ReactNode, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Button } from "../Button";
import {
  ConfirmationContext,
  ConfirmationOptions,
} from "./ConfirmationContext";

const confirmationDefault: ConfirmationOptions = {
  severity: "error",
  title: "Xác nhận",
  description: "Bạn chắc chắc muốn thực hành động này?",
  confirmText: "Xác nhận",
  cancelText: "Huỷ",
  onConfirm: async () => null,
};

export interface ConfirmationProviderProps {
  children: ReactNode;
}

export const ConfirmationProvider: FC<ConfirmationProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [confirmationOptions, setConfirmationOptions] =
    useState<ConfirmationOptions>(confirmationDefault);

  const handleOpen = () => {
    confirmationOptions?.onOpen?.();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setConfirmationOptions(confirmationDefault);
    }, 500);
  };

  const confirm = async (options?: ConfirmationOptions) => {
    setConfirmationOptions((prevState) => ({
      ...prevState,
      ...options,
    }));
    options?.onOpen?.();
    handleOpen();
  };

  const handleConfirm = async () => {
    try {
      setSubmitting(true);
      await confirmationOptions.onConfirm?.();
      setSubmitting(false);
      handleClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  const textColor =
    confirmationOptions.severity === "error"
      ? "danger"
      : confirmationOptions.severity;

  const icon =
    confirmationOptions.icon === undefined
      ? "ri-delete-bin-line"
      : confirmationOptions.icon;

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {children}
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered={true}
        modalTransition={{ timeout: 50 }}
        backdropTransition={{ timeout: 50 }}
      >
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            {icon !== null && (
              <i
                className={classNames("display-5", `text-${textColor}`, icon)}
              ></i>
            )}
            <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-3">
              <h4>{confirmationOptions.title}</h4>
              <p className="text-muted mx-4 mb-0">
                {confirmationOptions.description}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              {confirmationOptions.cancelText}
            </button>
            <Button
              type="button"
              className={classNames("btn w-sm", `btn-${textColor}`)}
              id="delete-record"
              onClick={handleConfirm}
              loading={submitting}
            >
              {confirmationOptions.confirmText}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </ConfirmationContext.Provider>
  );
};
