import {
  RetrieveStepByIdArgs,
  useRetrieveStepByIdQuery,
} from "app/services/step";

export const useRetrieveStepById = (
  args: RetrieveStepByIdArgs,
  opts?: Parameters<typeof useRetrieveStepByIdQuery>[1],
) => {
  const result = useRetrieveStepByIdQuery(args, opts as any);
  return {
    step: result.data?.data.data,
    isLoadingStep: result.isLoading,
    ...result,
  };
};
