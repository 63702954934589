import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Vendor } from "./types";

export const DELETE_VENDOR_URL = (id: ID) => `/api/admin/vendors/${id}`;

export enum DeleteVendorError {}

export const deletedVendorErrorMessages = {};

export type DeleteVendorResponse = Response<Vendor>;

export type DeleteVendorArgs = {
  id: ID;
};

export const deleteVendor = ({ id }: DeleteVendorArgs) => {
  return httpClient.delete<DeleteVendorResponse>(DELETE_VENDOR_URL(id));
};

export const useDeleteVendorMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteVendorResponse>,
    AxiosError<Response<null>>,
    DeleteVendorArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_VENDOR_URL],
    mutationFn: (deletedVendorArgs: DeleteVendorArgs) =>
      deleteVendor(deletedVendorArgs),
    ...opts,
  });
};
