import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { ProductionPlan } from "./types";
import { Response } from "../types";

export const RETRIEVE_PRODUCTION_PLAN_BY_ID_URL = (id: ID) =>
  `/api/admin/production-plans/${id}`;

export type RetrieveProductionPlanByIdResponse = Response<ProductionPlan>;

export type RetrieveProductionPlanByIdArgs = {
  id: ID;
};

export const retrieveProductionPlanById = ({
  id,
}: RetrieveProductionPlanByIdArgs) => {
  return httpClient.get<RetrieveProductionPlanByIdResponse>(
    RETRIEVE_PRODUCTION_PLAN_BY_ID_URL(id),
  );
};

export const useRetrieveProductionPlanByIdQuery = (
  retrieveProductionPlansArgs: RetrieveProductionPlanByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductionPlanByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductionPlanByIdResponse>, AxiosError<any>>({
    queryKey: [
      RETRIEVE_PRODUCTION_PLAN_BY_ID_URL(retrieveProductionPlansArgs.id),
    ],
    queryFn: () => retrieveProductionPlanById(retrieveProductionPlansArgs),
    ...opts,
  });
