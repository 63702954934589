import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductionPlan } from "./types";

export const UPDATE_PRODUCTION_PLAN_URL = (id: ID) =>
  `/api/admin/production-plans/${id}`;

export enum UpdateProductionPlanError {}

export const updateProductionPlanErrorMessages = {};

export type UpdateProductionPlanResponse = Response<ProductionPlan>;

export type UpdateProductionPlanArgs = {
  id: ID;
  name: string;
  description: string;
  startDate: DateTimeString;
  endDate: DateTimeString;
  items: {
    orderItemId: ID;
    quantity: number;
    id?: ID;
  }[];
  // status: ProductionPlanStatus;
};

export const updateProductionPlan = ({
  id,
  ...args
}: UpdateProductionPlanArgs) => {
  return httpClient.put<UpdateProductionPlanResponse>(
    UPDATE_PRODUCTION_PLAN_URL(id),
    args,
  );
};

export const useUpdateProductionPlanMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductionPlanResponse>,
      AxiosError<any>,
      UpdateProductionPlanArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateProductionPlanResponse>,
    AxiosError<any>,
    UpdateProductionPlanArgs
  >({
    mutationFn: updateProductionPlan,
    mutationKey: [UPDATE_PRODUCTION_PLAN_URL],
    ...opts,
  });
