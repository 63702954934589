import { useAppTranslation } from "app/hooks";
import { FC, PropsWithChildren } from "react";
import {
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { Button } from "../Button";

export interface FilterOffCanvasProps extends PropsWithChildren {
  show: boolean;
  onToggle: () => void;
  handleClearFilter: () => void;
  total: number;
  formId?: string;
}

export const FilterOffCanvas: FC<FilterOffCanvasProps> = ({
  onToggle,
  show,
  children,
  handleClearFilter,
  total,
  formId,
}) => {
  const { filterText } = useAppTranslation();
  return (
    <>
      <Button
        color={"default"}
        onClick={onToggle}
        className="btn btn-sm btn-icon border"
      >
        <i className="fs-20 ri-filter-2-fill" />
      </Button>
      <Offcanvas
        direction="end"
        toggle={onToggle}
        isOpen={show}
        offcanvasTransition={{ timeout: 100 }}
        backdropTransition={{ timeout: 0 }}
      >
        <OffcanvasHeader toggle={onToggle}>{filterText.title}</OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column h-100">
            {children}
            <div>
              <Row>
                <Col xs={6}>
                  <Button outline color="primary" onClick={handleClearFilter}>
                    {filterText.button.clear}
                  </Button>
                </Col>
                <Col xs={6} className="text-end">
                  <Button form={formId} type="submit" color="primary">
                    ({total})&nbsp;{filterText.button.filter}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};
