import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { ProductionOrder } from "./types";
import { Response } from "../types";

export const RETRIEVE_PRODUCTION_ORDER_BY_ID_URL = (id: ID) =>
  `/api/admin/production-orders/${id}`;

export type RetrieveProductionOrderByIdResponse = Response<ProductionOrder>;

export type RetrieveProductionOrderByIdArgs = {
  id: ID;
};

export const retrieveProductionOrderById = ({
  id,
}: RetrieveProductionOrderByIdArgs) => {
  return httpClient.get<RetrieveProductionOrderByIdResponse>(
    RETRIEVE_PRODUCTION_ORDER_BY_ID_URL(id),
  );
};

export const useRetrieveProductionOrderByIdQuery = (
  retrieveProductionOrdersArgs: RetrieveProductionOrderByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductionOrderByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductionOrderByIdResponse>, AxiosError<any>>(
    {
      queryKey: [
        RETRIEVE_PRODUCTION_ORDER_BY_ID_URL(retrieveProductionOrdersArgs.id),
      ],
      queryFn: () => retrieveProductionOrderById(retrieveProductionOrdersArgs),
      ...opts,
    },
  );
