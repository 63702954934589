import { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "app/components/organisms/AuthProvider";
import { LoadingScreen } from "app/components/organisms/LoadingScreen";
import { AccessTypeUser } from "app/services/user";

const AuthProtected = (props: any) => {
  const navigate = useNavigate();
  const { authenticated, fetchingCurrentUser, currentUser } = useAuth();

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    } else {
      if (currentUser?.accessType === AccessTypeUser.Panel) {
        navigate("/panel");
      } else {
        localStorage.removeItem("accessType");
      }
    }
  }, [authenticated]);

  if (fetchingCurrentUser || !currentUser) {
    return <LoadingScreen />;
  }

  return <Suspense fallback={<LoadingScreen />}>{props.children}</Suspense>;
};

export default AuthProtected;
