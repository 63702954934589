import {
  RetrieveProductionOrderByIdArgs,
  useRetrieveProductionOrderByIdQuery,
} from "app/services/productionOrder";

export const useRetrieveProductionOrderById = (
  args: RetrieveProductionOrderByIdArgs,
  opts?: Parameters<typeof useRetrieveProductionOrderByIdQuery>[1],
) => {
  const result = useRetrieveProductionOrderByIdQuery(args, opts as any);
  return {
    productionOrder: result.data?.data.data,
    isLoadingProductionOrder: result.isLoading,
    ...result,
  };
};
