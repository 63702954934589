import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { OrderItem } from "../order";
import { PagingData, Response } from "../types";

export const RETRIEVE_PRODUCT_ORDER_BY_ID_URL = (id: ID) =>
  `/api/admin/products/${id}/order`;

export type RetrieveProductOrderByIdResponse = Response<
  PagingData<OrderItem[]>
>;

export type RetrieveProductOrderByIdArgs = {
  id: ID;
  page: number;
  limit: number;
  searchString?: string;
};

export const retrieveProductOrderById = ({
  id,
  ...args
}: RetrieveProductOrderByIdArgs) => {
  return httpClient.get<RetrieveProductOrderByIdResponse>(
    RETRIEVE_PRODUCT_ORDER_BY_ID_URL(id),
    { params: args },
  );
};

export const useRetrieveProductOrderByIdQuery = (
  retrieveProductOrdersArgs: RetrieveProductOrderByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductOrderByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductOrderByIdResponse>, AxiosError<any>>({
    queryKey: [
      RETRIEVE_PRODUCT_ORDER_BY_ID_URL(retrieveProductOrdersArgs.id),
      retrieveProductOrdersArgs,
    ],
    queryFn: () => retrieveProductOrderById(retrieveProductOrdersArgs),
    ...opts,
  });
