import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Step } from "./types";
import { Response } from "../types";

export const RETRIEVE_STEP_BY_ID_URL = (id: ID) => `/api/admin/steps/${id}`;

export type RetrieveStepByIdResponse = Response<Step>;

export type RetrieveStepByIdArgs = {
  id: ID;
};

export const retrieveStepById = ({ id }: RetrieveStepByIdArgs) => {
  return httpClient.get<RetrieveStepByIdResponse>(RETRIEVE_STEP_BY_ID_URL(id));
};

export const useRetrieveStepByIdQuery = (
  retrieveStepsArgs: RetrieveStepByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveStepByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveStepByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_STEP_BY_ID_URL(retrieveStepsArgs.id)],
    queryFn: () => retrieveStepById(retrieveStepsArgs),
    ...opts,
  });
