import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { PagingData, Response } from "../types";
import { Panel } from "./types";

export const RETRIEVE_PANEL_URL = "/api/admin/panels";

export interface RetrievePanelsArgs {
  page: number;
  limit: number;
  searchString?: string;
  workshopId?: ID;
}

export type RetrievePanelsResponse = Response<PagingData<Panel[]>>;

export const retrievePanelss = (args: RetrievePanelsArgs) => {
  return httpClient.get<RetrievePanelsResponse>(RETRIEVE_PANEL_URL, {
    params: args,
  });
};

export const useRetrievePanelsQuery = (
  retrievePanelsArgs: RetrievePanelsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrievePanelsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePanelsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PANEL_URL, retrievePanelsArgs],
    queryFn: () => retrievePanelss(retrievePanelsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
