import {
  RetrievePanelByIdArgs,
  useRetrievePanelByIdQuery,
} from "app/services/panel";

export const useRetrievePanelById = (
  args: RetrievePanelByIdArgs,
  opts?: Parameters<typeof useRetrievePanelByIdQuery>[1],
) => {
  const result = useRetrievePanelByIdQuery(args, opts as any);
  return {
    panel: result.data?.data.data,
    isLoadingPanel: result.isLoading,
    ...result,
  };
};
