import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  ProductionOrderAction,
  ProductionOrderActionDropdown,
} from "app/modules/productionOrder/components";
import { productionOrderStatusMappers } from "app/modules/productionOrder/constants";
import {
  useDeleteProductionOrder,
  useRetrieveProductionOrders,
} from "app/modules/productionOrder/hooks";
import {
  ProductionOrder,
  ProductionOrderStatus,
} from "app/services/productionOrder";
import { OptionSelect } from "app/services/types";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListProductionOrderFilter } from "./ListProductionOrderFilter";
import { ListProductionOrderFilterChips } from "./ListProductionOrderFilterChips";

interface ListProductionOrderProps {}

const ListProductionOrder: FC<ListProductionOrderProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { productionOrderText, confirmText } = useAppTranslation();

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{
    status: OptionSelect<ProductionOrderStatus>[];
    startDate: Date | null;
    endDate: Date | null;
    productionPlans: OptionSelect[];
  }>({
    initialPage: 1,
    initialLimit: 20,
    initialSearchString: "",
    initialCustomParams: {
      status: [],
      startDate: null,
      endDate: null,
      productionPlans: [],
    },
  });

  const handleClearFilter = () => {
    setCustomParams({
      status: [],
      startDate: null,
      endDate: null,
      productionPlans: [],
    });
  };

  const { productionOrders, isLoadingProductionOrders } =
    useRetrieveProductionOrders({
      limit,
      page,
      searchString: searchString || undefined,
      status: customParams.status.map((item) => item.value),
      startDate: customParams.startDate?.toISOString(),
      endDate: customParams.endDate?.toISOString(),
      productPlanId: customParams.productionPlans.map((item) => item.value),
    });

  const { mutateAsync: deleteProductionOrder } = useDeleteProductionOrder();

  const handleActionClick = (
    action: ProductionOrderAction,
    data?: ProductionOrder,
  ) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/production-order/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/production-order/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(productionOrderText.title),
            description: confirmText.deleteContent(productionOrderText.title),
            onConfirm: () => deleteProductionOrder({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<ProductionOrder>[] = useMemo(
    () => [
      {
        header: productionOrderText.field.name,
        accessorKey: "name",
      },
      {
        header: productionOrderText.field.status,
        accessorKey: "status",
        cell: ({ row }) => productionOrderStatusMappers[row.original.status],
      },
      {
        header: productionOrderText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          new Date(row.original.startDate).toLocaleDateString(),
      },
      {
        header: productionOrderText.field.endDate,
        accessorKey: "endDate",
        cell: ({ row }) => new Date(row.original.endDate).toLocaleDateString(),
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductionOrderActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [productionOrderText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={productionOrderText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <ListProductionOrderFilter
                searchString={searchString}
                handleSearchChange={setSearchString}
                filterParams={{
                  status: customParams.status,
                  startDate: customParams.startDate,
                  endDate: customParams.endDate,
                  productionPlans: customParams.productionPlans,
                }}
                setCustomParams={setCustomParams}
              />
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/production-order/new")}
              >
                {productionOrderText.add}
              </Button>
            </Col>
            <Col xs={12}>
              <ListProductionOrderFilterChips
                filterParams={{
                  status: customParams.status,
                  startDate: customParams.startDate,
                  endDate: customParams.endDate,
                  productionPlans: customParams.productionPlans,
                }}
                setCustomParams={setCustomParams}
                handleClearFilter={handleClearFilter}
              />
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<ProductionOrder>
            columns={columns}
            data={productionOrders?.result || []}
            loading={isLoadingProductionOrders}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={productionOrders?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListProductionOrder;
