import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { OptionSelect } from "app/services/types";
import React from "react";
import Select, { Props } from "react-select";
import { useRetrieveOrderById } from "../../hooks";

export type OrderItemOptionSelect = OptionSelect<ID> & {
  quantity: number | null;
  planQty: number | null;
};

export interface OrderItemSelectProps
  extends Omit<Props<OrderItemOptionSelect>, "value" | "onChange"> {
  id?: ID;
  value: OrderItemOptionSelect | null;
  onChange: (value: OrderItemOptionSelect | null) => void;
  isInvalid?: boolean;
}

export const OrderItemSelect: React.FC<OrderItemSelectProps> = ({
  value,
  id,
  onChange,
  isInvalid,
  ...props
}) => {
  const { placeHolderText, noDataTableText } = useAppTranslation();
  const { order, isLoadingOrder } = useRetrieveOrderById(
    {
      id: id as string,
    },
    { enabled: !!id },
  );

  const orderItems = order?.orderItems || [];

  return (
    <>
      <Select<OrderItemOptionSelect>
        {...props}
        className={`${props.className} ${isInvalid ? "is-invalid" : ""}`}
        noOptionsMessage={() => noDataTableText}
        value={value}
        isMulti={false}
        isLoading={isLoadingOrder}
        onChange={onChange}
        options={
          orderItems.map((item) => ({
            value: item.id,
            label: `${item.product?.code} - ${item.product?.nameVn} (${formatDate(item.deliveryDate)})`,
            quantity: item.quantity || null,
            planQty: item.planQty || null,
          })) || []
        }
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isClearable
        placeholder={placeHolderText.select}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderColor: isInvalid
              ? "var(--vz-form-invalid-color)"
              : provided.borderColor,
            ":hover": {
              borderColor: isInvalid
                ? "var(--vz-form-invalid-color)"
                : provided.borderColor,
            },
          }),
        }}
      />
    </>
  );
};
