import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  CheckInPanelTicketForm,
  CheckInPanelTicketFormData,
} from "./CheckInPanelTicketForm";

export interface CheckInPanelTicketFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: CheckInPanelTicketFormData) => void;
  submitting?: boolean;
  defaultValues?: CheckInPanelTicketFormData;
  isQuickAdd?: boolean;
}

export const CheckInPanelTicketFormModal: FC<
  CheckInPanelTicketFormModalProps
> = ({ onToggle, open = false, onSubmit, submitting, defaultValues }) => {
  const { ticketText } = useAppTranslation();
  const title = ticketText.checkInTitle;

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <CheckInPanelTicketForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          defaultValues={
            defaultValues || {
              quantity: null,
              note: "",
            }
          }
          submitting={submitting}
        />
      </ModalBody>
    </Modal>
  );
};
