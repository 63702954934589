import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createProductionPlanErrorMessages,
  RETRIEVE_PRODUCTION_PLANS_URL,
  useCreateProductionPlanMutation,
} from "app/services/productionPlan";

interface UseCreateProductionPlanProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateProductionPlan = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateProductionPlanProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateProductionPlanMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_PRODUCTION_PLANS_URL],
      });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createProductionPlanErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
