import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Workshop } from "app/services/workshop";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

export interface DetailWorkshopHeaderProps {}

const DetailWorkshopHeader: FC<DetailWorkshopHeaderProps> = () => {
  const { buttonText } = useAppTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: workshop } = useDetailPageContext<Workshop>();

  const avatar = false;

  return (
    <Row className="g-4">
      <div className="col-auto">
        <div className="avatar-lg d-flex justify-content-center align-items-center">
          {avatar ? (
            <img
              src={avatar}
              alt="user-img"
              className="img-thumbnail rounded-4 w-100 h-100"
            />
          ) : (
            <i className="bx bx-buildings text-body"></i>
          )}
        </div>
      </div>

      <Col>
        <div className="p-2">
          <h3 className="mb-1">{workshop?.name}</h3>
        </div>
      </Col>
      <Col className="col-lg-auto order-last order-lg-0">
        <Row className="text text-center">
          <Col className="py-2">
            <Button
              color="primary"
              onClick={() => navigate(`/workshop/${id}/update`)}
            >
              {buttonText.edit}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DetailWorkshopHeader;
