import { WithID } from "app/models";
import { CommonProperties } from "app/services/types";
import {
  AccessTypeUser,
  CreatePanelUserArgs,
  CreateUserArgs,
  PanelUser,
  UpdatePanelUserArgs,
  UpdateUserArgs,
  User,
} from "app/services/user";
import { UserFormData } from "./components";
import { PanelUserFormData } from "./components/PanelUserFormModal";

export const mapper = ({
  password,
  role,
  username,
  employeeId,
}: UserFormData & { employeeId: ID }): CommonProperties<
  CreateUserArgs,
  UpdateUserArgs
> => {
  return {
    password,
    username,
    accessId: employeeId,
    accessType: AccessTypeUser.Admin,
    roleId: role?.value || "",
  };
};

export const mapCreateUser = (
  data: UserFormData & { employeeId: ID },
): CreateUserArgs => {
  return { status: 1, ...mapper(data) };
};

export const mapUpdateUser = ({
  id,
  ...data
}: WithID<UserFormData & { employeeId: ID }>): UpdateUserArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultUser = ({
  username,
  password,
  role,
}: Partial<User> = {}): UserFormData => {
  return {
    username: username || "",
    password: password || "",
    role: role
      ? {
          value: role.id,
          label: role.name,
        }
      : null,
  };
};

export const panelMapper = ({
  password,
  username,
  workshopId,
}: PanelUserFormData & { workshopId: ID }): CommonProperties<
  CreatePanelUserArgs,
  UpdatePanelUserArgs
> => {
  return {
    password,
    username,
    accessId: workshopId || "",
    accessType: AccessTypeUser.Panel,
  };
};

export const mapCreatePanelUser = (
  data: PanelUserFormData & { workshopId: ID },
): CreatePanelUserArgs => {
  return { status: 1, ...panelMapper(data) };
};

export const mapUpdatePanelUser = ({
  id,
  ...data
}: WithID<PanelUserFormData & { workshopId: ID }>): UpdatePanelUserArgs => {
  return { id, ...panelMapper(data) };
};

export const mapDefaultPanelUser = ({
  username,
  password,
}: Partial<PanelUser> = {}): PanelUserFormData => {
  return {
    username: username || "",
    password: password || "",
  };
};
