import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { AccessTypeUser, PanelUser } from "./types";

export const CREATE_PANEL_USER_URL = "/api/admin/users/panel";

export enum CreatePanelUserError {}

export const createPanelUserErrorMessages = {};

export interface CreatePanelUserArgs {
  username: ID;
  password: string;
  accessId: ID;
  accessType: AccessTypeUser;
  status: number;
}

export type CreatePanelUserResponse = Response<PanelUser>;

export const createPanelUser = (args: CreatePanelUserArgs) => {
  return httpClient.post<CreatePanelUserResponse>(CREATE_PANEL_USER_URL, args);
};

export const useCreatePanelUserMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreatePanelUserResponse>,
      AxiosError<any>,
      CreatePanelUserArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreatePanelUserResponse>,
    AxiosError<any>,
    CreatePanelUserArgs
  >({
    mutationFn: createPanelUser,
    mutationKey: [CREATE_PANEL_USER_URL],
    ...opts,
  });
