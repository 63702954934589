import { PagingData } from "../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { MasterType } from "./types";

export const RETRIEVE_MASTER_TYPES_URL = "/api/admin/master-types";

export interface RetrieveMasterTypesArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveMasterTypesResponse = Response<PagingData<MasterType[]>>;

export const retrieveMasterTypes = (args: RetrieveMasterTypesArgs) => {
  return httpClient.get<RetrieveMasterTypesResponse>(
    RETRIEVE_MASTER_TYPES_URL,
    { params: args },
  );
};

export const useRetrieveMasterTypesQuery = (
  retrieveMasterTypesArgs: RetrieveMasterTypesArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveMasterTypesResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveMasterTypesResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_MASTER_TYPES_URL, retrieveMasterTypesArgs],
    queryFn: () => retrieveMasterTypes(retrieveMasterTypesArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
