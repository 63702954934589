import { WithID } from "app/models";
import {
  CreateProductionOrderArgs,
  ProductionOrder,
  UpdateProductionOrderArgs,
} from "app/services/productionOrder";
import { CommonProperties } from "app/services/types";
import { ProductionOrderFormData } from "./components";

export const mapper = ({
  name,
  endDate,
  items,
  note,
  plan,
  startDate,
}: ProductionOrderFormData): CommonProperties<
  CreateProductionOrderArgs,
  UpdateProductionOrderArgs
> => {
  return {
    name,
    planId: plan?.value as ID,
    startDate: startDate?.toISOString() as string,
    endDate: endDate?.toISOString() as string,
    note: note || null,
    productionOrderItems: items.map((item) => ({
      id: item.id,
      planItemId: item.planItem?.value as ID,
      quantity: item.quantity as number,
      note: item.note || null,
    })),
  };
};

export const mapCreateProductionOrder = (
  data: ProductionOrderFormData,
): CreateProductionOrderArgs => {
  return mapper(data);
};

export const mapUpdateProductionOrder = ({
  id,
  ...data
}: WithID<ProductionOrderFormData>): UpdateProductionOrderArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultProductionOrder = ({
  name,
  startDate,
  endDate,
  note,
  productionPlan,
  productionOrderItems,
}: Partial<ProductionOrder> = {}): ProductionOrderFormData => {
  return {
    name: name || "",
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    note: note || "",
    items:
      productionOrderItems?.map((item) => ({
        quantity: item.quantity,
        planItem: {
          value: item.planItem.id as ID,
          label: `Mục đơn hàng #${item.planItem?.orderItem?.id} -  ${item.planItem?.orderItem?.product.nameVn}`,
          quantity: item.planItem.quantity,
          planQty: item.planItem.productionOrderQty,
        },
        id: item.id,
        defaultQuantity: item.quantity,
        note: item.note || "",
        remainingQuantity:
          item.planItem.quantity - item.planItem.productionOrderQty,
      })) || [],
    plan: productionPlan?.id
      ? {
          value: productionPlan?.id as ID,
          label: productionPlan?.name || "",
        }
      : null,
  };
};
