import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { TicketLog } from "./types";

export const CREATE_TICKET_LOG_URL = "/api/admin/ticket-logs";

export enum CreateTicketLogError {}

export const createTicketLogErrorMessages = {};

export interface CreateTicketLogArgs {
  ticketId: ID;
  employeeId: ID;
  quantity: number;
  note: string | null;
}

export type CreateTicketLogResponse = Response<TicketLog>;

export const createTicketLog = (args: CreateTicketLogArgs) => {
  return httpClient.post<CreateTicketLogResponse>(CREATE_TICKET_LOG_URL, args);
};

export const useCreateTicketLogMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateTicketLogResponse>,
      AxiosError<any>,
      CreateTicketLogArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateTicketLogResponse>,
    AxiosError<any>,
    CreateTicketLogArgs
  >({
    mutationFn: createTicketLog,
    mutationKey: [CREATE_TICKET_LOG_URL],
    ...opts,
  });
