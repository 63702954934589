import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { EmployeeSelect } from "app/modules/employee/components";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface StartTicketFormProps {
  defaultValues?: StartTicketFormData;
  onSubmit: (data: StartTicketFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface StartTicketFormData {
  employee: OptionSelect | null;
}

export const StartTicketForm: FC<StartTicketFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  onToggle,
}) => {
  const { ticketText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<StartTicketFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        employee: yupSchema.objectRequired("ticket.error.employeeRequired"),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="master-data-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="customer" className="form-label">
              {ticketText.field.employee}
            </FormLabel>
            <Controller
              name="employee"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <EmployeeSelect
                    name="employee"
                    value={field.value}
                    inputId="employee"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
