import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { ProductionOrder, ProductionOrderStatus } from "./types";

export const RETRIEVE_PRODUCTION_ORDERS_URL = "/api/admin/production-orders";

export interface RetrieveProductionOrdersArgs {
  page: number;
  limit: number;
  searchString?: string;
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  status?: ProductionOrderStatus[];
  productPlanId?: ID[];
}

export type RetrieveProductionOrdersResponse = Response<
  PagingData<ProductionOrder[]>
>;

export const retrieveProductionOrders = (
  args: RetrieveProductionOrdersArgs,
) => {
  return httpClient.get<RetrieveProductionOrdersResponse>(
    RETRIEVE_PRODUCTION_ORDERS_URL,
    { params: args },
  );
};

export const useRetrieveProductionOrdersQuery = (
  retrieveProductionOrdersArgs: RetrieveProductionOrdersArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductionOrdersResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductionOrdersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCTION_ORDERS_URL, retrieveProductionOrdersArgs],
    queryFn: () => retrieveProductionOrders(retrieveProductionOrdersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
