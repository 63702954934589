import {
  RetrieveTicketsArgs,
  useRetrieveTicketsQuery,
} from "app/services/ticket";

export const useRetrieveTickets = (args: RetrieveTicketsArgs) => {
  const result = useRetrieveTicketsQuery(args);
  return {
    tickets: result.data?.data.data,
    isLoadingTickets: result.isLoading,
    ...result,
  };
};
