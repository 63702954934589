import { AUTH_ACCESS_TOKEN } from "app/helpers";
import axios, { AxiosInstance } from "axios";

const httpClientPanel: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 120000, // Waiting 2m for request timeout
  headers: {
    "Cache-Control": "no-cache",
  },
});

httpClientPanel.interceptors.request.use(function handleRequest(reqConfig) {
  const configOverride = reqConfig;
  const token = localStorage.getItem(AUTH_ACCESS_TOKEN);
  configOverride.headers!.Authorization = token ? `Bearer ${token}` : false;
  return configOverride;
});

export default httpClientPanel;
