import { IBaseModel } from "../types";

export enum MasterDataType {
  VendorStatus = "vendor_status",
  VendorType = "vendor_type",
  Department = "department",
  JobTitle = "job_title",
  CustomerStatus = "customer_status",
  CustomerType = "customer_type",
  ProductType = "product_type",
  ProductGroup = "product_group",
  ProductUnit = "product_unit",
  Skill = "skill",
}

export interface MasterData extends IBaseModel {
  type: MasterDataType;
  masterType: MasterType;
  name: string;
  description: string;
  colorCode: string;
}

export interface MasterType extends IBaseModel {
  key: MasterDataType;
  name: string;
}
