import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  approveProductionPlanErrorMessages,
  RETRIEVE_PRODUCTION_PLAN_BY_ID_URL,
  RETRIEVE_PRODUCTION_PLANS_URL,
  useApproveProductionPlanMutation,
} from "app/services/productionPlan";

interface useApproveProductionPlanProps {
  onSuccess?: () => void;
  onError?: () => void;
  id?: ID; // to invalidate the cache
}

export const useApproveProductionPlan = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
}: useApproveProductionPlanProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useApproveProductionPlanMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({
        queryKey: [RETRIEVE_PRODUCTION_PLANS_URL],
      });
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_PRODUCTION_PLAN_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          approveProductionPlanErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
