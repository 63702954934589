import { WithID } from "app/models";
import { CreatePanelArgs, Panel, UpdatePanelArgs } from "app/services/panel";
import { CommonProperties } from "app/services/types";
import { PanelFormData } from "./components";

export const mapper = ({
  name,
  description,
  workshopId,
}: PanelFormData & { workshopId: ID }): CommonProperties<
  CreatePanelArgs,
  UpdatePanelArgs
> => {
  return {
    name,
    workshopId,
    description: description || null,
  };
};

export const mapCreatePanel = (
  data: PanelFormData & { workshopId: ID },
): CreatePanelArgs => {
  return mapper(data);
};

export const mapUpdatePanel = ({
  id,
  ...data
}: WithID<PanelFormData> & { workshopId: ID }): UpdatePanelArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultPanel = ({
  name,
  description,
}: Partial<Panel> = {}): PanelFormData => {
  return {
    name: name || "",
    description: description || "",
  };
};
