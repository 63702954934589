import {
  RetrievePanelTicketByIdArgs,
  useRetrievePanelTicketByIdQuery,
} from "app/services/ticket";

export const useRetrievePanelTicketById = (
  args: RetrievePanelTicketByIdArgs,
  opts?: Parameters<typeof useRetrievePanelTicketByIdQuery>[1],
) => {
  const result = useRetrievePanelTicketByIdQuery(args, opts as any);
  return {
    ticket: result.data?.data.data,
    isLoadingTicket: result.isLoading,
    ...result,
  };
};
