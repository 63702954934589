import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  startPanelTicketErrorMessages,
  useStartPanelTicketMutation,
} from "app/services/ticket";

interface StartPanelTicketProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useStartPanelTicket = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: StartPanelTicketProps = {}) => {
  const toast = useToastify();
  return useStartPanelTicketMutation({
    onSuccess: () => {
      toast.success();
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          startPanelTicketErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
