import { formatDate } from "app/helpers/utils";
import { WithID } from "app/models";
import {
  CreateProductionPlanArgs,
  ProductionPlan,
  UpdateProductionPlanArgs,
} from "app/services/productionPlan";
import { CommonProperties } from "app/services/types";
import { ProductionPlanFormData } from "./components";

export const mapper = ({
  name,
  description,
  startDate,
  endDate,
  items,
}: ProductionPlanFormData): CommonProperties<
  CreateProductionPlanArgs,
  UpdateProductionPlanArgs
> => {
  return {
    name,
    description: description || "",
    startDate: startDate ? new Date(startDate).toISOString() : "",
    endDate: endDate ? new Date(endDate).toISOString() : "",
    items: items.map((item) => ({
      orderItemId: item.orderItem?.value as string,
      quantity: item.quantity as number,
      id: item.id || undefined,
    })),
  };
};

export const mapCreateProductionPlan = (
  data: ProductionPlanFormData,
): CreateProductionPlanArgs => {
  return mapper(data);
};

export const mapUpdateProductionPlan = ({
  id,
  ...data
}: WithID<ProductionPlanFormData>): UpdateProductionPlanArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultProductionPlan = ({
  name,
  description,
  startDate,
  endDate,
  planItems,
}: Partial<ProductionPlan> = {}): ProductionPlanFormData => {
  return {
    name: name || "",
    description: description || "",
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    items:
      planItems?.map((item) => ({
        id: item.id,
        order: {
          value: item?.orderItem?.order?.id as string,
          label: `${item?.orderItem?.order?.orderCode}`,
        },
        orderItem: {
          value: item?.orderItem?.id,
          label: `${item?.orderItem?.product?.code} - ${item?.orderItem?.product?.nameVn} (${formatDate(item?.orderItem?.deliveryDate)})`,
          quantity: item?.orderItem?.quantity || null,
          planQty: item?.orderItem?.planQty || null,
        },
        defaultQuantity: item?.quantity,
        remainingQuantity: item?.orderItem?.quantity - item?.orderItem?.planQty,
        quantity: item?.quantity || null,
      })) || [],
  };
};
