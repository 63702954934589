import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { intl } from "app/helpers";
import { useAppTranslation } from "app/hooks";
import { Vendor } from "app/services/vendor";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailVendorGeneralProps {}

const DetailVendorGeneral: FC<DetailVendorGeneralProps> = () => {
  const { vendorText } = useAppTranslation();
  const { data: vendor } = useDetailPageContext<Vendor>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {vendorText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.vendorName}
                    value={vendor?.vendorName}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.vendorCode}
                    value={vendor?.vendorCode}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.vendorType}
                    value={
                      vendor?.vendorType ? (
                        <Chip
                          size="sm"
                          color={vendor?.vendorType?.colorCode}
                          label={vendor?.vendorType?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.address}
                    value={vendor?.address}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.phoneNumber}
                    value={vendor?.phoneNumber}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.email}
                    value={vendor?.email}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.website}
                    value={
                      vendor?.website ? (
                        <a
                          href={vendor.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {vendor.website}
                        </a>
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.representative}
                    value={vendor?.representative}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.representativeRole}
                    value={vendor?.representativeRole}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.startDate}
                    value={
                      vendor?.startDate
                        ? intl.formatDate(new Date(vendor.startDate))
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.vendorStatus}
                    value={
                      vendor?.vendorStatus ? (
                        <Chip
                          size="sm"
                          color={vendor?.vendorStatus?.colorCode}
                          label={vendor?.vendorStatus?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.assignee}
                    value={vendor?.assignee ? vendor.assignee.fullName : "-"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={vendorText.field.notes}
                    value={vendor?.notes}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailVendorGeneral;
