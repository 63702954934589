import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { TicketLog } from "./types";

export const RETRIEVE_TICKET_LOGS_URL = "/api/admin/ticket-logs";

export interface RetrieveTicketLogsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveTicketLogsResponse = Response<PagingData<TicketLog[]>>;

export const retrieveTicketLogs = (args: RetrieveTicketLogsArgs) => {
  return httpClient.get<RetrieveTicketLogsResponse>(RETRIEVE_TICKET_LOGS_URL, {
    params: args,
  });
};

export const useRetrieveTicketLogsQuery = (
  retrieveTicketLogsArgs: RetrieveTicketLogsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveTicketLogsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveTicketLogsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_TICKET_LOGS_URL, retrieveTicketLogsArgs],
    queryFn: () => retrieveTicketLogs(retrieveTicketLogsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
