import {
  RetrieveTicketByIdArgs,
  useRetrieveTicketByIdQuery,
} from "app/services/ticket";

export const useRetrieveTicketById = (
  args: RetrieveTicketByIdArgs,
  opts?: Parameters<typeof useRetrieveTicketByIdQuery>[1],
) => {
  const result = useRetrieveTicketByIdQuery(args, opts as any);
  return {
    ticket: result.data?.data.data,
    isLoadingTicket: result.isLoading,
    ...result,
  };
};
