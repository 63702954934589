import { useAppTranslation, useRouteMatch } from "app/hooks";
import classnames from "classnames";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

export interface DetailTicketTabsProps {}

export enum DetailTicketTabEnum {
  General = "/ticket/:id/general",
  Logs = "/ticket/:id/logs",
}

export const DetailTicketTabs: FC<DetailTicketTabsProps> = () => {
  const { ticketText } = useAppTranslation();
  const { id } = useParams();
  const routeMatch = useRouteMatch(["/ticket/:id/general", "/ticket/:id/logs"]);

  const currentTab = routeMatch?.pattern?.path ?? DetailTicketTabEnum.General;

  return (
    <div className="d-flex px-3">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <Link
            to={`/ticket/${id}/general`}
            className={classnames(
              {
                active: currentTab === DetailTicketTabEnum.General,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {ticketText.detail.tab.general}
            </span>
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={`/ticket/${id}/logs`}
            className={classnames(
              {
                active: currentTab === DetailTicketTabEnum.Logs,
              },
              "fs-14 nav-link",
            )}
            replace
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              {ticketText.detail.tab.log}
            </span>
          </Link>
        </NavItem>
      </Nav>
    </div>
  );
};
