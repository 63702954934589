import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { ProductionPlanAction } from "app/modules/productionPlan/components";
import { useRetrieveProductionPlanById } from "app/modules/productionPlan/hooks";
import { useApproveProductionPlan } from "app/modules/productionPlan/hooks/useApproveProductionPlan";
import { ProductionPlan } from "app/services/productionPlan";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailProductionPlanHeader from "./DetailProductionPlanHeader";
import { DetailProductionPlanTabs } from "./DetailProductionPlanTabs";

const DetailProductionPlan = () => {
  const { id } = useParams();
  const { productionPlanText, confirmText, buttonText } = useAppTranslation();
  const { confirm } = useConfirm();
  const { productionPlan, isLoadingProductionPlan } =
    useRetrieveProductionPlanById({ id: id as string }, { enabled: !!id });

  const { mutateAsync: approve } = useApproveProductionPlan({ id });

  const handleAction = async (action: ProductionPlanAction) => {
    switch (action) {
      case "approve":
        confirm({
          title: confirmText.approveTitle(productionPlanText.title),
          description: confirmText.approveContent(productionPlanText.title),
          severity: "success",
          confirmText: buttonText.approve,
          onConfirm: async () => {
            await approve({ id: id as ID });
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <DetailPage<ProductionPlan>
      data={productionPlan as ProductionPlan}
      loading={isLoadingProductionPlan}
    >
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={productionPlanText.detail.title}
          pageTitle={productionPlanText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailProductionPlanHeader onAction={handleAction} />
      </DetailPage.Header>
      <DetailPage.Main tabs={<DetailProductionPlanTabs />}>
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailProductionPlan;
