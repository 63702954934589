import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Step } from "./types";

export const UPDATE_STEP_URL = (id: ID) => `/api/admin/steps/${id}`;

export enum UpdateStepError {}

export const updateStepErrorMessages = {};

export type UpdateStepResponse = Response<Step>;

export type UpdateStepArgs = {
  id: ID;
  name: string;
  description: string | null;
  skillIds: ID[];
};

export const updateStep = ({ id, ...args }: UpdateStepArgs) => {
  return httpClient.put<UpdateStepResponse>(UPDATE_STEP_URL(id), args);
};

export const useUpdateStepMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateStepResponse>,
      AxiosError<any>,
      UpdateStepArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateStepResponse>,
    AxiosError<any>,
    UpdateStepArgs
  >({
    mutationFn: updateStep,
    mutationKey: [UPDATE_STEP_URL],
    ...opts,
  });
