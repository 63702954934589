import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  createWorkshopErrorMessages,
  RETRIEVE_WORKSHOPS_URL,
  useCreateWorkshopMutation,
} from "app/services/workshop";

interface UseCreateWorkshopProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateWorkshop = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseCreateWorkshopProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useCreateWorkshopMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_WORKSHOPS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          createWorkshopErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
