import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const DELETE_PRODUCT_URL = (id: ID) => `/api/admin/products/${id}`;

export enum DeleteProductError {}

export const deletedProductErrorMessages = {};

export type DeleteProductResponse = Response<Product>;

export type DeleteProductArgs = {
  id: ID;
};

export const deleteProduct = ({ id }: DeleteProductArgs) => {
  return httpClient.delete<DeleteProductResponse>(DELETE_PRODUCT_URL(id));
};

export const useDeleteProductMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteProductResponse>,
    AxiosError<Response<null>>,
    DeleteProductArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_PRODUCT_URL],
    mutationFn: (deletedProductArgs: DeleteProductArgs) =>
      deleteProduct(deletedProductArgs),
    ...opts,
  });
};
