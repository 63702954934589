import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Employee } from "./types";
import { Response } from "../types";

export const RETRIEVE_EMPLOYEE_BY_ID_URL = (id: ID) =>
  `/api/admin/employees/${id}`;

export type RetrieveEmployeeByIdResponse = Response<Employee>;

export type RetrieveEmployeeByIdArgs = {
  id: ID;
};

export const retrieveEmployeeById = ({ id }: RetrieveEmployeeByIdArgs) => {
  return httpClient.get<RetrieveEmployeeByIdResponse>(
    RETRIEVE_EMPLOYEE_BY_ID_URL(id),
  );
};

export const useRetrieveEmployeeByIdQuery = (
  retrieveEmployeesArgs: RetrieveEmployeeByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveEmployeeByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveEmployeeByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_EMPLOYEE_BY_ID_URL(retrieveEmployeesArgs.id)],
    queryFn: () => retrieveEmployeeById(retrieveEmployeesArgs),
    ...opts,
  });
