import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Employee } from "./";
import { Response } from "../types";

export const RETRIEVE_PANEL_EMPLOYEE_BY_ID_URL = (id: ID) =>
  `/api/panel/employees/${id}`;

export type RetrievePanelEmployeeByIdResponse = Response<Employee>;

export type RetrievePanelEmployeeByIdArgs = {
  id: ID;
};

export const retrievePanelEmployeeById = ({
  id,
}: RetrievePanelEmployeeByIdArgs) => {
  return httpClientPanel.get<RetrievePanelEmployeeByIdResponse>(
    RETRIEVE_PANEL_EMPLOYEE_BY_ID_URL(id),
  );
};

export const useRetrievePanelEmployeeByIdQuery = (
  retrieveEmployeesArgs: RetrievePanelEmployeeByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrievePanelEmployeeByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrievePanelEmployeeByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PANEL_EMPLOYEE_BY_ID_URL(retrieveEmployeesArgs.id)],
    queryFn: () => retrievePanelEmployeeById(retrieveEmployeesArgs),
    ...opts,
  });
