import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Customer } from "./types";

export const UPDATE_CUSTOMER_URL = (id: ID) => `/api/admin/customers/${id}`;

export enum UpdateCustomerError {}

export const updateCustomerErrorMessages = {};

export type UpdateCustomerResponse = Response<Customer>;

export type UpdateCustomerArgs = {
  id: ID;
  customerCode: string | null;
  customerName: string;
  customerTypeId: ID | null;
  address: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  representative: string | null;
  representativeRole: string | null;
  paymentTerms: string | null;
  depositPercentage: number | null;
  paymentDueDays: number | null;
  notes: string | null;
  startDate: DateTimeString | null;
  customerStatusId: ID | null;
  assigneeId: ID | null;
};

export const updateCustomer = ({ id, ...args }: UpdateCustomerArgs) => {
  return httpClient.put<UpdateCustomerResponse>(UPDATE_CUSTOMER_URL(id), args);
};

export const useUpdateCustomerMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateCustomerResponse>,
      AxiosError<any>,
      UpdateCustomerArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateCustomerResponse>,
    AxiosError<any>,
    UpdateCustomerArgs
  >({
    mutationFn: updateCustomer,
    mutationKey: [UPDATE_CUSTOMER_URL],
    ...opts,
  });
