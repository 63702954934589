import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Vendor } from "./types";

export const RETRIEVE_VENDORS_URL = "/api/admin/vendors";

export interface RetrieveVendorsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveVendorsResponse = Response<PagingData<Vendor[]>>;

export const retrieveVendors = (args: RetrieveVendorsArgs) => {
  return httpClient.get<RetrieveVendorsResponse>(RETRIEVE_VENDORS_URL, {
    params: args,
  });
};

export const useRetrieveVendorsQuery = (
  retrieveVendorsArgs: RetrieveVendorsArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveVendorsResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveVendorsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_VENDORS_URL, retrieveVendorsArgs],
    queryFn: () => retrieveVendors(retrieveVendorsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
