import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { Source } from "app/models";
import {
  EmployeeForm,
  EmployeeFormData,
} from "app/modules/employee/components";
import {
  useRetrieveEmployeeById,
  useUpdateEmployee,
} from "app/modules/employee/hooks";
import {
  mapDefaultEmployee,
  mapUpdateEmployee,
} from "app/modules/employee/mapper";
import { getUploadedFileIds } from "app/modules/file/helpers";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateEmployeeProps {}

const UpdateEmployee: FC<UpdateEmployeeProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { employeeText } = useAppTranslation();

  const { employee, isLoading } = useRetrieveEmployeeById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateEmployee({
    id,
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleSubmit = async (data: EmployeeFormData) => {
    const imageIds =
      (await getUploadedFileIds({
        data: data.avatar,
        fileKey: "image",
        source: Source.User,
        upload,
      })) || [];
    const imageId = imageIds[0];
    await mutateAsync(
      mapUpdateEmployee({ ...data, id: id as string, avatarId: imageId }),
      {
        onError: () => {
          if (imageIds[0]) {
            deleteFile({ id: imageId });
          }
        },
      },
    );
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={employeeText.edit}
          pageTitle={employeeText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <EmployeeForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultEmployee(employee)}
              submitting={isPending || isUploading}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateEmployee;
