import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Order } from "./types";

export const UPDATE_ORDER_URL = (id: ID) => `/api/admin/orders/${id}`;

export enum UpdateOrderError {}

export const updateOrderErrorMessages = {};

export type UpdateOrderResponse = Response<Order>;

export type UpdateOrderArgs = {
  id: ID;
  orderCode: string | null;
  orderDate: DateTimeString;
  customerId: ID;
  expectedDeliveryDate: DateTimeString;
  notes: string | null;
  orderItems: {
    productId: ID;
    quantity: number;
    deliveryDate: DateTimeString;
    id?: ID;
  }[];
  addFileIds?: ID[];
  deleteFileIds?: ID[];
};

export const updateOrder = ({ id, ...args }: UpdateOrderArgs) => {
  return httpClient.put<UpdateOrderResponse>(UPDATE_ORDER_URL(id), args);
};

export const useUpdateOrderMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateOrderResponse>,
      AxiosError<any>,
      UpdateOrderArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateOrderResponse>,
    AxiosError<any>,
    UpdateOrderArgs
  >({
    mutationFn: updateOrder,
    mutationKey: [UPDATE_ORDER_URL],
    ...opts,
  });
