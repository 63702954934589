import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { mapProductionPlanStatus } from "app/modules/productionPlan/helpers";
import { ProductionPlan } from "app/services/productionPlan";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailProductionPlanGeneralProps {}

const DetailProductionPlanGeneral: FC<
  DetailProductionPlanGeneralProps
> = () => {
  const { productionPlanText } = useAppTranslation();
  const { data: productionPlan } = useDetailPageContext<ProductionPlan>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {productionPlanText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={productionPlanText.field.name}
                    value={productionPlan?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionPlanText.field.status}
                    value={
                      productionPlan?.status
                        ? mapProductionPlanStatus(productionPlan?.status)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionPlanText.field.startDate}
                    value={
                      productionPlan?.startDate
                        ? formatDate(productionPlan.startDate)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionPlanText.field.endDate}
                    value={
                      productionPlan?.startDate
                        ? formatDate(productionPlan.endDate)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={productionPlanText.field.description}
                    value={productionPlan?.description}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailProductionPlanGeneral;
