import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  ProductionOrderForm,
  ProductionOrderFormData,
} from "app/modules/productionOrder/components";
import { useCreateProductionOrder } from "app/modules/productionOrder/hooks";
import {
  mapCreateProductionOrder,
  mapDefaultProductionOrder,
} from "app/modules/productionOrder/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateProductionOrderProps {}

const CreateProductionOrder: FC<CreateProductionOrderProps> = () => {
  const { productionOrderText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateProductionOrder({
    onSuccess: () => {
      navigate("/production-orders");
    },
  });

  const handleSubmit = async (data: ProductionOrderFormData) => {
    mutateAsync(mapCreateProductionOrder(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={productionOrderText.add}
          pageTitle={productionOrderText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <ProductionOrderForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultProductionOrder()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateProductionOrder;
