import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import { StepForm, StepFormData } from "app/modules/step/components";
import { useRetrieveStepById, useUpdateStep } from "app/modules/step/hooks";
import { mapDefaultStep, mapUpdateStep } from "app/modules/step/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateStepProps {}

const UpdateStep: FC<UpdateStepProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { stepText } = useAppTranslation();

  const { step, isLoading } = useRetrieveStepById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateStep({
    id,
    onSuccess: () => {
      navigate("/steps");
    },
  });

  const handleSubmit = async (data: StepFormData) => {
    mutateAsync(mapUpdateStep({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb title={stepText.edit} pageTitle={stepText.title} isBack />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <StepForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultStep(step)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateStep;
