import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { PagingData } from "./../types";
import { Order } from "./types";

export const RETRIEVE_ORDERS_URL = "/api/admin/orders";

export interface RetrieveOrdersArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveOrdersResponse = Response<PagingData<Order[]>>;

export const retrieveOrders = (args: RetrieveOrdersArgs) => {
  return httpClient.get<RetrieveOrdersResponse>(RETRIEVE_ORDERS_URL, {
    params: args,
  });
};

export const useRetrieveOrdersQuery = (
  retrieveOrdersArgs: RetrieveOrdersArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveOrdersResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveOrdersResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_ORDERS_URL, retrieveOrdersArgs],
    queryFn: () => retrieveOrders(retrieveOrdersArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveOrdersInfiniteQuery = (
  retrieveOrdersArgs: RetrieveOrdersArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveOrdersResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveOrdersResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveOrdersResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_ORDERS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveOrdersArgs;
      return retrieveOrders({
        ...retrieveOrdersArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config.params as RetrieveOrdersArgs;
      let nextPageParam: RetrieveOrdersArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveOrdersArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
