import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "./types";
import { Response } from "../types";

export const RETRIEVE_TICKET_BY_ID_URL = (id: ID) => `/api/admin/tickets/${id}`;

export type RetrieveTicketByIdResponse = Response<Ticket>;

export type RetrieveTicketByIdArgs = {
  id: ID;
};

export const retrieveTicketById = ({ id }: RetrieveTicketByIdArgs) => {
  return httpClient.get<RetrieveTicketByIdResponse>(
    RETRIEVE_TICKET_BY_ID_URL(id),
  );
};

export const useRetrieveTicketByIdQuery = (
  retrieveTicketsArgs: RetrieveTicketByIdArgs,
  opts?: Omit<
    UseQueryOptions<AxiosResponse<RetrieveTicketByIdResponse>, AxiosError<any>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveTicketByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_TICKET_BY_ID_URL(retrieveTicketsArgs.id)],
    queryFn: () => retrieveTicketById(retrieveTicketsArgs),
    ...opts,
  });
