import { WithID } from "app/models";
import { CreateStepArgs, Step, UpdateStepArgs } from "app/services/step";
import { CommonProperties } from "app/services/types";
import { StepFormData } from "./components";

export const mapper = ({
  name,
  description,
  skills,
}: StepFormData): CommonProperties<CreateStepArgs, UpdateStepArgs> => {
  return {
    name,
    description: description || null,
    skillIds: skills.map(({ value }) => value),
  };
};

export const mapCreateStep = (data: StepFormData): CreateStepArgs => {
  return mapper(data);
};

export const mapUpdateStep = ({
  id,
  ...data
}: WithID<StepFormData>): UpdateStepArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultStep = ({
  name,
  description,
  stepSkills,
}: Partial<Step> = {}): StepFormData => {
  return {
    name: name || "",
    description: description || "",
    skills:
      stepSkills?.map((skill) => ({
        label: skill.skill.name,
        value: skill.skill.id,
      })) || [],
  };
};
