import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { APIError, APISuccess, Response } from "../types";
import { httpClient } from "app/datasources";

export const LOGOUT_URL = "/api/admin/auth/logout";

export type LogoutResponse = Response<unknown>;

export enum LogoutError {
  InvalidLogoutInfo = "User Not Found",
}

export const logoutErrorMessages = {
  [LogoutError.InvalidLogoutInfo]: "Thông tin đăng xuất không chính xác",
};

export const logout = () => {
  return httpClient.post<LogoutResponse>(LOGOUT_URL);
};

export const useLogoutMutation = (
  opts?: UseMutationOptions<APISuccess<LogoutResponse>, APIError<LogoutError>>,
) => useMutation({ mutationFn: logout, mutationKey: [LOGOUT_URL], ...opts });
