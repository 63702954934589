import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deletedWorkshopErrorMessages,
  RETRIEVE_WORKSHOPS_URL,
  useDeleteWorkshopMutation,
} from "app/services/workshop";

interface UseDeleteWorkshopProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteWorkshop = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseDeleteWorkshopProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteWorkshopMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_WORKSHOPS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deletedWorkshopErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
