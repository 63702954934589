import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Customer } from "./types";
import { Response } from "../types";

export const RETRIEVE_CUSTOMER_BY_ID_URL = (id: ID) =>
  `/api/admin/customers/${id}`;

export type RetrieveCustomerByIdResponse = Response<Customer>;

export type RetrieveCustomerByIdArgs = {
  id: ID;
};

export const retrieveCustomerById = ({ id }: RetrieveCustomerByIdArgs) => {
  return httpClient.get<RetrieveCustomerByIdResponse>(
    RETRIEVE_CUSTOMER_BY_ID_URL(id),
  );
};

export const useRetrieveCustomerByIdQuery = (
  retrieveCustomersArgs: RetrieveCustomerByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveCustomerByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveCustomerByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_CUSTOMER_BY_ID_URL(retrieveCustomersArgs.id)],
    queryFn: () => retrieveCustomerById(retrieveCustomersArgs),
    ...opts,
  });
