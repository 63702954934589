import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Product } from "./types";

export const DEACTIVE_PRODUCT_URL = (id: ID) =>
  `/api/admin/products/${id}/deactive`;

export enum DeactiveProductError {}

export const deactiveProductErrorMessages = {};

export type DeactiveProductResponse = Response<Product>;

export type DeactiveProductArgs = {
  id: ID;
};

export const deactiveProduct = ({ id }: DeactiveProductArgs) => {
  return httpClient.put<DeactiveProductResponse>(DEACTIVE_PRODUCT_URL(id));
};

export const useDeactiveProductMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<DeactiveProductResponse>,
      AxiosError<any>,
      DeactiveProductArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<DeactiveProductResponse>,
    AxiosError<any>,
    DeactiveProductArgs
  >({
    mutationFn: deactiveProduct,
    mutationKey: [DEACTIVE_PRODUCT_URL],
    ...opts,
  });
