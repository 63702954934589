import {
  RetrieveWorkshopsArgs,
  useRetrieveWorkshopsQuery,
} from "app/services/workshop";

export const useRetrieveWorkshops = (args: RetrieveWorkshopsArgs) => {
  const result = useRetrieveWorkshopsQuery(args);
  return {
    workshops: result.data?.data.data,
    isLoadingWorkshops: result.isLoading,
    ...result,
  };
};
