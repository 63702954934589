import { AttachmentBar } from "app/components/organisms/AttachmentBar";
import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation, useDebounceValue } from "app/hooks";
import useToastify from "app/hooks/useToastify";
import { FileDTO, Source } from "app/models";
import { getUploadedFileIds } from "app/modules/file/helpers";
import {
  useRetrieveFilesInfinite,
  useUploadModuleFiles,
} from "app/modules/file/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { useDeleteFile, useUploadFiles } from "app/services/file";
import { Order } from "app/services/order";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import DetailOrderHeader from "./DetailOrderHeader";
import { DetailOrderTabs } from "./DetailOrderTabs";

export enum DetailProductTabEnum {
  General = "/order/:id/general",
  OrderItems = "/order/:id/order-items",
}

const DetailOrder = () => {
  const { id } = useParams();
  const toastify = useToastify();
  const { orderText } = useAppTranslation();
  const { order, isLoadingOrder } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  const [searchString, setSearchString] = useState("");

  const handleSetSearchString = (value: string) => {
    setSearchString(value);
  };

  const debouncedSearchString = useDebounceValue(searchString);

  const { mutateAsync: deleteFile } = useDeleteFile();

  const handleDelete = async (id: string) => {
    deleteFile(
      { id },
      {
        onSuccess: () => {
          toastify.success();
        },
        onError: () => {
          toastify.error();
        },
      },
    );
  };

  const { mutateAsync } = useUploadModuleFiles();

  const { mutateAsync: upload, isPending: isUploading } = useUploadFiles();

  // Images
  const {
    files: _images,
    hasNextPage: hasNextPageImages,
    fetchNextPage: fetchNextPageImages,
    isFetchingNextPage: isFetchingNextPageImages,
    isLoading: isImagesLoading,
  } = useRetrieveFilesInfinite({
    moduleId: id as ID,
    moduleType: Source.Order,
    fileType: "image",
    searchString: debouncedSearchString,
  });

  const imagesData = useMemo(() => {
    let result: FileDTO[] = [];
    _images?.pages.forEach((page) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [_images]);

  const handleUploadImages = async (data: File[]) => {
    const imageIds =
      (await getUploadedFileIds({
        data: data,
        fileKey: "image",
        source: Source.Order,
        upload,
      })) || [];
    await mutateAsync(
      {
        fileIds: imageIds,
        moduleId: id as string,
        moduleType: Source.Order,
        relationType: "order",
      },
      {
        onError: () => {
          imageIds?.map((imageId) => {
            deleteFile({ id: imageId });
          });
        },
      },
    );
  };

  // Documents
  const {
    files: _documents,
    hasNextPage: hasNextPageDocuments,
    fetchNextPage: fetchNextPageDocuments,
    isFetchingNextPage: isFetchingNextPageDocuments,
    isLoading: isDocumentsLoading,
  } = useRetrieveFilesInfinite({
    moduleId: id as ID,
    moduleType: Source.Order,
    fileType: "document",
    searchString: debouncedSearchString,
  });

  const documentsData = useMemo(() => {
    let result: FileDTO[] = [];
    _images?.pages.forEach((page) => {
      result = result.concat(page.data.data.result);
    });
    return result;
  }, [_documents]);

  const handleUploadDocuments = async (data: File[]) => {
    const documentIds =
      (await getUploadedFileIds({
        data: data,
        fileKey: "document",
        source: Source.Order,
        upload,
      })) || [];
    await mutateAsync(
      {
        fileIds: documentIds,
        moduleId: id as string,
        moduleType: Source.Order,
        relationType: "order",
      },
      {
        onError: () => {
          documentIds?.map((documentId) => {
            deleteFile({ id: documentId });
          });
        },
      },
    );
  };

  return (
    <DetailPage<Order> data={order as Order} loading={isLoadingOrder}>
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={orderText.detail.title}
          pageTitle={orderText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailOrderHeader />
      </DetailPage.Header>
      <DetailPage.Main
        tabs={<DetailOrderTabs />}
        attachmentBar={
          <AttachmentBar
            imageProps={{
              data: imagesData || [],
              onUpload: handleUploadImages,
              uploading: isUploading,
              loading: isImagesLoading,
              onDelete: handleDelete,
              fetchingProps: {
                fetchNextPage: fetchNextPageImages,
                hasNextPage: hasNextPageImages,
                isFetchingNextPage: isFetchingNextPageImages,
              },
            }}
            documentProps={{
              data: documentsData || [],
              onUpload: handleUploadDocuments,
              uploading: isUploading,
              loading: isDocumentsLoading,
              onDelete: handleDelete,
              fetchingProps: {
                fetchNextPage: fetchNextPageDocuments,
                hasNextPage: hasNextPageDocuments,
                isFetchingNextPage: isFetchingNextPageDocuments,
              },
            }}
            onSearch={handleSetSearchString}
          />
        }
      >
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailOrder;
