import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Vendor } from "./types";

export const UPDATE_VENDOR_URL = (id: ID) => `/api/admin/vendors/${id}`;

export enum UpdateVendorError {}

export const updateVendorErrorMessages = {};

export type UpdateVendorResponse = Response<Vendor>;

export type UpdateVendorArgs = {
  id: ID;
  vendorCode: string | null;
  vendorName: string;
  vendorTypeId: ID | null;
  address: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  representative: string | null;
  representativeRole: string | null;
  notes: string | null;
  startDate: DateTimeString | null;
  vendorStatusId: ID | null;
  assigneeId: ID | null;
};

export const updateVendor = ({ id, ...args }: UpdateVendorArgs) => {
  return httpClient.put<UpdateVendorResponse>(UPDATE_VENDOR_URL(id), args);
};

export const useUpdateVendorMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateVendorResponse>,
      AxiosError<any>,
      UpdateVendorArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateVendorResponse>,
    AxiosError<any>,
    UpdateVendorArgs
  >({
    mutationFn: updateVendor,
    mutationKey: [UPDATE_VENDOR_URL],
    ...opts,
  });
