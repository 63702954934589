import { PagingData } from "./../types";
import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { ProductionPlan, ProductionPlanStatus } from "./types";
import { DEFAULT_LIMIT_PAGE, DEFAULT_PAGE } from "app/helpers";

export const RETRIEVE_PRODUCTION_PLANS_URL = "/api/admin/production-plans";

export interface RetrieveProductionPlansArgs {
  page: number;
  limit: number;
  searchString?: string;
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  status?: ProductionPlanStatus[];
}

export type RetrieveProductionPlansResponse = Response<
  PagingData<ProductionPlan[]>
>;

export const retrieveProductionPlans = (args: RetrieveProductionPlansArgs) => {
  return httpClient.get<RetrieveProductionPlansResponse>(
    RETRIEVE_PRODUCTION_PLANS_URL,
    { params: args },
  );
};

export const useRetrieveProductionPlansQuery = (
  retrieveProductionPlansArgs: RetrieveProductionPlansArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductionPlansResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductionPlansResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCTION_PLANS_URL, retrieveProductionPlansArgs],
    queryFn: () => retrieveProductionPlans(retrieveProductionPlansArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });

export const useRetrieveProductionPlansInfiniteQuery = (
  retrieveProductionPlansArgs: RetrieveProductionPlansArgs,
  opts?: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<RetrieveProductionPlansResponse>,
      AxiosError<any>
    >,
    "initialPageParam" | "queryFn" | "queryKey" | "getNextPageParam"
  >,
): any => {
  return useInfiniteQuery<
    AxiosResponse<RetrieveProductionPlansResponse>,
    AxiosError<any>,
    AxiosResponse<RetrieveProductionPlansResponse, any>
  >({
    initialPageParam: {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    },
    queryKey: [RETRIEVE_PRODUCTION_PLANS_URL, "INFINITE_QUERY"],
    queryFn: ({ pageParam }) => {
      const { page, limit } = pageParam as RetrieveProductionPlansArgs;
      return retrieveProductionPlans({
        ...retrieveProductionPlansArgs,
        page,
        limit,
      });
    },
    getNextPageParam: (lastPage) => {
      const total = lastPage.data.data.total;
      const { limit, page } = lastPage.config
        .params as RetrieveProductionPlansArgs;
      let nextPageParam: RetrieveProductionPlansArgs | undefined;

      if (page) {
        const hasNextPage = page * (limit || DEFAULT_LIMIT_PAGE) < total;

        if (hasNextPage) {
          nextPageParam = {
            ...retrieveProductionPlansArgs,
            page: page + 1,
            limit,
          };
        }
      }
      return nextPageParam;
    },
    ...opts,
  });
};
