import { PagingData } from "./../types";
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { httpClient } from "app/datasources";
import { Department } from "./types";

export const RETRIEVE_DEPARTMENTS_URL = "/api/admin/departments";

export interface RetrieveDepartmentsArgs {
  page: number;
  limit: number;
  searchString?: string;
}

export type RetrieveDepartmentsResponse = Response<PagingData<Department[]>>;

export const retrieveDepartments = (args: RetrieveDepartmentsArgs) => {
  return httpClient.get<RetrieveDepartmentsResponse>(RETRIEVE_DEPARTMENTS_URL, {
    params: args,
  });
};

export const useRetrieveDepartmentsQuery = (
  retrieveDepartmentsArgs: RetrieveDepartmentsArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveDepartmentsResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveDepartmentsResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_DEPARTMENTS_URL, retrieveDepartmentsArgs],
    queryFn: () => retrieveDepartments(retrieveDepartmentsArgs),
    placeholderData: keepPreviousData,
    ...opts,
  });
