import {
  RetrieveComponentsProductionOrderIdArgs,
  useRetrieveComponentsProductionOrderIdQuery,
} from "app/services/product";

export const useRetrieveComponentsProductionOrderId = (
  args: RetrieveComponentsProductionOrderIdArgs,
  opts?: Parameters<typeof useRetrieveComponentsProductionOrderIdQuery>[1],
) => {
  const result = useRetrieveComponentsProductionOrderIdQuery(args, opts as any);
  return {
    components: result.data?.data.data,
    isLoadingComponents: result.isLoading,
    ...result,
  };
};
