import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { FilterOffCanvas } from "app/components/molecules/FilterOffCanvas";
import { FormLabel } from "app/components/molecules/FormLabel";
import { ReactSelectCustom } from "app/components/molecules/ReactSelectCustom";
import { useAppTranslation } from "app/hooks";
import { EmployeeSelect } from "app/modules/employee/components";
import { StepSelect } from "app/modules/step/components/StepSelect";
import { ticketStatusOptions } from "app/modules/ticket/constants";
import { useRetrieveTickets } from "app/modules/ticket/hooks";
import { WorkshopSelect } from "app/modules/workshop/components";
import { TicketStatus } from "app/services/ticket";
import { OptionSelect } from "app/services/types";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "reactstrap";

export interface TicketFilterParams {
  status: OptionSelect<TicketStatus>[];
  productSteps: OptionSelect[];
  assignees: OptionSelect[];
  workshops: OptionSelect[];
}

export interface ListTicketFilterProps {
  searchString: string;
  filterParams: TicketFilterParams;
  handleSearchChange: (value: string) => void;
  setCustomParams: (params: TicketFilterParams) => void;
}

export const ListTicketFilter: FC<ListTicketFilterProps> = ({
  handleSearchChange,
  filterParams,
  searchString,
  setCustomParams,
}) => {
  const { ticketText } = useAppTranslation();
  const { control, reset, watch, handleSubmit } = useForm<TicketFilterParams>({
    defaultValues: filterParams,
  });

  const watchStatus = watch("status");
  const watchProductSteps = watch("productSteps");
  const watchAssignees = watch("assignees");
  const watchWorkshops = watch("workshops");

  const { tickets } = useRetrieveTickets({
    limit: 20,
    page: 1,
    searchString: searchString || undefined,
    status: watchStatus?.map((item) => item.value),
    productStepIds: watchProductSteps?.map((item) => item.value),
    assigneeIds: watchAssignees?.map((item) => item.value),
    workshopIds: watchWorkshops?.map((item) => item.value),
  });

  const total = tickets?.total || 0;

  const handleFilter = () => {
    setCustomParams({
      status: watchStatus,
      productSteps: watchProductSteps,
      assignees: watchAssignees,
      workshops: watchWorkshops,
    });
    handleToggle();
  };

  const handleClearFilter = () => {
    reset({
      status: [],
      productSteps: [],
      assignees: [],
      workshops: [],
    });
  };

  useEffect(() => {
    reset(filterParams);
  }, [filterParams]);

  const formID = "ticket-filter-form";

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="search-box me-2 d-inline-block">
        <DebouncedInput
          name="list-ticket-search"
          value={searchString || ""}
          onChange={(value) => {
            handleSearchChange(String(value));
          }}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
      <form noValidate id={formID} onSubmit={handleSubmit(handleFilter)}>
        <FilterOffCanvas
          formId={formID}
          handleClearFilter={handleClearFilter}
          onToggle={handleToggle}
          show={show}
          total={total}
        >
          <div className="d-flex flex-column flex-grow-1">
            <FormGroup>
              <FormLabel className="mb-2">{ticketText.field.status}</FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <ReactSelectCustom
                    isMulti
                    value={field.value}
                    onChange={field.onChange}
                    options={ticketStatusOptions}
                    placeholder="Chọn"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {ticketText.field.productStep}
              </FormLabel>
              <Controller
                name="productSteps"
                control={control}
                render={({ field, fieldState }) => (
                  <StepSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    inputId="productSteps"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {ticketText.field.assignee}
              </FormLabel>
              <Controller
                name="assignees"
                control={control}
                render={({ field, fieldState }) => (
                  <EmployeeSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    inputId="assignees"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="mb-2">
                {ticketText.field.workshop}
              </FormLabel>
              <Controller
                name="workshops"
                control={control}
                render={({ field, fieldState }) => (
                  <WorkshopSelect
                    isMulti
                    name={field.name}
                    value={field.value}
                    inputId="workshops"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                )}
              />
            </FormGroup>
          </div>
        </FilterOffCanvas>
      </form>
    </div>
  );
};
