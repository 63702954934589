import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Step } from "./types";

export const CREATE_STEP_URL = "/api/admin/steps";

export enum CreateStepError {}

export const createStepErrorMessages = {};

export interface CreateStepArgs {
  name: string;
  description: string | null;
  skillIds: ID[];
}

export type CreateStepResponse = Response<Step>;

export const createStep = (args: CreateStepArgs) => {
  return httpClient.post<CreateStepResponse>(CREATE_STEP_URL, args);
};

export const useCreateStepMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateStepResponse>,
      AxiosError<any>,
      CreateStepArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateStepResponse>,
    AxiosError<any>,
    CreateStepArgs
  >({
    mutationFn: createStep,
    mutationKey: [CREATE_STEP_URL],
    ...opts,
  });
