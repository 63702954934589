import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import { RETRIEVE_TICKET_BY_ID_URL } from "app/services/ticket";
import {
  RETRIEVE_TICKET_LOG_BY_ID_URL,
  RETRIEVE_TICKET_LOGS_URL,
  updateTicketLogErrorMessages,
  useUpdateTicketLogMutation,
} from "app/services/ticketLog";

interface useCreateTicketLogProps {
  onSuccess?: () => void;
  onError?: () => void;
  ticketId?: ID; // to invalidate the cache
  id?: ID; // to invalidate the cache
}

export const useUpdateTicketLog = ({
  onError: handleError,
  onSuccess: handleSuccess,
  id,
  ticketId,
}: useCreateTicketLogProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useUpdateTicketLogMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_TICKET_LOGS_URL] });
      if (ticketId) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_TICKET_BY_ID_URL(ticketId)],
        });
      }
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [RETRIEVE_TICKET_LOG_BY_ID_URL(id)],
        });
      }
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          updateTicketLogErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
