import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  WorkshopForm,
  WorkshopFormData,
} from "app/modules/workshop/components";
import {
  useRetrieveWorkshopById,
  useUpdateWorkshop,
} from "app/modules/workshop/hooks";
import {
  mapDefaultWorkshop,
  mapUpdateWorkshop,
} from "app/modules/workshop/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface UpdateWorkshopProps {}

const UpdateWorkshop: FC<UpdateWorkshopProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { workshopText } = useAppTranslation();

  const { workshop, isLoading } = useRetrieveWorkshopById(
    { id: id as string },
    { enabled: !!id },
  );

  const { mutateAsync, isPending } = useUpdateWorkshop({
    id,
    onSuccess: () => {
      navigate("/workshops");
    },
  });

  const handleSubmit = async (data: WorkshopFormData) => {
    mutateAsync(mapUpdateWorkshop({ id: id as string, ...data }));
  };

  return (
    <FormPage loading={isLoading}>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={workshopText.edit}
          pageTitle={workshopText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <WorkshopForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultWorkshop(workshop)}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default UpdateWorkshop;
