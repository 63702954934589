import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientPanel } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "./types";

export const CHECK_IN_PANEL_TICKET_URL = `/api/panel/ticket-logs/`;

export enum CheckInPanelTicketError {}

export const checkInPanelTicketErrorMessages = {};

export interface CheckInPanelTicketArgs {
  ticketId: ID;
  employeeId: ID;
  quantity: number;
  note: string | null;
}

export type CheckInPanelTicketResponse = Response<Ticket>;

export const checkInPanelTicket = (args: CheckInPanelTicketArgs) => {
  return httpClientPanel.post<CheckInPanelTicketResponse>(
    CHECK_IN_PANEL_TICKET_URL,
    args,
  );
};

export const useCheckInPanelTicketMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CheckInPanelTicketResponse>,
      AxiosError<any>,
      CheckInPanelTicketArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CheckInPanelTicketResponse>,
    AxiosError<any>,
    CheckInPanelTicketArgs
  >({
    mutationFn: checkInPanelTicket,
    mutationKey: [CHECK_IN_PANEL_TICKET_URL],
    ...opts,
  });
