import { useQueryClient } from "@tanstack/react-query";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  deletedStepErrorMessages,
  RETRIEVE_STEPS_URL,
  useDeleteStepMutation,
} from "app/services/step";

interface UseDeleteStepProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteStep = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: UseDeleteStepProps = {}) => {
  const toast = useToastify();
  const queryClient = useQueryClient();
  return useDeleteStepMutation({
    onSuccess: () => {
      toast.success();
      queryClient.invalidateQueries({ queryKey: [RETRIEVE_STEPS_URL] });
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          deletedStepErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
