import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Workshop } from "./types";

export const UPDATE_WORKSHOP_URL = (id: ID) => `/api/admin/workshops/${id}`;

export enum UpdateWorkshopError {}

export const updateWorkshopErrorMessages = {};

export type UpdateWorkshopResponse = Response<Workshop>;

export type UpdateWorkshopArgs = {
  id: ID;
  name: string;
  description: string | null;
  address: string | null;
};

export const updateWorkshop = ({ id, ...args }: UpdateWorkshopArgs) => {
  return httpClient.put<UpdateWorkshopResponse>(UPDATE_WORKSHOP_URL(id), args);
};

export const useUpdateWorkshopMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateWorkshopResponse>,
      AxiosError<any>,
      UpdateWorkshopArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<UpdateWorkshopResponse>,
    AxiosError<any>,
    UpdateWorkshopArgs
  >({
    mutationFn: updateWorkshop,
    mutationKey: [UPDATE_WORKSHOP_URL],
    ...opts,
  });
