import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import { EmployeeSelect } from "app/modules/employee/components";
import { MasterDataInfiniteSelect } from "app/modules/masterData/components/MasterDataInfinitySelect/MasterDataInfinitySelect";
import { MasterData, MasterDataType } from "app/services/masterData";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

export interface VendorFormProps {
  defaultValues?: VendorFormData;
  onSubmit: (data: VendorFormData) => void;
  submitting?: boolean;
}

export interface VendorFormData {
  vendorCode: string;
  vendorName: string;
  vendorType: OptionSelect<ID> | null;
  address: string;
  phoneNumber: string;
  email: string;
  website: string;
  representative: string;
  representativeRole: string;
  notes: string;
  startDate: Date | null;
  vendorStatus: OptionSelect<ID> | null;
  assignee: OptionSelect<ID> | null;
}

export const VendorForm: FC<VendorFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
}) => {
  const { vendorText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<VendorFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        vendorCode: yupSchema.stringNotRequired(),
        vendorName: yupSchema.stringRequired("vendor.error.vendorNameRequired"),
        vendorType: yupSchema.objectNotRequired(),
        vendorStatus: yupSchema.objectNotRequired(),
        assignee: yupSchema.objectNotRequired(),
        address: yupSchema.stringNotRequired(),
        phoneNumber: yupSchema.stringNotRequired(),
        email: yupSchema.emailNotRequired("vendor.error.emailInvalid"),
        representative: yupSchema.stringNotRequired(),
        representativeRole: yupSchema.stringNotRequired(),
        startDate: yupSchema.dateNotRequired(),
        notes: yupSchema.stringNotRequired(),
        website: yupSchema.websiteNotRequired("vendor.error.websiteInvalid"),
      }),
    ),
    defaultValues,
  });

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const handleSetValue = (name: keyof VendorFormData) => (data: MasterData) => {
    setValue(
      name,
      {
        value: data.id,
        label: data.name,
      },
      { shouldDirty: true },
    );
  };

  return (
    <Form onSubmit={submit} id="vendor-form">
      <Row>
        <Col lg={12}>
          <FormGroup>
            <FormLabel required htmlFor="vendorName" className="form-label">
              {vendorText.field.vendorName}
            </FormLabel>
            <Controller
              name="vendorName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="vendorName"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="vendorCode" className="form-label">
              {vendorText.field.vendorCode}
            </Label>
            <Controller
              name="vendorCode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="vendorCode"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="vendorType" className="form-label">
              {vendorText.field.vendorType}
            </Label>
            <Controller
              name="vendorType"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isClearable
                    key="vendorType"
                    name="vendorType"
                    inputId="vendorType"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.VendorType }}
                    onCreateSuccess={handleSetValue("vendorType")}
                  />
                  <FormFeedback type="invalid" className="d-block">
                    {fieldState.error?.message}
                  </FormFeedback>
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="address" className="form-label">
              {vendorText.field.address}
            </Label>
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="address"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="phoneNumber" className="form-label">
              {vendorText.field.phoneNumber}
            </Label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="email" className="form-label">
              {vendorText.field.email}
            </Label>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="email"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="website" className="form-label">
              {vendorText.field.website}
            </Label>
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="url"
                    className="form-control"
                    id="website"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="representative" className="form-label">
              {vendorText.field.representative}
            </Label>
            <Controller
              name="representative"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="representative"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="representativeRole" className="form-label">
              {vendorText.field.representativeRole}
            </Label>
            <Controller
              name="representativeRole"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="representativeRole"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="startDate" className="form-label">
              {vendorText.field.startDate}
            </Label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="startDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date || null);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="vendorStatus" className="form-label">
              {vendorText.field.vendorStatus}
            </Label>
            <Controller
              name="vendorStatus"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <MasterDataInfiniteSelect
                    isCreatable
                    key="vendorStatus"
                    name="vendorStatus"
                    inputId="vendorStatus"
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                    retrieveKeys={{ type: MasterDataType.VendorStatus }}
                    onCreateSuccess={handleSetValue("vendorStatus")}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="assignee" className="form-label">
              {vendorText.field.assignee}
            </Label>
            <Controller
              name="assignee"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <EmployeeSelect
                    name={field.name}
                    value={field.value}
                    inputId="assignee"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="notes" className="form-label">
              {vendorText.field.notes}
            </Label>
            <Controller
              name="notes"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="notes"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
