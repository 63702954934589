import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";
import { Response } from "../types";

export const RETRIEVE_PRODUCT_BY_ID_URL = (id: ID) =>
  `/api/admin/products/${id}`;

export type RetrieveProductByIdResponse = Response<Product>;

export type RetrieveProductByIdArgs = {
  id: ID;
};

export const retrieveProductById = ({ id }: RetrieveProductByIdArgs) => {
  return httpClient.get<RetrieveProductByIdResponse>(
    RETRIEVE_PRODUCT_BY_ID_URL(id),
  );
};

export const useRetrieveProductByIdQuery = (
  retrieveProductsArgs: RetrieveProductByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCT_BY_ID_URL(retrieveProductsArgs.id)],
    queryFn: () => retrieveProductById(retrieveProductsArgs),
    ...opts,
  });
