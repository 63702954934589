import { useAuth } from "app/components/organisms/AuthProvider";
import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  loginErrorMessages,
  tokenStorage,
  useLoginPanelMutation,
} from "app/services/auth";
import { useNavigate } from "react-router-dom";

export const useLoginPanel = () => {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const toast = useToastify();
  return useLoginPanelMutation({
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data.data.data;
      tokenStorage.setRefreshToken(refreshToken);
      authenticate({ accessToken });
      navigate("/panel/scan");
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(loginErrorMessages, error.response?.data.message),
      );
    },
  });
};
