import {
  ProductionOrderItemStatus,
  ProductionOrderStatus,
} from "app/services/productionOrder";

export const productionOrderStatusMappers: Record<
  ProductionOrderStatus,
  string
> = {
  [ProductionOrderStatus.InProgress]: "Đang thực hiện",
  [ProductionOrderStatus.Todo]: "Chờ thực hiện",
};

export const productionOrderItemStatusMappers: Record<
  ProductionOrderItemStatus,
  string
> = {
  [ProductionOrderItemStatus.InProgress]: "Đang thực hiện",
  [ProductionOrderItemStatus.Todo]: "Chờ thực hiện",
};

export const productionOrderStatusOptions = Object.keys(
  ProductionOrderStatus,
).map((key) => ({
  value: key as ProductionOrderStatus,
  label: productionOrderStatusMappers[key as ProductionOrderStatus],
}));
