import { ColumnDef } from "@tanstack/react-table";
import { Chip } from "app/components/atoms/Chip";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  ProductAction,
  ProductActionDropdown,
} from "app/modules/product/components";
import {
  useDeleteProduct,
  useRetrieveProducts,
} from "app/modules/product/hooks";
import { useActiveProduct } from "app/modules/product/hooks/useActiveProduct";
import { useDeactiveProduct } from "app/modules/product/hooks/useDeactiveProduct";
import { Product } from "app/services/product";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import { FC, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface ListProductProps {}

const ListProduct: FC<ListProductProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const rowSelected = useRef<Product | null>(null);
  const { productText, confirmText } = useAppTranslation();

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { products, isLoadingProducts } = useRetrieveProducts({
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteProduct } = useDeleteProduct();

  const { mutateAsync: activeProduct } = useActiveProduct({
    id: rowSelected.current?.id,
  });

  const { mutateAsync: deactiveProduct } = useDeactiveProduct({
    id: rowSelected.current?.id,
  });

  const handleActionClick = (action: ProductAction, data?: Product) => {
    if (data) {
      rowSelected.current = data;
    }
    switch (action) {
      case "active":
        if (data) activeProduct({ id: data.id });
        break;
      case "deactive":
        if (data) deactiveProduct({ id: data.id });
        break;
      case "edit":
        if (data) {
          navigate(`/product/${data.id}/update`);
        }
        break;
      case "detail":
        if (data) {
          navigate(`/product/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(productText.title),
            description: confirmText.deleteContent(productText.title),
            onConfirm: () => deleteProduct({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Product>[] = useMemo(
    () => [
      {
        header: productText.field.code,
        accessorKey: "code",
        size: 200,
        cell: ({ row }) => (
          <AvatarRow
            name={row.original.nameVn}
            avatarUrl={row.original.productImage?.attachmentUrl}
          />
        ),
      },
      {
        header: productText.field.nameVn,
        accessorKey: "nameVn",
      },
      {
        header: productText.field.nameEn,
        accessorKey: "nameEn",
        cell: ({ row }) => row.original.nameEn || "-",
      },
      {
        header: productText.field.type,
        accessorKey: "productType",
        cell: ({ row }) =>
          row.original.productType?.name ? (
            <Chip
              size="sm"
              color={row.original.productType?.colorCode}
              label={row.original.productType?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: productText.field.group,
        accessorKey: "productGroup",
        cell: ({ row }) =>
          row.original.productGroup?.name ? (
            <Chip
              size="sm"
              color={row.original.productGroup?.colorCode}
              label={row.original.productGroup?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: productText.field.unit,
        accessorKey: "productUnit",
        cell: ({ row }) =>
          row.original.productUnit?.name ? (
            <Chip
              size="sm"
              color={row.original.productUnit?.colorCode}
              label={row.original.productUnit?.name}
            />
          ) : (
            "-"
          ),
      },
      {
        header: productText.field.countryOfOrigin,
        accessorKey: "countryOfOrigin",
        cell: ({ row }) => row.original.countryOfOrigin || "-",
      },
      {
        header: productText.field.isActive,
        accessorKey: "isActive",
        cell: ({ row }) => (row.original.isActive ? "Có" : "Không"),
      },
      {
        header: productText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [productText],
  );

  return (
    <ListPage>
      <ListPage.BreadCrumb>
        <BreadCrumb title={productText.title} pageTitle="Kingston" />
      </ListPage.BreadCrumb>
      <ListPage.Filter>
        <Row>
          <Col xs={6}>
            <div className="search-box me-2 mb-2 d-inline-block col-12">
              <DebouncedInput
                name="list-product-search"
                value={searchString || ""}
                onChange={(value) => {
                  setSearchString(String(value));
                }}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </Col>
          <Col xs={6} className="text-end">
            <Button
              color="primary"
              size="sm"
              onClick={() => navigate("/product/new")}
            >
              {productText.add}
            </Button>
          </Col>
        </Row>
      </ListPage.Filter>
      <ListPage.Main>
        <DataTable<Product>
          columns={columns}
          data={products?.result || []}
          loading={isLoadingProducts}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          total={products?.total || 0}
          onRowClick={(row) => handleActionClick("detail", row)}
        />
      </ListPage.Main>
    </ListPage>
  );
};

export default ListProduct;
