import {
  tokenStorage,
  useRetrieveCurrentPanelProfile,
  useRetrieveCurrentProfile,
  userStorage,
} from "app/services/auth";
import { FC, PropsWithChildren, useEffect, useReducer, useRef } from "react";
import { AuthContext, IAuthContext } from "./AuthContext";
import {
  AuthState,
  JsonWebTokenState,
  authReducer,
  checkAccessToken,
} from "./authReducer";

export interface AuthProviderProps extends PropsWithChildren {
  onAuthenticated?: () => void;
  onLogout?: () => void;
}

// State of the access token, when init app when doesn't refresh yet or does anything
const initialAccessTokenState = checkAccessToken();
console.info(
  `[Diagnostic] AuthProvider: Initial access token state: `,
  initialAccessTokenState,
);

const initialAuthState: AuthState = {
  authenticated:
    !initialAccessTokenState.invalid && !initialAccessTokenState.expired,
  currentUser:
    !initialAccessTokenState.invalid && !initialAccessTokenState.expired
      ? userStorage.get()
      : null,
  fetchingCurrentUser:
    !initialAccessTokenState.invalid && !initialAccessTokenState.expired,
  fetchCurrentUserError: null,
};
console.info(
  `[Diagnostic] AuthProvider: Initial auth reducer state: `,
  initialAuthState,
);

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  onAuthenticated = () => null,
  onLogout = () => null,
}) => {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState);

  const initialAccessTokenStateRef = useRef<JsonWebTokenState>(
    initialAccessTokenState,
  );

  const retrieveCurrentProfileResult = useRetrieveCurrentProfile({
    enabled: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const retrieveCurrentPanelProfileResult = useRetrieveCurrentPanelProfile({
    enabled: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const retrieveProfileResult =
    localStorage.getItem("accessType") === "panel"
      ? retrieveCurrentPanelProfileResult
      : retrieveCurrentProfileResult;

  useEffect(() => {
    if (retrieveProfileResult.isSuccess) {
      if (import.meta.env.VITE_NODE_ENV !== "production")
        console.info(
          `[Diagnostic] AuthProvider: Fetched current user info successfully`,
          retrieveProfileResult.data?.data,
        );

      userStorage.set(retrieveProfileResult.data?.data.data);
      dispatch({
        type: "UPDATE_CURRENT_USER",
        payload: retrieveProfileResult.data?.data.data,
      });
    }
  }, [retrieveProfileResult.isSuccess, retrieveProfileResult.isFetching]);

  useEffect(() => {
    if (retrieveProfileResult.isError) {
      if (import.meta.env.VITE_NODE_ENV !== "production")
        console.info(
          `[Diagnostic] AuthProvider: Occur an error when get current user: ${JSON.stringify(
            retrieveProfileResult.error,
          )}`,
        );

      dispatch({ type: "FETCH_CURRENT_USER_FAIL", payload: "UnknowError" });
    }
  }, [retrieveProfileResult.isError, retrieveProfileResult.isFetching]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === tokenStorage.accessTokenStorageKey) {
        if (event.newValue) return;

        tokenStorage.removeAccessToken();
        tokenStorage.removeRefreshToken();
        localStorage.removeItem("ks-employee-info");
        userStorage.remove();

        dispatch({ type: "LOGOUT" });

        onLogout();
      }
    });

    const { invalid, expired } = initialAccessTokenStateRef.current;

    if (invalid || expired) {
      if (import.meta.env.VITE_NODE_ENV !== "production")
        console.info(
          `[Diagnostic] AuthProvider: Logout user (if already logged in before) when access token is invalid or expried.`,
        );

      tokenStorage.removeAccessToken();
      userStorage.remove();

      dispatch({ type: "LOGOUT" });

      onLogout();
    } else {
      if (import.meta.env.VITE_NODE_ENV !== "production")
        console.info(
          `[Diagnostic] AuthProvider: User has logged in before, fetching current user info.`,
        );

      dispatch({ type: "FETCH_CURRENT_USER" });

      retrieveProfileResult.refetch();

      onAuthenticated();
    }
  }, []);

  const authenticate: IAuthContext["authenticate"] = async ({
    accessToken,
  }) => {
    tokenStorage.setAccessToken(accessToken);

    dispatch({ type: "AUTHENTICATED", payload: { accessToken } });
    dispatch({ type: "FETCH_CURRENT_USER" });

    retrieveProfileResult.refetch();

    onAuthenticated();
  };

  const refreshCurrentUser = () => {
    dispatch({ type: "FETCH_CURRENT_USER" });

    retrieveProfileResult.refetch();
  };

  const logout = async () => {
    if (import.meta.env.VITE_NODE_ENV !== "production")
      console.info(`[Diagnostic] AuthProvider: Log user out.`);

    tokenStorage.removeAccessToken();
    tokenStorage.removeRefreshToken();
    userStorage.remove();
    userStorage.removeIsFirstLogin();

    dispatch({ type: "LOGOUT" });

    onLogout();
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        authenticate,
        refreshCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
