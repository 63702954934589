import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { useAppTranslation } from "app/hooks";
import { Step } from "app/services/step";
import { FC } from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";

export interface DetailStepGeneralProps {}

const DetailStepGeneral: FC<DetailStepGeneralProps> = () => {
  const { stepText } = useAppTranslation();
  const { data: step } = useDetailPageContext<Step>();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">{stepText.detail.infomation}</h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={stepText.field.name}
                    value={step?.name}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={stepText.field.description}
                    value={step?.description}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={stepText.field.skill}
                    value={
                      <div className="d-flex gap-1 flex-wrap">
                        {step?.stepSkills?.map((skill) => (
                          <Badge pill key={skill.id} color="primary">
                            {skill.skill.name}
                          </Badge>
                        )) || "-"}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailStepGeneral;
