import { OptionSelect } from "app/services/types";
import { MultiValue, SingleValue } from "react-select";

export type WithID<T> = T & {
  id: ID;
};

export enum Source {
  Employee = "employee",
  Product = "product",
  Order = "order",
  User = "user",
  Step = "step",
}

export enum FilterByDate {
  StartDate = "START_DATE",
  EndDate = "END_DATE",
}

export type OptionValue<T = ID> =
  | SingleValue<OptionSelect<T>>
  | MultiValue<OptionSelect<T>>;
