import { errorMessageMapper } from "app/helpers";
import useToastify from "app/hooks/useToastify";
import {
  startPanelTicketErrorMessages,
  useCheckInPanelTicketMutation,
} from "app/services/ticket";

interface CheckInPanelTicketProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCheckInPanelTicket = ({
  onError: handleError,
  onSuccess: handleSuccess,
}: CheckInPanelTicketProps = {}) => {
  const toast = useToastify();
  return useCheckInPanelTicketMutation({
    onSuccess: () => {
      toast.success();
      handleSuccess?.();
    },
    onError: (error) => {
      toast.error(
        errorMessageMapper(
          startPanelTicketErrorMessages,
          error.response?.data.message,
        ),
      );
      handleError?.();
    },
  });
};
