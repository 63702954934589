import { RetrievePanelsArgs, useRetrievePanelsQuery } from "app/services/panel";

export const useRetrievePanels = (args: RetrievePanelsArgs) => {
  const result = useRetrievePanelsQuery(args);
  return {
    panels: result.data?.data.data,
    isLoadingPanels: result.isLoading,
    ...result,
  };
};
