import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { APIError, APISuccess, Response } from "../types";
import { httpClient } from "app/datasources";

export const LOGIN_URL = "/api/admin/auth/login";

export type LoginResponse = Response<{
  accessToken: string;
  refreshToken: string;
}>;

export type LoginArgs = {
  username: string;
  password: string;
  rememberMe?: boolean;
};

export enum LoginError {
  InvalidLoginInfo = "User Not Found",
  UserNotValidAccessType = "User Not Valid Access Type",
  HaveNotPermission = "HAVE_NOT_PERMISSION",
  Unauthorized = "Unauthorized",
}

export const loginErrorMessages = {
  [LoginError.InvalidLoginInfo]: "Thông tin đăng nhập không chính xác",
  [LoginError.UserNotValidAccessType]: "Thông tin đăng nhập không chính xác",
  [LoginError.HaveNotPermission]: "Bạn không có quyền truy cập",
  [LoginError.Unauthorized]: "Thông tin đăng nhập không chính xác",
};

export const login = (loginArgs: LoginArgs) => {
  return httpClient.post<LoginResponse>(LOGIN_URL, loginArgs);
};

export const useLoginMutation = (
  opts?: UseMutationOptions<APISuccess<LoginResponse>, APIError, LoginArgs>,
) => useMutation({ mutationFn: login, mutationKey: [LOGIN_URL], ...opts });
