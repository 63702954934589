import { ColumnDef } from "@tanstack/react-table";
import { AvatarRow } from "app/components/molecules/AvatarRow";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { DataTable } from "app/components/organisms/DataTable";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import {
  CheckInPanelTicketFormData,
  CheckInPanelTicketFormModal,
} from "app/modules/ticket/components";
import {
  useCheckInPanelTicket,
  useRetrievePanelTicketById,
  useStartPanelTicket,
} from "app/modules/ticket/hooks";
import { mapCheckInPanelTicket } from "app/modules/ticket/mapper";
import { Ticket } from "app/services/ticket";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailTicketPanelProps {}

const DetailTicketPanel: FC<DetailTicketPanelProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ticketText, productText, buttonText } = useAppTranslation();
  const { ticket: _ticket } = useRetrievePanelTicketById({ id: id as string });
  const { mutateAsync: _start } = useStartPanelTicket();
  const { mutateAsync: checkIn, isPending: isCheckingIn } =
    useCheckInPanelTicket();
  const { confirm } = useConfirm();
  const [isCheckInModalOpen, toggleCheckInModal] = useState(false);

  const handleToggleCheckInModal = () => {
    toggleCheckInModal((prevState) => !prevState);
  };

  const ticket = {
    id: "1",
    isDeleted: false,
    createdBy: "1",
    updatedBy: "1",
    deletedBy: null,
    createdAt: "2025-01-06T08:34:21.310Z",
    updatedAt: "2025-01-06T08:34:21.310Z",
    deletedAt: null,
    name: "Cắt ghế dàiii",
    productionOrderItemId: "1",
    productStepId: "26",
    assigneeId: "1",
    workshopId: "1",
    quantity: 12,
    remaningQuantity: 6,
    status: "Todo",
    note: "dssdf",
    productionOrderItem: {
      id: "1",
      isDeleted: false,
      createdBy: "1",
      updatedBy: "1",
      deletedBy: null,
      createdAt: "2024-12-27T04:30:39.533Z",
      updatedAt: "2024-12-27T04:30:39.533Z",
      deletedAt: null,
      productionOrderId: "1",
      planItemId: "6",
      quantity: 1,
      status: "Todo",
      note: "vvvv",
      productionOrder: {
        id: "1",
        isDeleted: false,
        createdBy: "1",
        updatedBy: "1",
        deletedBy: null,
        createdAt: "2024-12-27T04:30:39.533Z",
        updatedAt: "2024-12-27T04:30:39.533Z",
        deletedAt: null,
        name: "Plan 001",
        planId: "3",
        note: null,
        startDate: "2024-12-23T17:00:00.000Z",
        endDate: "2024-12-25T17:00:00.000Z",
        status: "Todo",
      },
      planItem: {
        id: "6",
        isDeleted: false,
        createdBy: "1",
        updatedBy: "1",
        deletedBy: null,
        createdAt: "2024-12-24T07:34:43.082Z",
        updatedAt: "2024-12-24T07:34:43.082Z",
        deletedAt: null,
        planId: "3",
        orderItemId: "23",
        quantity: 4,
        status: "Todo",
        plan: {
          id: "3",
          isDeleted: false,
          createdBy: "1",
          updatedBy: "1",
          deletedBy: null,
          createdAt: "2024-12-24T07:34:43.082Z",
          updatedAt: "2024-12-24T07:34:43.082Z",
          deletedAt: null,
          name: "Plan 13",
          description: "snssnm111",
          startDate: "2024-12-23T17:00:00.000Z",
          endDate: "2024-12-26T17:00:00.000Z",
          status: "Todo",
          approvedAt: "2025-01-08T09:10:35.620Z",
          approvedBy: "1",
        },
        orderItem: {
          id: "23",
          isDeleted: false,
          createdBy: "1",
          updatedBy: "1",
          deletedBy: null,
          createdAt: "2024-12-06T06:46:32.269Z",
          updatedAt: "2024-12-06T06:46:32.269Z",
          deletedAt: null,
          orderUUID: "7c3a78d6-d2f9-4f6c-abcf-0e7bea5001f4",
          productId: "1",
          quantity: 11,
          deliveryDate: "2024-12-15T17:00:00.000Z",
        },
        productionOrderItems: [],
        productionOrderQty: 0,
      },
    },
    assignee: {
      id: "1",
      isDeleted: false,
      createdBy: "1",
      updatedBy: "1",
      deletedBy: null,
      createdAt: "2024-10-18T09:07:07.017Z",
      updatedAt: "2024-10-18T09:07:07.017Z",
      deletedAt: "2024-10-18T09:07:07.017Z",
      employeeCode: "EMP120",
      departmentId: "44",
      jobTitleId: "8",
      firstName: "Vỏ",
      middleName: "Văn",
      lastName: "Nguyễn",
      email: "vonguyen.vn@gmail.com",
      phone: "0981343200",
      taxCode: "120012",
      nationalId: "1234567",
      gender: "male",
      birthdate: "1999-08-18T17:00:00.000Z",
      dateOfIssue: "2024-10-01T17:00:00.000Z",
      placeOfIssue: "HCMCxd",
      permanentAddress: "Ở đây addx",
      dateOfJoining: "2024-09-30T17:00:00.000Z",
      dateOfLeaving: "2024-10-02T17:00:00.000Z",
      fullName: "Vỏ Văn Nguyễn",
    },
    workshop: {
      id: "1",
      isDeleted: false,
      createdBy: "1",
      updatedBy: "1",
      deletedBy: null,
      createdAt: "2024-12-31T02:54:20.720Z",
      updatedAt: "2024-12-31T02:54:20.720Z",
      deletedAt: null,
      name: "Xưởng Sắt",
      description: "Xưởng Sắt",
      address: "47 Trần Huy Liệu",
    },
    productStep: {
      id: "26",
      isDeleted: false,
      createdBy: "1",
      updatedBy: "1",
      deletedBy: null,
      createdAt: "2024-12-31T03:35:23.428Z",
      updatedAt: "2024-12-31T03:35:23.428Z",
      deletedAt: null,
      productId: "1",
      stepId: "52",
      estimateTime: 12,
      product: {
        id: "1",
        isDeleted: false,
        createdBy: "1",
        updatedBy: "1",
        deletedBy: null,
        createdAt: "2024-10-24T13:35:25.415Z",
        updatedAt: "2024-10-24T13:35:25.415Z",
        deletedAt: null,
        isActive: true,
        code: "SP001",
        nameEn: "Long chair",
        nameVn: "Ghế dàiiiiiii",
        typeId: "26",
        groupId: "41",
        unitId: "31",
        countryOfOrigin: "Việt Nam",
        description: "Ghế dài 3m",
        lengthCm: 300,
        widthCm: 20,
        heightCm: 20,
        seatSizeCm: "80cm x 80cm",
        weightKg: 90,
        color: "Đỏ",
        packageLengthCm: 305,
        packageWidthCm: 23,
        packageHeightCm: 25,
        packageBoxCount: 4,
        productionTime: null,
        productImage: {
          id: "130",
          isDeleted: false,
          createdBy: "1",
          updatedBy: null,
          deletedBy: null,
          createdAt: "2025-01-08T07:43:57.890Z",
          updatedAt: "2025-01-08T07:43:57.890Z",
          deletedAt: null,
          fileName: "z5390977908005_7c6ec971d1955c4e4da2dd73db061097.jpg",
          filePath:
            "product/2025-01-08-fc47938f-d39f-437d-bef8-011d3664493e.jpg",
          fileType: "image",
          moduleType: "product",
          isUploaded: false,
          attachmentUrl:
            "https://staging.api.kingstonvn.co/api/admin/attachments/download?filePath=product%2F2025-01-08-fc47938f-d39f-437d-bef8-011d3664493e.jpg&signed=4f56482cf4a9a372950f1f44e51ef8be3cb7cd17a460289113845c33e1055025",
        },
      },
      step: {
        id: "52",
        isDeleted: false,
        createdBy: "1",
        updatedBy: null,
        deletedBy: null,
        createdAt: "2024-12-18T07:30:17.216Z",
        updatedAt: "2024-12-18T07:30:17.216Z",
        deletedAt: null,
        type: "product_step",
        name: "Cắt",
        description: "Cắt",
        colorCode: "#d0021b",
      },
    },
    product: {
      id: "1",
      isDeleted: false,
      createdBy: "1",
      updatedBy: "1",
      deletedBy: null,
      createdAt: "2024-10-24T13:35:25.415Z",
      updatedAt: "2024-10-24T13:35:25.415Z",
      deletedAt: null,
      isActive: true,
      code: "SP001",
      nameEn: "Long chair",
      nameVn: "Ghế dàiiiiiii",
      typeId: "26",
      groupId: "41",
      unitId: "31",
      countryOfOrigin: "Việt Nam",
      description: "Ghế dài 3m",
      lengthCm: 300,
      widthCm: 20,
      heightCm: 20,
      seatSizeCm: "80cm x 80cm",
      weightKg: 90,
      color: "Đỏ",
      packageLengthCm: 305,
      packageWidthCm: 23,
      packageHeightCm: 25,
      packageBoxCount: 4,
      productionTime: null,
    },
  };

  const columns: ColumnDef<Ticket>[] = useMemo(
    () => [
      {
        header: ticketText.field.product,
        accessorKey: "product",
        cell: ({ row }) => row.original.product?.nameVn || "-",
      },
      {
        header: ticketText.field.quantity,
        accessorKey: "quantity",
        cell: ({ row }) => row.original.quantity || "-",
      },
      {
        header: ticketText.field.createdAt,
        accessorKey: "time",
        cell: ({ row }) =>
          row.original.createdAt ? formatDate(row.original.createdAt) : "-",
      },
    ],
    [ticketText],
  );

  const handleStart = () => {
    confirm({
      icon: null,
      severity: "success",
      title: ticketText.startTitle,
      description: ticketText.startDescription,
    });
  };

  const handleCheckIn = ({ note, quantity }: CheckInPanelTicketFormData) => {
    checkIn(
      mapCheckInPanelTicket({
        employeeId: "",
        ticketId: id as ID,
        quantity,
        note,
      }),
    );
  };

  return (
    <div className="p-3">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center gap-2 mb-3">
              <Button
                color=""
                className="btn-icon"
                style={{ height: 18, width: 18 }}
                onClick={() => navigate(-1)}
              >
                <i className="ri-arrow-left-s-line fs-24" />
              </Button>
              <h5 className="mb-sm-0">{ticketText.detail.title}</h5>
            </div>
            <div className="d-flex justify-content-end mt-3 gap-2">
              <Button color="primary" onClick={handleStart}>
                {buttonText.start}
              </Button>
              <Button color="primary" onClick={handleToggleCheckInModal}>
                {buttonText.checkIn}
              </Button>
            </div>
          </div>
          <Row>
            <Col xs={6}>
              <ListInfoItem
                label={productText.field.avatar}
                value={
                  <AvatarRow
                    avatarUrl={
                      ticket.productStep.product.productImage?.attachmentUrl
                    }
                    size="md"
                    isClickable
                  />
                }
              />
              <ListInfoItem
                label={productText.field.name}
                value={ticket.productStep.product.nameVn}
              />
              <ListInfoItem
                label={productText.field.productStep}
                value={ticket.productStep.step.name}
              />
              <ListInfoItem
                label={ticketText.field.quantity}
                value={ticket.quantity}
              />
              <ListInfoItem label={ticketText.field.note} value={ticket.note} />
            </Col>
            <Col xs={6}>
              <Col xs={12}>
                <ListInfoItem
                  label={productText.field.lengthCm}
                  value={ticket?.productStep.product?.lengthCm}
                />
              </Col>
              <Col xs={12}>
                <ListInfoItem
                  label={productText.field.widthCm}
                  value={ticket?.productStep.product?.widthCm}
                />
              </Col>
              <Col xs={12}>
                <ListInfoItem
                  label={productText.field.heightCm}
                  value={ticket?.productStep.product?.heightCm}
                />
              </Col>
              <Col xs={12}>
                <ListInfoItem
                  label={productText.field.weightKg}
                  value={ticket?.productStep.product?.weightKg}
                />
              </Col>
              <Col xs={12}>
                <ListInfoItem
                  label={productText.field.color}
                  value={ticket?.productStep.product?.color}
                />
              </Col>
            </Col>
          </Row>
          <hr />
          <h6 className="mb-sm-0">Lịch sử thực hiện</h6>
          <DataTable<Ticket>
            columns={columns}
            data={[]}
            loading={false}
            total={0}
            isHiddenPagination
          />
          <CheckInPanelTicketFormModal
            open={isCheckInModalOpen}
            onToggle={handleToggleCheckInModal}
            onSubmit={handleCheckIn}
            submitting={isCheckingIn}
            defaultValues={{
              quantity: null,
              note: "",
              totalQuantity: ticket.quantity,
              remainingQuantity: ticket.remaningQuantity,
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailTicketPanel;
