import {
  RetrievePanelTicketsArgs,
  useRetrievePanelTicketsQuery,
} from "app/services/ticket";

export const useRetrievePanelTickets = (args: RetrievePanelTicketsArgs) => {
  const result = useRetrievePanelTicketsQuery(args);
  return {
    tickets: result.data?.data.data,
    isLoadingPanelTickets: result.isLoading,
    ...result,
  };
};
