import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { DateInput } from "app/components/molecules/DateInput";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { FileUpload } from "app/components/organisms/FileUpload/FileUpload";
import { intl } from "app/helpers";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation } from "app/hooks";
import { FilePreview } from "app/models";
import { CustomerSelect } from "app/modules/customer/components";
import { ProductSelect } from "app/modules/product/components";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, FormGroup, Row, Table } from "reactstrap";
import * as Yup from "yup";

export interface OrderFormProps {
  defaultValues?: OrderFormData;
  onSubmit: (data: OrderFormData) => void;
  submitting?: boolean;
  isDuplicate?: boolean;
}

export interface OrderItemFormData {
  products: OptionSelect<ID> | null;
  quantity: number | null;
  expectedDeliveryDate: Date | null;
  id?: ID | null;
}

export interface OrderFormData {
  orderCode: string;
  orderDate: Date | null;
  customer: OptionSelect<ID> | null;
  expectedDeliveryDate: Date | null;
  notes: string;
  orderItems: OrderItemFormData[];
  images: FilePreview[];
  documents: FilePreview[];
}

export const OrderForm: FC<OrderFormProps> = ({
  onSubmit,
  defaultValues,
  submitting,
  isDuplicate = false,
}) => {
  const { orderText, buttonText } = useAppTranslation();
  const schema = Yup.object({
    orderCode: yupSchema.stringNotRequired(),
    orderDate: yupSchema.dateRequired("order.error.orderDateRequired"),
    customer: yupSchema.objectRequired("order.error.customerRequired"),
    expectedDeliveryDate: yupSchema.dateMoreThanOrEqualRequired(
      "orderDate",
      "order.error.expectedDeliveryDateRequired",
      "order.error.expectedDeliveryDateInvalid",
    ),
    notes: yupSchema.stringNotRequired(),
    orderItems: Yup.array()
      .of(
        Yup.object({
          products: yupSchema.objectRequired("order.error.productRequired"),
          quantity: yupSchema.numberMoreThanRequired(
            0,
            "order.error.quantityMoreThanZero",
            "order.error.quantityRequired",
          ),
          expectedDeliveryDate: yupSchema.dateRequired(
            "order.error.expectedDeliveryDateRequired",
          ),
        }),
      )
      .defined(),
    images: yupSchema.fileNotRequired(),
    documents: yupSchema.fileNotRequired(),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm<OrderFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "orderItems",
  });

  const watchOrderItems = watch("orderItems");
  const orderItemsField = fields.map((field, index) => {
    return {
      ...field,
      ...watchOrderItems[index],
    };
  });

  const isEnableSubmit = useMemo(
    () => (isDuplicate ? isValid : isDirty && isValid && !submitting),
    [isDirty, isValid, submitting, isDuplicate],
  );

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const watchOrderDate = watch("orderDate");

  return (
    <Form onSubmit={submit} id="order-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="orderCode" className="form-label">
              {orderText.field.orderCode}
            </FormLabel>
            <Controller
              name="orderCode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="orderCode"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="customer" className="form-label">
              {orderText.field.customer}
            </FormLabel>
            <Controller
              name="customer"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <CustomerSelect
                    name="customer"
                    value={field.value}
                    inputId="customer"
                    onChange={field.onChange}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="orderDate" className="form-label">
              {orderText.field.orderDate}
            </FormLabel>
            <Controller
              name="orderDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="orderDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                      setValue("expectedDeliveryDate", null, {
                        shouldValidate: dirtyFields.expectedDeliveryDate
                          ? true
                          : false,
                      });
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel
              required
              htmlFor="expectedDeliveryDate"
              className="form-label"
            >
              {orderText.field.expectedDeliveryDate}
            </FormLabel>
            <Controller
              name="expectedDeliveryDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    name={field.name}
                    id="expectedDeliveryDate"
                    value={field.value || ""}
                    onChange={([date]) => {
                      field.onChange(date);
                    }}
                    options={{
                      minDate: watchOrderDate
                        ? intl.formatDate(new Date(watchOrderDate))
                        : undefined,
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <FormLabel className="form-label">
          {orderText.field.productInfomation}
        </FormLabel>
        <Col lg={12}>
          {orderItemsField.map((field, index) => (
            <Card key={field.id}>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <FormLabel htmlFor="notes" className="form-label">
                            {orderText.field.product}
                          </FormLabel>
                        </Col>
                        <Col>
                          <div className="text-end">
                            <i
                              className="ri-delete-bin-line fs-18 cursor-pointer lh-1"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Controller
                        name={`orderItems.${index}.products` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <ProductSelect
                              name={field.name}
                              value={field.value}
                              inputId={`orderItems.${index}.products`}
                              onChange={field.onChange}
                              isInvalid={fieldState.invalid}
                            />
                            <FormHelper message={fieldState.error?.message} />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} sx={12}>
                    <FormGroup>
                      <FormLabel
                        required
                        htmlFor={`orderItems.${index}.quantity`}
                        className="form-label"
                      >
                        {orderText.field.quantity}
                      </FormLabel>
                      <Controller
                        name={`orderItems.${index}.quantity` as const}
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <FormInput
                              {...field}
                              value={field.value ?? ""}
                              onChange={(value) => {
                                field.onChange(
                                  value.target.value === ""
                                    ? null
                                    : Number(value.target.value),
                                );
                              }}
                              type="number"
                              className="form-control"
                              id={`orderItems.${index}.quantity`}
                              invalid={!!fieldState.invalid}
                            />
                            <FormHelper message={fieldState.error?.message} />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel
                        required
                        htmlFor={`orderItems.${index}.expectedDeliveryDate`}
                        className="form-label"
                      >
                        {orderText.field.expectedDeliveryDate}
                      </FormLabel>
                      <Controller
                        name={
                          `orderItems.${index}.expectedDeliveryDate` as const
                        }
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <DateInput
                              name={field.name}
                              id={`orderItems.${index}.expectedDeliveryDate`}
                              value={field.value || ""}
                              onChange={([date]) => {
                                field.onChange(date || null);
                              }}
                              isInvalid={fieldState.invalid}
                            />
                            <FormHelper message={fieldState.error?.message} />
                          </>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
          <Button
            color="primary"
            size="sm"
            className="mb-4"
            onClick={() =>
              append({
                products: null,
                quantity: null,
                expectedDeliveryDate: null,
              })
            }
          >
            {buttonText.add}
          </Button>
        </Col>
        {orderItemsField.length > 0 && (
          <Col xs={12}>
            <Table hover>
              <thead>
                <tr>
                  <th>{orderText.field.product}</th>
                  <th>{orderText.field.quantity}</th>
                  <th>{orderText.field.expectedDeliveryDate}</th>
                </tr>
              </thead>
              <tbody>
                {orderItemsField.map((field) => (
                  <tr key={field.id}>
                    <td>{field.products?.label || "-"}</td>
                    <td>{field.quantity || "-"}</td>
                    <td>
                      {field.expectedDeliveryDate
                        ? intl.formatDate(field.expectedDeliveryDate)
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="notes" className="form-label">
              {orderText.field.notes}
            </FormLabel>
            <Controller
              name="notes"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="textarea"
                    className="form-control"
                    id="notes"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="images" className="form-label">
              {orderText.field.image}
            </FormLabel>
            <Controller
              name="images"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="documents" className="form-label">
              {orderText.field.document}
            </FormLabel>
            <Controller
              name="documents"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FileUpload
                    value={field.value || []}
                    onChange={field.onChange}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
