import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { mapDefaultUser } from "../../mapper";
import { PanelUserForm, PanelUserFormData } from "./PanelUserForm";

export interface PanelUserFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: PanelUserFormData) => void;
  submitting?: boolean;
}

export const PanelUserFormModal: FC<PanelUserFormModalProps> = ({
  onToggle,
  open = false,
  onSubmit,
  submitting,
}) => {
  const { panelText } = useAppTranslation();
  const title = panelText.addAccount;

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <PanelUserForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          defaultValues={mapDefaultUser()}
          submitting={submitting}
        />
      </ModalBody>
    </Modal>
  );
};
