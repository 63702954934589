import { useAppTranslation } from "app/hooks";
import { useRetrievePanelById } from "app/modules/panel/hooks";
import { Panel } from "app/services/panel";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { mapDefaultPanel } from "../../mapper";
import { PanelForm, PanelFormData } from "./PanelForm";

export interface PanelFormModalProps {
  data?: Panel;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: PanelFormData) => void;
  submitting?: boolean;
  edit?: boolean;
  defaultValues?: PanelFormData;
  isQuickAdd?: boolean;
}

export const PanelFormModal: FC<PanelFormModalProps> = ({
  data,
  onToggle,
  open = false,
  onSubmit,
  submitting,
  edit = false,
  defaultValues,
}) => {
  const { panelText } = useAppTranslation();
  const title = edit ? panelText.edit : panelText.add;
  const { panel, isLoading } = useRetrievePanelById(
    {
      id: data?.id as string,
    },
    { enabled: edit && !!data?.id },
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoading ? (
          <PanelForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={defaultValues || mapDefaultPanel(panel)}
            submitting={submitting}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
