import { Employee } from "../employee";
import { Panel } from "../panel";
import { Role } from "../role";
import { IBaseModel } from "../types";
export interface User extends IBaseModel {
  username: ID;
  password: string;
  employee?: Employee;
  panel?: Panel;
  role: Role;
  status: 1;
  accessType: AccessTypeUser;
}
export interface PanelUser extends IBaseModel {
  username: ID;
  password: string;
  status: 1;
}

export enum AccessTypeUser {
  Admin = "admin",
  Panel = "panel",
}
