import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { ProductionPlan } from "./types";

export const APPROVE_PRODUCTION_PLAN_URL = (id: ID) =>
  `/api/admin/production-plans/${id}/approve`;

export enum ApproveProductionPlanError {}

export const approveProductionPlanErrorMessages = {};

export type ApproveProductionPlanResponse = Response<ProductionPlan>;

export type ApproveProductionPlanArgs = {
  id: ID;
};

export const approveProductionPlan = ({ id }: ApproveProductionPlanArgs) => {
  return httpClient.put<ApproveProductionPlanResponse>(
    APPROVE_PRODUCTION_PLAN_URL(id),
  );
};

export const useApproveProductionPlanMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<ApproveProductionPlanResponse>,
      AxiosError<any>,
      ApproveProductionPlanArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<ApproveProductionPlanResponse>,
    AxiosError<any>,
    ApproveProductionPlanArgs
  >({
    mutationFn: approveProductionPlan,
    mutationKey: [APPROVE_PRODUCTION_PLAN_URL],
    ...opts,
  });
