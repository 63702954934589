import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "app/components/molecules/Button";
import { FormHelper } from "app/components/molecules/FormHelper";
import { FormInput } from "app/components/molecules/FormInput";
import { FormLabel } from "app/components/molecules/FormLabel";
import { yupSchema } from "app/helpers/schema";
import { useAppTranslation, useGetEnableSubmitButton } from "app/hooks";
import {
  ComponentSelect,
  ProductStepSelect,
} from "app/modules/product/components";
import { ProductionOrderItemSelect } from "app/modules/productionOrder/components/ProductionOrderItemSelect";
import { WorkshopSelect } from "app/modules/workshop/components";
import { OptionSelect } from "app/services/types";
import { FC, FormEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

export interface TicketProductionOrderItemFormProps {
  defaultValues?: TicketProductionOrderItemFormData;
  onSubmit: (data: TicketProductionOrderItemFormData) => void;
  submitting?: boolean;
  onToggle: () => void;
}

export interface TicketProductionOrderItemFormData {
  productionOrderItem: (OptionSelect & { product?: ID | null }) | null;
  component: OptionSelect | null;
  productStep: OptionSelect | null;
  workshop: OptionSelect | null;
  quantity: number | null;
  note: string;
}

export const TicketProductionOrderItemForm: FC<
  TicketProductionOrderItemFormProps
> = ({ onSubmit, defaultValues, submitting, onToggle }) => {
  const { ticketText, buttonText } = useAppTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isDirty, isValid },
  } = useForm<TicketProductionOrderItemFormData>({
    mode: "onChange",
    resolver: yupResolver(
      Yup.object({
        productionOrderItem: yupSchema.objectRequired(
          "ticket.error.productionOrderItemRequired",
        ),
        component: yupSchema.objectRequired("ticket.error.componentRequired"),
        productStep: yupSchema.objectRequired(
          "ticket.error.productStepRequired",
        ),
        workshop: yupSchema.objectRequired("ticket.error.workshopRequired"),
        quantity: yupSchema.numberMoreThanRequired(
          0,
          "order.error.quantityMoreThanZero",
          "order.error.quantityRequired",
        ),
        note: yupSchema.stringNotRequired(),
      }),
    ),
    defaultValues,
  });

  const watchProductionOrderItem = watch("productionOrderItem");
  const watchComponent = watch("component");

  const isEnableSubmit = useGetEnableSubmitButton({
    isDirty,
    isValid,
    submitting,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <Form onSubmit={submit} id="ticket-form">
      <Row>
        <Col xs={12}>
          <FormGroup>
            <FormLabel
              required
              htmlFor="productionOrderItem"
              className="form-label"
            >
              {ticketText.field.productionOrderItem}
            </FormLabel>
            <Controller
              name="productionOrderItem"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ProductionOrderItemSelect
                    isDisabled
                    name={field.name}
                    value={field.value}
                    inputId="productionOrderItem"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="component" className="form-label">
              {ticketText.field.component}
            </FormLabel>
            <Controller
              name="component"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <ComponentSelect
                    id={watchProductionOrderItem?.product || undefined}
                    name={field.name}
                    value={field.value}
                    inputId="component"
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("productStep", null);
                      clearErrors("productStep");
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        {watchComponent && (
          <Col xs={12}>
            <FormGroup>
              <FormLabel required htmlFor="productStep" className="form-label">
                {ticketText.field.productStep}
              </FormLabel>
              <Controller
                name="productStep"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <ProductStepSelect
                      id={watchComponent?.value}
                      name={field.name}
                      value={field.value}
                      inputId="productStep"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      isInvalid={fieldState.invalid}
                    />
                    <FormHelper message={fieldState.error?.message} />
                  </>
                )}
              />
            </FormGroup>
          </Col>
        )}
        <Col xs={12}>
          <FormGroup>
            <FormLabel required htmlFor="workshop" className="form-label">
              {ticketText.field.workshop}
            </FormLabel>
            <Controller
              name="workshop"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <WorkshopSelect
                    name={field.name}
                    value={field.value}
                    inputId="workshop"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isInvalid={fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col sx={12}>
          <FormGroup>
            <FormLabel required htmlFor={`quantity`} className="form-label">
              {ticketText.field.quantity}
            </FormLabel>
            <Controller
              name={`quantity` as const}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    value={field.value ?? ""}
                    onChange={(value) => {
                      field.onChange(
                        value.target.value === ""
                          ? null
                          : Number(value.target.value),
                      );
                    }}
                    type="number"
                    className="form-control"
                    id={`quantity`}
                    invalid={!!fieldState.invalid}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <FormLabel htmlFor="note" className="form-label">
              {ticketText.field.note}
            </FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormInput
                    {...field}
                    type="text"
                    className="form-control"
                    id="note"
                    invalid={!!fieldState.error}
                  />
                  <FormHelper message={fieldState.error?.message} />
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={onToggle}>
              {buttonText.cancel}
            </Button>
            <Button
              disabled={!isEnableSubmit}
              type="submit"
              color="primary"
              loading={submitting}
            >
              {buttonText.save}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
