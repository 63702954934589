import { FileDTO, FilePreview, WithID } from "app/models";
import { CreateOrderArgs, Order, UpdateOrderArgs } from "app/services/order";
import { CommonProperties } from "app/services/types";
import { OrderFormData } from "./components";

export const mapper = ({
  customer,
  expectedDeliveryDate,
  notes,
  orderCode,
  orderDate,
  orderItems,
}: OrderFormData): CommonProperties<CreateOrderArgs, UpdateOrderArgs> => {
  return {
    orderCode: orderCode || null,
    orderDate: orderDate?.toISOString() as DateTimeString,
    customerId: customer?.value as string,
    expectedDeliveryDate: expectedDeliveryDate?.toISOString() as DateTimeString,
    notes: notes || null,
    orderItems:
      orderItems.map((item) => ({
        productId: item.products?.value as string,
        quantity: item.quantity as number,
        deliveryDate:
          item.expectedDeliveryDate?.toISOString() as DateTimeString,
        id: item.id || undefined,
      })) || [],
  };
};

export const mapCreateOrder = (
  data: OrderFormData & { files?: ID[] },
): CreateOrderArgs => {
  return {
    fileIds: data.files,
    ...mapper(data),
  };
};

export const mapUpdateOrder = ({
  id,
  deleteFileIds,
  ...data
}: WithID<
  OrderFormData & { files?: ID[]; deleteFileIds?: ID[] }
>): UpdateOrderArgs => {
  return { id, deleteFileIds, addFileIds: data.files, ...mapper(data) };
};

export const mapDefaultOrder = (
  {
    expectedDeliveryDate,
    customer,
    notes,
    orderCode,
    orderDate,
    orderItems,
    images,
    documents,
  }: Partial<Order> & { images?: FileDTO[]; documents?: FileDTO[] } = {},
  isDuplicate?: boolean,
): OrderFormData => {
  return {
    orderCode: orderCode || "",
    orderDate: orderDate ? new Date(orderDate) : null,
    customer: customer
      ? { label: customer.customerName, value: customer.id }
      : null,
    expectedDeliveryDate: expectedDeliveryDate
      ? new Date(expectedDeliveryDate)
      : null,
    notes: notes || "",
    orderItems:
      orderItems?.map((item) =>
        isDuplicate
          ? {
              products: item.product
                ? {
                    label: `${item.product.nameVn} - ${item.product.code}`,
                    value: item.product.id,
                  }
                : null,
              quantity: item.quantity || null,
              expectedDeliveryDate: item.deliveryDate
                ? new Date(item.deliveryDate)
                : null,
            }
          : {
              products: item.product
                ? {
                    label: `${item.product.nameVn} - ${item.product.code}`,
                    value: item.product.id,
                  }
                : null,
              quantity: item.quantity || null,
              expectedDeliveryDate: item.deliveryDate
                ? new Date(item.deliveryDate)
                : null,
              id: item.id || null,
            },
      ) || [],
    images:
      images?.map(
        (image) =>
          ({
            id: image?.id || "",
            preview: image?.attachmentUrl,
            name: image?.fileName,
          }) as FilePreview,
      ) || [],
    documents:
      documents?.map(
        (document) =>
          ({
            id: document?.id || "",
            preview: document?.attachmentUrl,
            name: document?.fileName,
          }) as FilePreview,
      ) || [],
  };
};
