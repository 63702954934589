import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Workshop } from "./types";

export const DELETE_WORKSHOP_URL = (id: ID) => `/api/admin/workshops/${id}`;

export enum DeleteWorkshopError {}

export const deletedWorkshopErrorMessages = {};

export type DeleteWorkshopResponse = Response<Workshop>;

export type DeleteWorkshopArgs = {
  id: ID;
};

export const deleteWorkshop = ({ id }: DeleteWorkshopArgs) => {
  return httpClient.delete<DeleteWorkshopResponse>(DELETE_WORKSHOP_URL(id));
};

export const useDeleteWorkshopMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteWorkshopResponse>,
    AxiosError<Response<null>>,
    DeleteWorkshopArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_WORKSHOP_URL],
    mutationFn: (deletedWorkshopArgs: DeleteWorkshopArgs) =>
      deleteWorkshop(deletedWorkshopArgs),
    ...opts,
  });
};
