import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { User } from "./types";

export const DEACTIVE_USER_URL = (id: ID) => `/api/admin/users/${id}/deactive`;

export enum DeactiveUserError {}

export const deactiveUserErrorMessages = {};

export interface DeactiveUserArgs {
  id: ID;
}

export type DeactiveUserResponse = Response<User>;

export const deactiveUser = ({ id }: DeactiveUserArgs) => {
  return httpClient.post<DeactiveUserResponse>(DEACTIVE_USER_URL(id));
};

export const useDeactiveUserMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<DeactiveUserResponse>,
      AxiosError<any>,
      DeactiveUserArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<DeactiveUserResponse>,
    AxiosError<any>,
    DeactiveUserArgs
  >({
    mutationFn: deactiveUser,
    mutationKey: [DEACTIVE_USER_URL],
    ...opts,
  });
