import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DataTable } from "app/components/organisms/DataTable";
import ListPage from "app/components/templates/ListPage";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import {
  ProductionPlanAction,
  ProductionPlanActionDropdown,
} from "app/modules/productionPlan/components";
import { productionPlanStatusMappers } from "app/modules/productionPlan/constants";
import {
  useDeleteProductionPlan,
  useRetrieveProductionPlans,
} from "app/modules/productionPlan/hooks";
import { useApproveProductionPlan } from "app/modules/productionPlan/hooks/useApproveProductionPlan";
import {
  ProductionPlan,
  ProductionPlanStatus,
} from "app/services/productionPlan";
import { OptionSelect } from "app/services/types";
import BreadCrumb from "Components/Common/BreadCrumb";
import "gridjs/dist/theme/mermaid.css";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ListProductionPlanFilter } from "./ListProductionPlanFilter";
import { ListProductionPlanFilterChips } from "./ListProductionPlanFilterChips";

interface ListProductionPlanProps {}

const ListProductionPlan: FC<ListProductionPlanProps> = () => {
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { productionPlanText, confirmText, buttonText } = useAppTranslation();

  const {
    page,
    limit,
    searchString,
    setPage,
    setLimit,
    setSearchString,
    customParams,
    setCustomParams,
  } = useAppSearch<{
    status: OptionSelect<ProductionPlanStatus>[];
    startDate: Date | null;
    endDate: Date | null;
  }>({
    initialPage: 1,
    initialLimit: 20,
    initialSearchString: "",
    initialCustomParams: {
      status: [],
      startDate: null,
      endDate: null,
    },
  });

  const handleClearFilter = () => {
    setCustomParams({
      status: [],
      startDate: null,
      endDate: null,
    });
  };

  const { productionPlans, isLoadingProductionPlans } =
    useRetrieveProductionPlans({
      limit,
      page,
      searchString: searchString || undefined,
      status: customParams.status.map((item) => item.value),
      startDate: customParams.startDate?.toISOString(),
      endDate: customParams.endDate?.toISOString(),
    });

  const { mutateAsync: deleteProductionPlan } = useDeleteProductionPlan();

  const { mutateAsync: approve } = useApproveProductionPlan();

  const handleActionClick = (
    action: ProductionPlanAction,
    data?: ProductionPlan,
  ) => {
    switch (action) {
      case "edit":
        if (data) {
          navigate(`/production-plan/${data.id}/update`);
        }
        break;
      case "approve":
        confirm({
          title: confirmText.approveTitle(productionPlanText.title),
          description: confirmText.approveContent(productionPlanText.title),
          severity: "success",
          confirmText: buttonText.approve,
          onConfirm: async () => {
            await approve({ id: data?.id as ID });
          },
        });
        break;
      case "detail":
        if (data) {
          navigate(`/production-plan/${data.id}`);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(productionPlanText.title),
            description: confirmText.deleteContent(productionPlanText.title),
            onConfirm: () => deleteProductionPlan({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<ProductionPlan>[] = useMemo(
    () => [
      {
        header: productionPlanText.field.name,
        accessorKey: "name",
      },
      {
        header: productionPlanText.field.status,
        accessorKey: "status",
        cell: ({ row }) => productionPlanStatusMappers[row.original.status],
      },
      {
        header: productionPlanText.field.startDate,
        accessorKey: "startDate",
        cell: ({ row }) =>
          new Date(row.original.startDate).toLocaleDateString(),
      },
      {
        header: productionPlanText.field.endDate,
        accessorKey: "endDate",
        cell: ({ row }) => new Date(row.original.endDate).toLocaleDateString(),
      },
      {
        header: productionPlanText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <ProductionPlanActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [productionPlanText],
  );

  return (
    <React.Fragment>
      <ListPage>
        <ListPage.BreadCrumb>
          <BreadCrumb title={productionPlanText.title} pageTitle="Kingston" />
        </ListPage.BreadCrumb>
        <ListPage.Filter>
          <Row>
            <Col xs={6}>
              <ListProductionPlanFilter
                searchString={searchString}
                handleSearchChange={setSearchString}
                filterParams={{
                  status: customParams.status,
                  startDate: customParams.startDate,
                  endDate: customParams.endDate,
                }}
                setCustomParams={setCustomParams}
              />
            </Col>
            <Col xs={6} className="text-end">
              <Button
                color="primary"
                size="sm"
                onClick={() => navigate("/production-plan/new")}
              >
                {productionPlanText.add}
              </Button>
            </Col>
            <Col xs={12}>
              <ListProductionPlanFilterChips
                filterParams={{
                  status: customParams.status,
                  startDate: customParams.startDate,
                  endDate: customParams.endDate,
                }}
                setCustomParams={setCustomParams}
                handleClearFilter={handleClearFilter}
              />
            </Col>
          </Row>
        </ListPage.Filter>
        <ListPage.Main>
          <DataTable<ProductionPlan>
            columns={columns}
            data={productionPlans?.result || []}
            loading={isLoadingProductionPlans}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            total={productionPlans?.total || 0}
            onRowClick={(row) => handleActionClick("detail", row)}
          />
        </ListPage.Main>
      </ListPage>
    </React.Fragment>
  );
};

export default ListProductionPlan;
