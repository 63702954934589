import {
  RetrieveProductionPlansArgs,
  useRetrieveProductionPlansQuery,
} from "app/services/productionPlan";

export const useRetrieveProductionPlans = (
  args: RetrieveProductionPlansArgs,
) => {
  const result = useRetrieveProductionPlansQuery(args);
  return {
    productionPlans: result.data?.data.data,
    isLoadingProductionPlans: result.isLoading,
    ...result,
  };
};
