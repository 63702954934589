import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { TicketLog } from "./types";

export const DELETE_TICKET_LOG_URL = (id: ID) => `/api/admin/ticket-logs/${id}`;

export enum DeleteTicketLogError {}

export const deletedTicketLogErrorMessages = {};

export type DeleteTicketLogResponse = Response<TicketLog>;

export type DeleteTicketLogArgs = {
  id: ID;
};

export const deleteTicketLog = ({ id }: DeleteTicketLogArgs) => {
  return httpClient.delete<DeleteTicketLogResponse>(DELETE_TICKET_LOG_URL(id));
};

export const useDeleteTicketLogMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteTicketLogResponse>,
    AxiosError<Response<null>>,
    DeleteTicketLogArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_TICKET_LOG_URL],
    mutationFn: (deletedTicketLogArgs: DeleteTicketLogArgs) =>
      deleteTicketLog(deletedTicketLogArgs),
    ...opts,
  });
};
