import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Product } from "./types";
import { Response } from "../types";

export const RETRIEVE_COMPONENTS_BY_PRODUCTION_ORDER_ID_URL = (id: ID) =>
  `/api/admin/products/${id}/components`;

export type RetrieveComponentsProductionOrderIdResponse = Response<Product[]>;

export type RetrieveComponentsProductionOrderIdArgs = {
  id: ID;
};

export const retrieveComponentsProductionOrderId = ({
  id,
}: RetrieveComponentsProductionOrderIdArgs) => {
  return httpClient.get<RetrieveComponentsProductionOrderIdResponse>(
    RETRIEVE_COMPONENTS_BY_PRODUCTION_ORDER_ID_URL(id),
  );
};

export const useRetrieveComponentsProductionOrderIdQuery = (
  retrieveProductionOrdersArgs: RetrieveComponentsProductionOrderIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveComponentsProductionOrderIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<
    AxiosResponse<RetrieveComponentsProductionOrderIdResponse>,
    AxiosError<any>
  >({
    queryKey: [
      RETRIEVE_COMPONENTS_BY_PRODUCTION_ORDER_ID_URL(
        retrieveProductionOrdersArgs.id,
      ),
    ],
    queryFn: () =>
      retrieveComponentsProductionOrderId(retrieveProductionOrdersArgs),
    ...opts,
  });
