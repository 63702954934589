import { FilePreview, WithID } from "app/models";
import {
  CreateProductArgs,
  Product,
  UpdateProductArgs,
} from "app/services/product";
import { CommonProperties } from "app/services/types";
import { ProductFormData } from "./components";

export const mapper = ({
  code,
  countryOfOrigin,
  description,
  group,
  heightCm,
  lengthCm,
  nameEn,
  nameVn,
  seatSizeCm,
  type,
  unit,
  weightKg,
  widthCm,
  boxQuantity,
  color,
  packagingHeightCm,
  packagingLengthCm,
  packagingWidthCm,
  imageId,
  productSteps,
  productRelations,
}: ProductFormData & { imageId?: ID }): CommonProperties<
  CreateProductArgs,
  UpdateProductArgs
> => {
  return {
    code,
    nameEn: nameEn || null,
    nameVn,
    countryOfOrigin,
    description: description || null,
    groupId: group?.value || null,
    unitId: unit?.value as ID,
    typeId: type?.value || null,
    heightCm: heightCm || null,
    lengthCm: lengthCm || null,
    seatSizeCm: seatSizeCm || null,
    weightKg: weightKg || null,
    widthCm: widthCm || null,
    packageBoxCount: boxQuantity || null,
    color: color || null,
    packageHeightCm: packagingHeightCm || null,
    packageLengthCm: packagingLengthCm || null,
    packageWidthCm: packagingWidthCm || null,
    imageId: imageId || null,
    productSteps: productSteps.map((step) => ({
      stepId: step.step?.value as ID,
      id: step.id || undefined,
      estimateTime: step.estimateTime || null,
    })),
    productRelations:
      productRelations?.map((item) => ({
        componentId: item.products?.value as ID,
        quantity: item.quantity as number,
        description: item.description || "",
        id: item.id || undefined,
      })) || [],
  };
};

export const mapCreateProduct = (
  data: ProductFormData & { imageId?: ID },
): CreateProductArgs => {
  return mapper(data);
};

export const mapUpdateProduct = ({
  id,
  ...data
}: WithID<ProductFormData> & { imageId?: ID }): UpdateProductArgs => {
  return { id, ...mapper(data) };
};

export const mapDefaultProduct = ({
  code,
  countryOfOrigin,
  description,
  productGroup,
  heightCm,
  lengthCm,
  nameEn,
  nameVn,
  seatSizeCm,
  productType,
  productUnit,
  weightKg,
  widthCm,
  packageBoxCount,
  color,
  packageHeightCm,
  packageLengthCm,
  packageWidthCm,
  productImage,
  productSteps,
  productRelations,
}: Partial<Product> = {}): ProductFormData => {
  return {
    code: code || "",
    nameEn: nameEn || "",
    nameVn: nameVn || "",
    description: description || "",
    countryOfOrigin: countryOfOrigin || "",
    group: productGroup
      ? { label: productGroup.name, value: productGroup.id }
      : null,
    type: productType
      ? { label: productType.name, value: productType.id }
      : null,
    unit: productUnit
      ? { label: productUnit.name, value: productUnit.id }
      : null,
    heightCm: heightCm || null,
    lengthCm: lengthCm || null,
    seatSizeCm: seatSizeCm || "",
    weightKg: weightKg || null,
    widthCm: widthCm || null,
    boxQuantity: packageBoxCount || null,
    color: color || "",
    packagingHeightCm: packageHeightCm || null,
    packagingLengthCm: packageLengthCm || null,
    packagingWidthCm: packageWidthCm || null,
    images: productImage?.id
      ? ([
          {
            id: productImage?.id || "",
            preview: productImage?.attachmentUrl,
            name: productImage?.fileName,
          },
        ] as FilePreview[])
      : [],
    productSteps:
      productSteps?.map((step) => ({
        id: step.id,
        step: {
          label: step.step.name || "",
          value: step.step.id,
        },
        estimateTime: step.estimateTime || null,
      })) || [],
    productRelations:
      productRelations?.map((item) => ({
        products: {
          label: item.component.nameVn,
          value: item.component.id,
        },
        quantity: item.quantity,
        id: item.id,
        description: item.description || "",
      })) || [],
  };
};
