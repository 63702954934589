import { useAppTranslation } from "app/hooks";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { StartTicketForm, StartTicketFormData } from "./StartTicketForm";

export interface StartTicketFormModalProps {
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: StartTicketFormData) => void;
  submitting?: boolean;
  defaultValues?: StartTicketFormData;
}

export const StartTicketFormModal: FC<StartTicketFormModalProps> = ({
  onToggle,
  open = false,
  onSubmit,
  submitting,
  defaultValues,
}) => {
  const { ticketText } = useAppTranslation();
  const title = ticketText.startTitle;
  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <StartTicketForm
          onSubmit={onSubmit}
          onToggle={onToggle}
          defaultValues={defaultValues || { employee: null }}
          submitting={submitting}
        />
      </ModalBody>
    </Modal>
  );
};
