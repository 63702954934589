import { ColumnDef } from "@tanstack/react-table";
import { Button } from "app/components/molecules/Button";
import { useConfirm } from "app/components/molecules/ConfirmationProvider";
import { DebouncedInput } from "app/components/molecules/DebounceInput";
import { DataTable } from "app/components/organisms/DataTable";
import { useAppTranslation } from "app/hooks";
import useAppSearch from "app/hooks/useAppSearch";
import { PanelFormData, PanelFormModal } from "app/modules/panel/components";
import {
  useCreatePanel,
  useDeletePanel,
  useRetrievePanels,
  useUpdatePanel,
} from "app/modules/panel/hooks";
import { mapCreatePanel, mapUpdatePanel } from "app/modules/panel/mapper";
import { PanelUserFormData } from "app/modules/user/components";
import { PanelUserFormModal } from "app/modules/user/components/PanelUserFormModal/PanelUserFormModal";
import { useCreatePanelUser } from "app/modules/user/hooks";
import { mapCreatePanelUser } from "app/modules/user/mapper";
import {
  WorkshopPanelAction,
  WorkshopPanelActionDropdown,
} from "app/modules/workshop/components";
import { Panel } from "app/services/panel";
import { FC, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

export interface DetailWorkshopPanelProps {}

const DetailWorkshopPanel: FC<DetailWorkshopPanelProps> = () => {
  const { id } = useParams();
  const { panelText, confirmText } = useAppTranslation();
  const { confirm } = useConfirm();
  const [isUserModalOpen, toggleUserModal] = useState(false);
  const [isPanelModalOpen, togglePanelModal] = useState(false);
  const rowSelected = useRef<Panel | null>(null);

  const handleToggleUserModal = (data?: Panel) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isUserModalOpen) {
      rowSelected.current = null;
    }
    toggleUserModal((prevState) => !prevState);
  };

  const handleTogglePanelModal = (data?: Panel) => {
    if (data) {
      rowSelected.current = data;
    }
    if (isPanelModalOpen) {
      rowSelected.current = null;
    }
    togglePanelModal((prevState) => !prevState);
  };

  const { page, limit, searchString, setPage, setLimit, setSearchString } =
    useAppSearch({
      initialPage: 1,
      initialLimit: 20,
      initialSearchString: "",
    });

  const { panels, isLoadingPanels } = useRetrievePanels({
    workshopId: id as string,
    limit,
    page,
    searchString: searchString || undefined,
  });

  const { mutateAsync: deleteEmployee } = useDeletePanel();

  const handleActionClick = (action: WorkshopPanelAction, data?: Panel) => {
    switch (action) {
      case "create-user":
        handleToggleUserModal(data);
        break;
      case "edit":
        if (data) {
          handleTogglePanelModal(data);
        }
        break;
      case "delete":
        if (data?.id) {
          confirm({
            title: confirmText.deleteTitle(panelText.title),
            description: confirmText.deleteContent(panelText.title),
            onConfirm: () => deleteEmployee({ id: data.id }),
          });
        }
        break;
      default:
        break;
    }
  };

  const columns: ColumnDef<Panel>[] = useMemo(
    () => [
      {
        header: panelText.field.name,
        accessorKey: "name",
        cell: ({ row }) => row.original.name || "-",
      },
      {
        header: panelText.field.description,
        accessorKey: "description",
        cell: ({ row }) => row.original.description || "-",
      },
      {
        header: panelText.field.username,
        accessorKey: "username",
        cell: ({ row }) => row.original?.user?.username || "-",
      },
      {
        header: "",
        accessorKey: "action",
        size: 40,
        cell: ({ row }) => (
          <WorkshopPanelActionDropdown
            onAction={handleActionClick}
            data={row.original}
          />
        ),
      },
    ],
    [panelText],
  );

  const { mutateAsync: createUser, isPending: isUserCreating } =
    useCreatePanelUser({
      onSuccess: () => {
        handleToggleUserModal();
      },
    });

  const handleCreateUser = (data: PanelUserFormData) => {
    if (rowSelected.current) {
      createUser(
        mapCreatePanelUser({ workshopId: rowSelected.current.id, ...data }),
      );
    }
  };

  const handleSubmitUser = (data: PanelUserFormData) => {
    handleCreateUser(data);
  };

  const { mutateAsync: createPanel, isPending: isCreating } = useCreatePanel({
    onSuccess: () => {
      handleTogglePanelModal();
    },
  });

  const handleCreatePanel = (data: PanelFormData) => {
    createPanel(mapCreatePanel({ ...data, workshopId: id as string }));
  };

  const { mutateAsync: updatePanel, isPending: isUpdating } = useUpdatePanel({
    id: rowSelected.current?.id,
    onSuccess: () => {
      handleTogglePanelModal();
    },
  });

  const handleUpdatePanel = (data: PanelFormData) => {
    if (rowSelected.current) {
      updatePanel(
        mapUpdatePanel({
          id: rowSelected.current.id,
          workshopId: id as string,
          ...data,
        }),
      );
    }
  };

  const handleSubmitPanel = (data: PanelFormData) => {
    if (rowSelected.current) {
      handleUpdatePanel(data);
    } else {
      handleCreatePanel(data);
    }
  };

  return (
    <>
      <div className="p-3 py-0 h-100 d-flex flex-column">
        <Row>
          <Col xs={6} className="search-box my-2 d-inline-block">
            <DebouncedInput
              name="list-product-search"
              value={searchString || ""}
              onChange={(value) => {
                setSearchString(String(value));
              }}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </Col>
          <Col xs={6} className="text-end my-2">
            <Button
              color="primary"
              size="sm"
              onClick={() => handleTogglePanelModal()}
            >
              {panelText.add}
            </Button>
          </Col>
        </Row>
        <DataTable<Panel>
          columns={columns}
          data={panels?.result || []}
          loading={isLoadingPanels}
          total={panels?.total || 0}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
        />
      </div>
      <PanelUserFormModal
        open={isUserModalOpen}
        onToggle={handleToggleUserModal}
        onSubmit={handleSubmitUser}
        submitting={isUserCreating}
      />
      <PanelFormModal
        open={isPanelModalOpen}
        onToggle={handleTogglePanelModal}
        onSubmit={handleSubmitPanel}
        submitting={isCreating || isUpdating}
        edit={!!rowSelected.current}
        data={rowSelected.current || undefined}
      />
    </>
  );
};

export default DetailWorkshopPanel;
