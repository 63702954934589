import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { useRetrieveOrderById } from "app/modules/order/hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailOrderGeneralProps {}

const DetailOrderGeneral: FC<DetailOrderGeneralProps> = () => {
  const { id } = useParams();
  const { orderText } = useAppTranslation();
  const { order } = useRetrieveOrderById(
    { id: id as string },
    { enabled: !!id },
  );

  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">{orderText.detail.infomation}</h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={orderText.field.orderCode}
                    value={order?.orderCode}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={orderText.field.customer}
                    value={order?.customer?.customerName}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={orderText.field.orderDate}
                    value={order?.orderDate ? formatDate(order.orderDate) : "-"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={orderText.field.expectedDeliveryDate}
                    value={
                      order?.expectedDeliveryDate
                        ? formatDate(order.expectedDeliveryDate)
                        : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={orderText.field.notes}
                    value={order?.notes}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailOrderGeneral;
