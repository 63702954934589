import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Workshop } from "./types";
import { Response } from "../types";

export const RETRIEVE_WORKSHOP_BY_ID_URL = (id: ID) =>
  `/api/admin/workshops/${id}`;

export type RetrieveWorkshopByIdResponse = Response<Workshop>;

export type RetrieveWorkshopByIdArgs = {
  id: ID;
};

export const retrieveWorkshopById = ({ id }: RetrieveWorkshopByIdArgs) => {
  return httpClient.get<RetrieveWorkshopByIdResponse>(
    RETRIEVE_WORKSHOP_BY_ID_URL(id),
  );
};

export const useRetrieveWorkshopByIdQuery = (
  retrieveWorkshopsArgs: RetrieveWorkshopByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveWorkshopByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveWorkshopByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_WORKSHOP_BY_ID_URL(retrieveWorkshopsArgs.id)],
    queryFn: () => retrieveWorkshopById(retrieveWorkshopsArgs),
    ...opts,
  });
