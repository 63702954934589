import FormPage from "app/components/templates/FormPage";
import { useAppTranslation } from "app/hooks";
import {
  WorkshopForm,
  WorkshopFormData,
} from "app/modules/workshop/components";
import { useCreateWorkshop } from "app/modules/workshop/hooks";
import {
  mapCreateWorkshop,
  mapDefaultWorkshop,
} from "app/modules/workshop/mapper";
import BreadCrumb from "Components/Common/BreadCrumb";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export interface CreateWorkshopProps {}

const CreateWorkshop: FC<CreateWorkshopProps> = () => {
  const { workshopText } = useAppTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateWorkshop({
    onSuccess: () => {
      navigate("/workshops");
    },
  });

  const handleSubmit = async (data: WorkshopFormData) => {
    mutateAsync(mapCreateWorkshop(data));
  };

  return (
    <FormPage>
      <FormPage.BreadCrumb>
        <BreadCrumb
          title={workshopText.add}
          pageTitle={workshopText.title}
          isBack
        />
      </FormPage.BreadCrumb>
      <FormPage.Main>
        <Card>
          <CardBody>
            <WorkshopForm
              onSubmit={handleSubmit}
              defaultValues={mapDefaultWorkshop()}
              submitting={isPending}
            />
          </CardBody>
        </Card>
      </FormPage.Main>
    </FormPage>
  );
};

export default CreateWorkshop;
