import { TicketStatus } from "app/services/ticket";

export const ticketStatusMappers: Record<TicketStatus, string> = {
  [TicketStatus.InProgress]: "Đang thực hiện",
  [TicketStatus.Todo]: "Chờ thực hiện",
  [TicketStatus.Completed]: "Hoàn thành",
};

export const ticketStatusOptions = Object.keys(TicketStatus).map((key) => ({
  value: key as TicketStatus,
  label: ticketStatusMappers[key as TicketStatus],
}));
