import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "app/components/organisms/DataTable";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { productionPlanItemStatusMappers } from "app/modules/productionPlan/constants";
import { useRetrieveProductionPlanById } from "app/modules/productionPlan/hooks";
import { ProductionPlanItem } from "app/services/productionPlan";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export interface DetailProductionPlanItemsProps {}

const DetailProductionPlanItems: FC<DetailProductionPlanItemsProps> = () => {
  const { id } = useParams();
  const { productionPlanText, productText, orderText } = useAppTranslation();

  const { productionPlan, isLoadingProductionPlan } =
    useRetrieveProductionPlanById({ id: id as string }, { enabled: !!id });

  const orderItems = productionPlan?.planItems;

  const columns: ColumnDef<ProductionPlanItem>[] = useMemo(
    () => [
      {
        header: productionPlanText.field.order,
        accessorKey: "order",
        cell: ({ row }) => row.original.orderItem?.order?.orderCode || "-",
      },
      {
        header: productText.field.product,
        accessorKey: "orderProductName",
        cell: ({ row }) => row.original.orderItem?.product.nameVn || "-",
      },
      {
        header: productText.field.code,
        accessorKey: "orderProductCode",
        cell: ({ row }) => row.original.orderItem?.product.code || "-",
      },
      {
        header: productionPlanText.field.status,
        accessorKey: "productionPlanItemStatus",
        cell: ({ row }) => productionPlanItemStatusMappers[row.original.status],
      },
      {
        header: orderText.field.deliveryDate,
        accessorKey: "deliveryDate",
        cell: ({ row }) =>
          row.original.orderItem?.deliveryDate
            ? formatDate(row.original.orderItem.deliveryDate)
            : "-",
      },
      {
        header: `${productionPlanText.field.quantity}/${productionPlanText.field.totalQuantity}`,
        accessorKey: "quantity",
        cell: ({ row }) =>
          `${row.original?.quantity}/${row.original.orderItem?.quantity}` ||
          "-",
      },
    ],
    [productionPlanText],
  );

  return (
    <div className="p-3 py-0 h-100 d-flex flex-column">
      <DataTable<ProductionPlanItem>
        columns={columns}
        data={orderItems || []}
        loading={isLoadingProductionPlan}
        total={orderItems?.length || 0}
        isHiddenPagination
      />
    </div>
  );
};

export default DetailProductionPlanItems;
