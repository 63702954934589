import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { Response } from "app/services/types";
import { AxiosError, AxiosResponse } from "axios";
import { Employee, EmployeeGender } from "./types";

export const CREATE_EMPLOYEE_URL = "/api/admin/employees";

export enum CreateEmployeeError {}

export const createEmployeeErrorMessages = {};

export interface CreateEmployeeArgs {
  employeeCode: string;
  departmentId: ID;
  jobTitleId: ID;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  phone: string;
  taxCode: string | null;
  nationalId: string | null;
  gender: EmployeeGender | null;
  birthdate: DateTimeString | null;
  dateOfIssue: DateTimeString | null;
  placeOfIssue: string | null;
  permanentAddress: string | null;
  dateOfJoining: DateTimeString | null;
  dateOfLeaving: DateTimeString | null;
  status: number;
  imageId: string | null;
  skillIds: ID[];
}

export type CreateEmployeeResponse = Response<Employee>;

export const createEmployee = (args: CreateEmployeeArgs) => {
  return httpClient.post<CreateEmployeeResponse>(CREATE_EMPLOYEE_URL, args);
};

export const useCreateEmployeeMutation = (
  opts?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateEmployeeResponse>,
      AxiosError<any>,
      CreateEmployeeArgs
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    AxiosResponse<CreateEmployeeResponse>,
    AxiosError<any>,
    CreateEmployeeArgs
  >({
    mutationFn: createEmployee,
    mutationKey: [CREATE_EMPLOYEE_URL],
    ...opts,
  });
