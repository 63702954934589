import { ImagePopup } from "app/components/organisms/ImagePopup";
import classNames from "classnames";
import { FC, MouseEvent, useState } from "react";
import { clsx } from "yet-another-react-lightbox";

interface AvatarRowProps {
  name?: string;
  avatarUrl?: string;
  isClickable?: boolean;
  size?: "sm" | "md" | "lg";
}

export const AvatarRow: FC<AvatarRowProps> = ({
  name,
  avatarUrl,
  isClickable = false,
  size = "sm",
}) => {
  const [showImage, setShowImage] = useState<boolean>(false);
  const handleToggleImage = (
    e: MouseEvent<HTMLImageElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setShowImage((prev) => !prev);
  };
  return (
    <div className={classNames("d-flex align-items-center gap-2")}>
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt={name || "avatar"}
          onClick={isClickable ? handleToggleImage : undefined}
          className={clsx(
            "rounded object-fit-cover overflow-clip-margin-unset",
            `avatar-${size}`,
          )}
        />
      ) : (
        <div className="avatar-sm rounded bg-light fs-4 text-truncate text-secondary text-center d-flex justify-content-center align-items-center">
          {name?.charAt(0).toUpperCase()}
        </div>
      )}
      {name && <div>{name}</div>}
      {showImage && isClickable ? (
        <ImagePopup
          open={!!showImage}
          onClose={() => setShowImage(false)}
          slides={[
            {
              src: avatarUrl || "",
              alt: name,
            },
          ]}
        />
      ) : null}
    </div>
  );
};
