import { FilterByDate } from "app/models";
import { filterByDate } from "./constants";
import { formatDate } from "./utils";

export const getDateFilterLabel = ({
  type,
  endDate,
  startDate,
}: {
  type?: FilterByDate;
  startDate?: Date;
  endDate?: Date;
}): string => {
  if (!startDate && !endDate) return "";
  if (type) {
    if (startDate && endDate) {
      return `${filterByDate[type]}: ${formatDate(startDate)} → ${formatDate(endDate)}`;
    }
    if (startDate && !endDate) {
      return `${filterByDate[type]} từ ngày: ${formatDate(startDate)}`;
    }
    if (endDate && !startDate) {
      return `${filterByDate[type]} đến ngày: ${formatDate(endDate)}`;
    }
  } else {
    if (startDate && endDate) {
      return `${formatDate(startDate)} → ${formatDate(endDate)}`;
    }
    if (startDate && !endDate) {
      return `Từ ngày: ${formatDate(startDate)}`;
    }
    if (endDate && !startDate) {
      return `Đến ngày: ${formatDate(endDate)}`;
    }
  }
  return "";
};
