import {
  RetrieveProductionOrdersArgs,
  useRetrieveProductionOrdersQuery,
} from "app/services/productionOrder";

export const useRetrieveProductionOrders = (
  args: RetrieveProductionOrdersArgs,
) => {
  const result = useRetrieveProductionOrdersQuery(args);
  return {
    productionOrders: result.data?.data.data,
    isLoadingProductionOrders: result.isLoading,
    ...result,
  };
};
