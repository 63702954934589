import DetailPage from "app/components/templates/DetailPage";
import { useAppTranslation } from "app/hooks";
import { useRetrieveProductionOrderById } from "app/modules/productionOrder/hooks";
import { ProductionOrder } from "app/services/productionOrder";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Outlet, useParams } from "react-router-dom";
import DetailProductionOrderHeader from "./DetailProductionOrderHeader";
import { DetailProductionOrderTabs } from "./DetailProductionOrderTabs";

const DetailProductionOrder = () => {
  const { id } = useParams();
  const { productionOrderText } = useAppTranslation();

  const { productionOrder, isLoadingProductionOrder } =
    useRetrieveProductionOrderById({ id: id as string }, { enabled: !!id });

  return (
    <DetailPage<ProductionOrder>
      data={productionOrder as ProductionOrder}
      loading={isLoadingProductionOrder}
    >
      <DetailPage.BreadCrumb>
        <BreadCrumb
          title={productionOrderText.detail.title}
          pageTitle={productionOrderText.title}
          isBack
        />
      </DetailPage.BreadCrumb>
      <DetailPage.Header>
        <DetailProductionOrderHeader />
      </DetailPage.Header>
      <DetailPage.Main
        tabs={<DetailProductionOrderTabs />}
        // attachmentBar={
        //   <AttachmentBar
        //     imageProps={{ data: [] }}
        //     documentProps={{ data: [] }}
        //   />
        // }
      >
        <Outlet />
      </DetailPage.Main>
    </DetailPage>
  );
};

export default DetailProductionOrder;
