import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { BomTree, Product } from ".";
import { Response } from "../types";

export const RETRIEVE_PRODUCT_BOM_BY_ID_URL = (id: ID) =>
  `/api/admin/products/${id}/bom`;

export type RetrieveProductBomByIdResponse = Response<
  Product & {
    tree: BomTree;
  }
>;

export type RetrieveProductBomByIdArgs = {
  id: ID;
};

export const retrieveProductBomById = ({ id }: RetrieveProductBomByIdArgs) => {
  return httpClient.get<RetrieveProductBomByIdResponse>(
    RETRIEVE_PRODUCT_BOM_BY_ID_URL(id),
  );
};

export const useRetrieveProductBomByIdQuery = (
  retrieveProductBomsArgs: RetrieveProductBomByIdArgs,
  opts?: Omit<
    UseQueryOptions<
      AxiosResponse<RetrieveProductBomByIdResponse>,
      AxiosError<any>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<AxiosResponse<RetrieveProductBomByIdResponse>, AxiosError<any>>({
    queryKey: [RETRIEVE_PRODUCT_BOM_BY_ID_URL(retrieveProductBomsArgs.id)],
    queryFn: () => retrieveProductBomById(retrieveProductBomsArgs),
    ...opts,
  });
