import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { MasterData } from "./types";

export const DELETE_MASTER_DATA_URL = (id: ID) =>
  `/api/admin/master-data/${id}`;

export enum DeleteMasterDataError {}

export const deletedMasterDataErrorMessages = {};

export type DeleteMasterDataResponse = Response<MasterData>;

export type DeleteMasterDataArgs = {
  id: ID;
};

export const deleteMasterData = ({ id }: DeleteMasterDataArgs) => {
  return httpClient.delete<DeleteMasterDataResponse>(
    DELETE_MASTER_DATA_URL(id),
  );
};

export const useDeleteMasterDataMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteMasterDataResponse>,
    AxiosError<Response<null>>,
    DeleteMasterDataArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_MASTER_DATA_URL],
    mutationFn: (deletedMasterDataArgs: DeleteMasterDataArgs) =>
      deleteMasterData(deletedMasterDataArgs),
    ...opts,
  });
};
