import { Chip } from "app/components/atoms/Chip";
import { ListInfoItem } from "app/components/molecules/ListInfoItem";
import { useDetailPageContext } from "app/components/templates/DetailPage/DetailPageContext";
import { formatDate } from "app/helpers/utils";
import { useAppTranslation } from "app/hooks";
import { Customer } from "app/services/customer";
import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

export interface DetailCustomerGeneralProps {}

const DetailCustomerGeneral: FC<DetailCustomerGeneralProps> = () => {
  const { data: customer } = useDetailPageContext<Customer>();
  const { customerText } = useAppTranslation();
  return (
    <div className="p-3 pb-0">
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">
                {customerText.detail.infomation}
              </h5>
              <Row className="gap-2">
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.customerName}
                    value={customer?.customerName}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.customerCode}
                    value={customer?.customerCode}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.address}
                    value={customer?.address}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.phoneNumber}
                    value={customer?.phoneNumber}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.email}
                    value={customer?.email}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.website}
                    value={
                      customer?.website ? (
                        <a
                          href={customer.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {customer.website}
                        </a>
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.customerType}
                    value={
                      customer?.customerType ? (
                        <Chip
                          size="sm"
                          color={customer?.customerType?.colorCode}
                          label={customer?.customerType?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.representative}
                    value={customer?.representative}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.representativeRole}
                    value={customer?.representativeRole}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.paymentTerms}
                    value={customer?.paymentTerms}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.depositPercentage}
                    value={customer?.depositPercentage}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.paymentDueDays}
                    value={customer?.paymentDueDays}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.startDate}
                    value={
                      customer?.startDate ? formatDate(customer.startDate) : "-"
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.customerStatus}
                    value={
                      customer?.customerStatus ? (
                        <Chip
                          size="sm"
                          color={customer?.customerStatus?.colorCode}
                          label={customer?.customerStatus?.name}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.assignee}
                    value={customer?.assignee.fullName || "-"}
                  />
                </Col>
                <Col xs={12}>
                  <ListInfoItem
                    label={customerText.field.notes}
                    value={customer?.notes}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailCustomerGeneral;
