import {
  RetrievePanelEmployeeByIdArgs,
  useRetrievePanelEmployeeByIdQuery,
} from "app/services/employee";

export const useRetrievePanelEmployeeById = (
  args: RetrievePanelEmployeeByIdArgs,
  opts?: Parameters<typeof useRetrievePanelEmployeeByIdQuery>[1],
) => {
  const result = useRetrievePanelEmployeeByIdQuery(args, opts as any);
  return {
    employee: result.data?.data.data,
    isLoadingPanelEmployee: result.isLoading,
    ...result,
  };
};
