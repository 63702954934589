import { useAppTranslation } from "app/hooks";
import { useRetrieveTicketLogById } from "app/modules/ticketLog/hooks";
import { mapDefaultTicketLog } from "app/modules/ticketLog/mapper";
import { TicketLog } from "app/services/ticketLog";
import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TicketLogForm, TicketLogFormData } from "./TicketLogForm";

export interface TicketLogFormModalProps {
  data?: TicketLog;
  open: boolean;
  onToggle: () => void;
  onSubmit: (data: TicketLogFormData) => void;
  submitting?: boolean;
  defaultValues?: TicketLogFormData;
  edit?: boolean;
  isQuickAdd?: boolean;
}

export const TicketLogFormModal: FC<TicketLogFormModalProps> = ({
  onToggle,
  open = false,
  onSubmit,
  submitting,
  defaultValues,
  edit,
  data,
}) => {
  const { ticketText } = useAppTranslation();
  const title = edit ? ticketText.editCheckInTitle : ticketText.checkInTitle;
  const { ticketLog, isLoadingTicketLog } = useRetrieveTicketLogById(
    {
      id: data?.id as string,
    },
    { enabled: !!edit && !!data?.id },
  );
  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!isLoadingTicketLog ? (
          <TicketLogForm
            onSubmit={onSubmit}
            onToggle={onToggle}
            defaultValues={
              defaultValues || mapDefaultTicketLog(ticketLog || {})
            }
            submitting={submitting}
            edit={edit}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
