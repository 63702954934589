import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClient } from "app/datasources";
import { AxiosError, AxiosResponse } from "axios";
import { Response } from "../types";
import { Step } from "./types";

export const DELETE_STEP_URL = (id: ID) => `/api/admin/steps/${id}`;

export enum DeleteStepError {}

export const deletedStepErrorMessages = {};

export type DeleteStepResponse = Response<Step>;

export type DeleteStepArgs = {
  id: ID;
};

export const deleteStep = ({ id }: DeleteStepArgs) => {
  return httpClient.delete<DeleteStepResponse>(DELETE_STEP_URL(id));
};

export const useDeleteStepMutation = (
  opts?: UseMutationOptions<
    AxiosResponse<DeleteStepResponse>,
    AxiosError<Response<null>>,
    DeleteStepArgs
  >,
) => {
  return useMutation({
    mutationKey: [DELETE_STEP_URL],
    mutationFn: (deletedStepArgs: DeleteStepArgs) =>
      deleteStep(deletedStepArgs),
    ...opts,
  });
};
